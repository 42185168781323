import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface SearchBarProps {
  searchFunction: (inputValue: string) => void;
}

const SearchBar = ({ searchFunction }: SearchBarProps) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState("");

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      searchFunction(searchString);
    },
    [searchFunction, searchString]
  );

  return (
    <form className="search-form" onSubmit={handleSubmit}>
      <input
        onChange={(e) => setSearchString(e.target.value)}
        type="text"
        placeholder={t("chooseCustomerSearch")}
      />
      <button type="submit" className="lm__primary-btn lm__blue-btn lm__base-icon-btn">
        <span className="material-icons" data-icon="search" />
      </button>
    </form>
  );
};

export default SearchBar;
