/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useEffect, useState, useCallback } from "react";
import { Route, Switch, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useToggle from "hooks/useToggle";
import useModal from "hooks/useModal";
import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useOnPageLeave from "hooks/useOnPageLeave";
import useConfirm from "hooks/useConfirm";
import useFetch from "hooks/useFetch";

import FeedPlanOverview from "pages/feed-plan-calculation/parts/FeedPlanOverview";
import FeedPlanOverviewAddRemove from "pages/feed-plan-calculation/parts/FeedPlanOverviewAddRemove";
import FeedPlanSettings from "pages/feed-plan-calculation/parts/FeedPlanSettings";
import ModalContent from "pages/customer-feeds/parts/CopyAsNewModalContent";
import CustomerFeedPlanTable from "pages/customer-overview/parts/CustomerFeedPlanTable";

import SideMenu from "components/sidemenu/SideMenu";
import PrintPage from "components/print-page/PrintPage";

import Modal from "components/modal/Modal";
import Header from "components/header/Header";
import BasePage from "components/base-page/BasePage";
import LMButton from "components/lm-button/LMButton";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { setCurrentFeedPlan } from "pages/choose-customer/context/customerActions";

import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";

import { BASE_FEED_PLAN_URL } from "assets/constants";

const FeedPlanCalculationPage = () => {
  const { t } = useTranslation();
  const { t: tWithKeyPrefix } = useTranslation("", {
    keyPrefix: "apiErrorStatusMessage",
  });
  const { path } = useRouteMatch();
  const [showMenu, toggleMenu] = useToggle(false);
  const history = useHistory();
  const modalHandler = useModal();
  const { pathname } = useLocation();
  const { isConfirmed } = useConfirm();
  const [unsavedFeedPlan, setUnsavedFeedPlan] = useState(false);
  const [unsavedFeedDietItems, setUnsavedFeedDietItems] = useState(false);
  const [unsavedControlParameterItems, setUnsavedControlParameterItems] = useState(false);
  const { copyFeedPlanToDatabase } = useSaveFeedRequest();
  const { get } = useFetch();

  const {
    customerState: { currentFeedPlan, currentCustomer },
    customerDispatch,
  } = useContext(CustomerContext);

  const sideMenuFunctions = {
    setUnsavedFeedPlan,
    setUnsavedFeedDietItems,
    setUnsavedControlParameterItems,
    toggleModal: modalHandler.toggleState,
  };

  const unsavedStates = {
    unsavedFeedPlan,
    unsavedFeedDietItems,
    unsavedControlParameterItems,
  };

  useEffect(() => {
    if (
      currentFeedPlan?.id &&
      pathname !== `/feed-plan-calculation/${currentFeedPlan.id}` &&
      pathname === "/feed-plan-calculation"
    ) {
      history.push(`${pathname}/${currentFeedPlan.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnCreateNewClick = useCallback(() => {
    history.push("/feed-plan-calculation/create-feed-plan");
  }, [history]);

  const unsaved = unsavedFeedDietItems || unsavedControlParameterItems || unsavedFeedPlan;

  useOnPageLeave(unsaved);

  const onResetChanges = useCallback(async () => {
    const uneditedFeedPlan: ICustomerFeedPlan = await get(
      `${BASE_FEED_PLAN_URL}/${currentFeedPlan?.id}`
    );
    customerDispatch(setCurrentFeedPlan(uneditedFeedPlan));
    setUnsavedFeedDietItems(false);
    setUnsavedControlParameterItems(false);
  }, [currentFeedPlan, get, customerDispatch]);

  const shouldShowSideMenu =
    !pathname.endsWith("add-remove") &&
    !pathname.endsWith("print") &&
    !pathname.endsWith("create-feed-plan")
      ? toggleMenu
      : undefined;

  return (
    <BasePage pageName="feed-plan-calculation">
      {currentCustomer && (
        <>
          <Header
            title="feedPlanCalculation"
            hideReadOnly={
              !currentFeedPlan ||
              pathname.endsWith("print") ||
              pathname.endsWith("create-feed-plan")
            }
            showSideMenu={shouldShowSideMenu}
          />
          {pathname.endsWith("feed-plan-calculation") && (
            <div className="button-wrapper margin-bottom-20">
              <LMButton text={t("createNew")} type="button" click={handleOnCreateNewClick} />
            </div>
          )}
        </>
      )}

      {showMenu && (
        <SideMenu
          type="feed-plan-calculation"
          toggleMenu={toggleMenu}
          unsavedProps={unsavedStates}
          callbackProps={sideMenuFunctions}
        />
      )}

      <Modal headerText="copyAsNew" show={modalHandler.toggleModal} hide={modalHandler.toggleState}>
        <ModalContent
          copyFeed={async (newFeedPlanName: string) => {
            const { result, id } = await copyFeedPlanToDatabase(
              currentFeedPlan?.id!,
              newFeedPlanName
            );
            if (!result.ok) {
              const code = `error${result.status}`;
              return isConfirmed(tWithKeyPrefix(code), "alert");
            }

            return history.push(`/feed-plan-calculation/${id}`);
          }}
          closeModal={modalHandler.toggleState}
        />
      </Modal>

      <Switch>
        <Route exact path={path}>
          {currentCustomer ? (
            <CustomerFeedPlanTable />
          ) : (
            <h2 className="text-center" onClick={() => history.push("/choose-customer")}>
              {t("customerOverviewNoCustomerSelected")}
            </h2>
          )}
        </Route>

        <Route path={`${path}/create-feed-plan`}>
          <FeedPlanSettings />
        </Route>

        <Route path={`${path}/:feedPlanId/settings`}>
          <FeedPlanSettings feedPlanToEdit={currentFeedPlan} />
        </Route>

        <Route path={`${path}/:feedPlanId/add-remove`}>
          <FeedPlanOverviewAddRemove setUnsavedFeedPlan={setUnsavedFeedPlan} />
        </Route>

        <Route path={`${path}/:feedPlanId/print`}>
          <PrintPage type="feedPlan" />
        </Route>

        <Route path={`${path}/:feedPlanId`}>
          <FeedPlanOverview
            unsavedFeedDietItems={unsavedFeedDietItems}
            setUnsavedFeedDietItems={setUnsavedFeedDietItems}
            unsavedControlParameterItems={unsavedControlParameterItems}
            setUnsavedControlParameterItems={setUnsavedControlParameterItems}
            onResetChanges={onResetChanges}
          />
        </Route>
      </Switch>
    </BasePage>
  );
};

export default withAuthenticationRequired(FeedPlanCalculationPage);
