import { useTranslation } from "react-i18next";

const LegendList = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div id="legend">
      <ul className="legend-list">
        <li>
          <div className="legendValue">
            <span className="min-legend" />
            <span className="label">{t("belowRange")}</span>
          </div>
        </li>
        <li>
          <div className="legendValue">
            <span className="max-legend" />
            <span className="label">{t("aboveRange")}</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default LegendList;
