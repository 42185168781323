import { useMemo, useCallback, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";
import { copySelectedCustomerFeed } from "pages/customer-feeds/context/customerFeedsActions";

import buttons from "pages/customer-feeds/json/saveModalContentButtons.json";

import LMButton from "components/lm-button/LMButton";

interface ISaveModalContentButton {
  id: number;
  disabled: boolean;
  purpose: string;
  text: string;
  type: string;
}

interface SaveModalContentProps {
  saveFeed?: any;
  closeModal?: any;
}

const SaveModalContent = ({ saveFeed, closeModal }: SaveModalContentProps): JSX.Element => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const { customerFeedsDispatch } = useContext(CustomerFeedsContext);

  const saveFeedPlan = useCallback(async () => {
    if (inputRef.current) {
      customerFeedsDispatch(copySelectedCustomerFeed(await saveFeed(inputRef.current.value)));
      closeModal();
    }
  }, [customerFeedsDispatch, saveFeed, closeModal]);

  const closeFeedModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleOnClick = useCallback(
    (button: ISaveModalContentButton) => {
      switch (button.purpose) {
        case "saveFeedNameForNewFeed":
          return saveFeedPlan();
        case "hideModalAndCancelOperation":
          return closeFeedModal();
        default:
          break;
      }
    },
    [closeFeedModal, saveFeedPlan]
  );

  const renderButtons = useMemo(
    (): JSX.Element[] =>
      buttons.map((button) => (
        <LMButton
          key={button.id}
          click={() => handleOnClick(button)}
          disabled={button.disabled ? true : undefined}
          text={t(button.text)}
          type="button"
          className={button.text === "cancel" ? "cancelButton" : "saveButton"}
        />
      )),
    [handleOnClick, t]
  );

  return (
    <div>
      <input ref={inputRef} type="text" placeholder="Enter Feed Name" />
      {renderButtons}
    </div>
  );
};

export default SaveModalContent;
