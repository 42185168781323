/* eslint-disable react-hooks/exhaustive-deps */
import {
  useMemo,
  createContext,
  useEffect,
  useReducer,
  useRef,
  useContext,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import { IDS_NOT_TO_DISPLAY_IN_TABLES } from "assets/constants";

import useOnClickOutside from "hooks/useOnClickOutside";
import useTableHeaderSorter from "hooks/useTableHeaderSorter";
import tableDataReducer from "components/table/context/tableDataReducer";
import TableRow from "components/table/parts/TableRow";
import TableHeadRow from "components/table/parts/TableHeadRow";
import Loader from "components/loader/Loader";
import useFeedMixCalculation from "hooks/useFeedMixCalculation";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { setCurrentFeedMix } from "pages/choose-customer/context/customerActions";
import {
  setTableData,
  setTableDataKeyPrefix,
  updateSelectedTableDataRow,
} from "components/table/context/tableDataActions";
import ITableDataRow from "interfaces/ITableDataRow";
import ITableDataState from "interfaces/ITableDataState";
import IAction from "interfaces/IAction";
import ITypeSpec from "interfaces/ITypeSpec";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import tableDataDecimalConfig from "./json/tableDataDecimalConfig.json";

interface ITableDataContext {
  tableDataState: ITableDataState;
  dispatch: React.Dispatch<IAction>;
  typeSpecs: ITypeSpec;
  tableDataKeyPrefix?: string;
}

interface ITableProps {
  data: ITableDataRow[];
  onSelectedRow?: (newSelectedRow: any) => void;
  onUpdatedRow?: (updatedRow: any) => void;
  typeSpecs: ITypeSpec;
  keyPrefix?: string;
  emptyTableData?: true;
  groupSortTableColumns?: string[];
  uniqueParamsCombo?: string[];
  checkIfParamsComboIsUnique?: (key: string, value: string, rowId: number) => boolean | undefined;
  readOnly?: boolean;
  feedMixTableSizing?: ITableDataRow[];
  toggleUnsavedFeedMix?: (val?: boolean) => void;
  optimizedFeedDiets?: number;
  newlyAddedFeedId?: number;
  userHiddenTableColumns?: number[];
  unsavedItems?: boolean;
  disableHighlight?: boolean;
  deleteFeedPlan?: (id: number, name: string) => void;
  tableSortOrder?: (direction: string, row: ITableDataRow) => void;
  onDoubleClickLeft?: (row: any) => void;
  onDoubleClickRight?: (row: any) => void;
  onYieldLevelHeadClick?: (header: string) => void;
  feedPlan?: ICustomerFeedPlan;
  getSortOrder?: (data: ITableDataRow[]) => void;
}

export const TableDataContext = createContext<ITableDataContext>({} as ITableDataContext);

const Table = forwardRef<HTMLTableElement, ITableProps>(
  (
    {
      data,
      onSelectedRow,
      onUpdatedRow,
      typeSpecs,
      keyPrefix,
      emptyTableData,
      groupSortTableColumns,
      uniqueParamsCombo,
      checkIfParamsComboIsUnique,
      readOnly,
      feedMixTableSizing,
      toggleUnsavedFeedMix,
      optimizedFeedDiets,
      newlyAddedFeedId,
      userHiddenTableColumns,
      unsavedItems,
      disableHighlight,
      deleteFeedPlan,
      tableSortOrder,
      onDoubleClickLeft,
      onDoubleClickRight,
      onYieldLevelHeadClick,
      feedPlan,
      getSortOrder,
    }: ITableProps,
    ref
  ): JSX.Element => {
    const [tableDataState, dispatch] = useReducer(tableDataReducer, {
      tableData: [],
      lastUpdatedTableDataRow: null,
    });
    const { tableData, selectedTableDataRow, lastUpdatedTableDataRow, tableDataKeyPrefix } =
      tableDataState;
    const {
      customerState: { currentFeedMix },
      customerDispatch,
    } = useContext(CustomerContext);

    const { handleAmountKgCalculation, handleShareInPercKgCalculation } = useFeedMixCalculation();
    const { t } = useTranslation();
    const thisTable = useRef<HTMLDivElement | null>(null);

    const { getCharacterLengthFeedMix } = useTableHeaderSorter();

    useEffect(() => {
      dispatch(setTableData(data));

      if (keyPrefix) {
        dispatch(setTableDataKeyPrefix(keyPrefix));
      }
    }, [dispatch, data, keyPrefix]);

    const handleOnClickOutside = (event: MouseEvent) => {
      if (!(event.target instanceof HTMLButtonElement)) {
        dispatch(updateSelectedTableDataRow());
      }
    };

    useOnClickOutside(thisTable, handleOnClickOutside);

    /**
     * @description Function to handle updated inputs in Feed Mix table.
     * These values rely on eachother therefore we need to update the table
     * every time one the amount or percentage input changes.
     * This function handles & executes correct calculation-function.
     * The actual calculation is done in useFeedMixCalculation.
     * @param updatedColumn - Which of the input fields have been updated
     * Either "amountKg" or "shareInPercKg"
     * @param value
     * @returns
     */

    const handleFeedMixCalculation = (updatedColumn: string, value: number) => {
      const copyOfSelectedTableRow = selectedTableDataRow;
      if (!copyOfSelectedTableRow || !currentFeedMix) {
        return;
      }

      const nonSelectedTableRows = tableData.filter(
        (tableRow) => tableRow.id !== copyOfSelectedTableRow.id
      );

      if (updatedColumn === "amountKg") {
        // If new input value is the same as current value -> return
        if (value === copyOfSelectedTableRow.amountKg) {
          return;
        }

        // Calculate new values
        const { newTableData, newFeedMixData } = handleAmountKgCalculation(
          value,
          selectedTableDataRow,
          nonSelectedTableRows,
          tableData,
          currentFeedMix
        );

        // Dispatch updated table values & currentFeedMix values
        dispatch(setTableData(newTableData));
        customerDispatch(setCurrentFeedMix(newFeedMixData));
      }

      if (updatedColumn === "shareInPercKg") {
        // If new input value is the same as current value or missing amountKg value -> return
        if (
          value === copyOfSelectedTableRow.shareInPercKg ||
          copyOfSelectedTableRow.amountKg === 0
        ) {
          return;
        }

        /* Calculate new values */
        const { newTableData, newFeedMixData } = handleShareInPercKgCalculation(
          value,
          selectedTableDataRow,
          nonSelectedTableRows,
          tableData,
          currentFeedMix
        );

        // Dispatch updated table values & currentFeedMixValues
        dispatch(setTableData(newTableData));
        customerDispatch(setCurrentFeedMix(newFeedMixData));
      }

      if (toggleUnsavedFeedMix) {
        toggleUnsavedFeedMix(true);
      }
    };

    /**
     * Creates copy of table head row and filters out unwanted columns
     */
    const renderTableDataHeadRowNoIds = (): string[] => {
      // We don't want to display any id properties in the head row.
      const tableDataHeadRow = Object.keys(tableData[0]);
    
      IDS_NOT_TO_DISPLAY_IN_TABLES.forEach((key) => {
        const indexOfId = tableDataHeadRow.indexOf(key);
        if (indexOfId !== -1) {
          tableDataHeadRow.splice(indexOfId, 1);
        }
      });
    
      return tableDataHeadRow;
    };

    const renderTableDataRows = () => {
      if (emptyTableData) {
        return (
          <tr className="empty-table-data">
            <th className="message">{t("emptyTableData")}</th>
          </tr>
        );
      }

      return tableData.map((tableDataRow: ITableDataRow, index: number) => (
        <TableRow
          index={index}
          defaultSelected={!!(newlyAddedFeedId && tableDataRow.id === newlyAddedFeedId)}
          key={tableDataRow.id ? tableDataRow.id : index}
          tableDataRow={tableDataRow}
          checkIfParamsComboForRowIsUnique={checkIfParamsComboIsUnique}
          uniqueParamsCombo={uniqueParamsCombo}
          optimizedFeedDiets={keyPrefix === "feedDietData" ? optimizedFeedDiets : undefined}
          disabled={tableDataRow.isOptimized || readOnly}
          keyPrefix={keyPrefix}
          handleFeedMixCalculation={(updatedColumn, value) =>
            handleFeedMixCalculation(updatedColumn, value)
          }
          userHiddenTableColumns={userHiddenTableColumns}
          tableDataDecimalConfig={tableDataDecimalConfig}
          unsavedItems={unsavedItems}
          disableHighlight={disableHighlight}
          deleteFeedPlan={deleteFeedPlan}
          tableSortOrder={tableSortOrder}
          onDoubleClickLeft={onDoubleClickLeft}
          onDoubleClickRight={onDoubleClickRight}
        />
      ));
    };

    useEffect(() => {
      if (selectedTableDataRow && onSelectedRow) {
        onSelectedRow(selectedTableDataRow);
      }

      if (selectedTableDataRow === undefined && onSelectedRow) {
        onSelectedRow(undefined);
      }
    }, [selectedTableDataRow]);

    useEffect(() => {
      if (lastUpdatedTableDataRow && onUpdatedRow) {
        onUpdatedRow(lastUpdatedTableDataRow);
      }
    }, [lastUpdatedTableDataRow]);

    useEffect(() => {
      if (getSortOrder) {
        getSortOrder(tableData);
      }
    }, [tableData]);

    /**
     * @description Provider value for TableDataContext
     * Using useMemo to avoid unnecessary re-renders
     */
    const tableProviderValue = useMemo(() => ({
      tableDataState,
      dispatch,
      typeSpecs,
      tableDataKeyPrefix,
    }), [tableDataState, dispatch, typeSpecs, tableDataKeyPrefix]);

    if (!tableDataState.tableData.length) {
      if (!emptyTableData) {
        return (
          <>
            <Loader size="small" />
            <span className="margin-left-15">{t("loading")}</span>
          </>
        );
      }
      return (
        <div>
          <span className="empty-table-data">
            <span className="message">{t("emptyTableData")}</span>
          </span>
        </div>
      );
    }

    return (
      <div className="lm__table-wrapper margin-bottom-30">
        <table className="table" ref={ref}>
          <TableDataContext.Provider value={tableProviderValue}>
            <thead>
              <TableHeadRow
                tableGroupSortHeadProperties={groupSortTableColumns}
                tableDataHeadRow={renderTableDataHeadRowNoIds()}
                feedMixTableSizing={
                  feedMixTableSizing ? getCharacterLengthFeedMix(feedMixTableSizing) : undefined
                }
                userHiddenTableColumns={userHiddenTableColumns}
                onYieldLevelHeadClick={onYieldLevelHeadClick}
                feedPlan={feedPlan}
              />
            </thead>
            <tbody>{renderTableDataRows()}</tbody>
          </TableDataContext.Provider>
        </table>
      </div>
    );
  }
);

export default Table;
