/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Feed } from "types";
import ICustomerFeed from "interfaces/ICustomerFeed";
import IFeedDiet from "interfaces/IFeedDiet";
import IFeedMix from "interfaces/IFeedMix";
import IControlParamterResults from "interfaces/IControlParameterResults";
import ITableDataRow from "interfaces/ITableDataRow";

interface IFeedMixTableSizing {
  feedName: number;
  feedNumber: number;
  feedGroup: number;
}

function useTableHeaderSorter() {
  const sortPropertiesOfFeedDietItems = (feedDietTableData: IFeedDiet[]): IFeedDiet[] =>
    feedDietTableData.map((feedDiet) => {
      const feedDietTableDataBluePrint: IFeedDiet = {
        isOptimized: false,
        feedName: "",
        customerFeedId: 0,
        sortIndex: 0,
      };

      for (const key in feedDietTableDataBluePrint) {
        feedDietTableDataBluePrint[key] = feedDiet[key];
      }

      for (const key in feedDiet) {
        if (!feedDietTableDataBluePrint[key]) {
          if (key !== "customerFeedId") {
            feedDietTableDataBluePrint[key.toString()] = feedDiet[key.toString()];
          }
        }
      }
      return feedDietTableDataBluePrint;
    });

  const sortPropertiesOfControlParameterItems = (
    controlParameterTableData: IControlParamterResults[]
  ): IControlParamterResults[] => {
    const sortedControlParameterTableData = controlParameterTableData.map((nutrient) => {
      const controlParameterTableDataBluePrint: IControlParamterResults = {
        nutrientName: "",
      };

      for (const key in controlParameterTableDataBluePrint) {
        controlParameterTableDataBluePrint[key] = nutrient[key];
      }

      for (const key in nutrient) {
        if (!controlParameterTableDataBluePrint[key]) {
          if (key !== "customerFeedId") {
            controlParameterTableDataBluePrint[key.toString()] = nutrient[key.toString()];
          }
        }
      }

      return controlParameterTableDataBluePrint;
    });
    return sortedControlParameterTableData;
  };

  /**
   * The order of these properties controls the order columns in frontend
   * @param feeds
   * @returns
   */
  const sortPropertiesOfCustomerFeeds = (feeds: Feed[]) =>
    feeds.map((feed) => {
      const customerFeedBluePrint: ICustomerFeed = {
        id: 0,
        customerId: "",
        isActive: true,
        feedGroup: 0,
        feedNumber: 0,
        feedName: "",
        price: null,
        dm: 0,
        forage: 0,
        ash: 0,
        omd: null,
        ivos: null,
        cProtein: 0,
        solubleProtein: 0,
        nh3N: 0,
        nitrate: 0,
        ndf: 0,
        adf: null,
        lignin: null,
        cFibre: 0,
        sugar: null,
        starch: 0,
        fat: 0,
        metabolizedEnergy: 0,
        epd: 0,
        efd: 0,
        nEdairy: null,
        tmp: null,
        rbp: null,
        tmMet: null,
        tmLys: null,
        ph: null,
        ca: 0,
        p: 0,
        mg: 0,
        k: 0,
        na: 0,
        cl: 0,
        s: 0,
        fe: null,
        mn: null,
        zn: null,
        cu: 0,
        co: null,
        se: 0,
        mo: null,
        i: null,
        vitA: 0,
        vitD: 0,
        vitE: 0,
        debs: 0,
        c16: 0,
        rfcp: null,
        tfcp: null,
        rfch: null,
        tfch: null,
        bst: null,
        eStructo: null,
        neBeef: null,
        lacticAcid: null,
        formicAcid: null,
        byturicAcid: null,
        indf: 0,
        aaT20: 0,
        pbV20: 0,
        neL20: 0,
        co2Feed: null,
        supportsSfr: false,
        supportsLfu: false,
        isMixed: false,
        created: "",
        updated: "",
        sw1: null,
        sw2: null,
        sw3: null,
        sw4: null,
        sw5: null,
        sw6: null,
        sw7: null,
        sw8: null,
      };

      for (const key in customerFeedBluePrint) {
        customerFeedBluePrint[key] = feed[key];
      }

      return customerFeedBluePrint;
    });

  /**
   * The order of these properties controls the order columns in frontend
   * @param feeds
   * @returns
   */
  const sortPropertiesOfFeedMix = (feeds: Feed[]) =>
    feeds.map((feed) => {
      const feedMixBluePrint: IFeedMix = {
        id: 0,
        customerId: "",
        feedGroup: 0,
        feedNumber: 0,
        feedName: "",
        shareInPercDm: 0,
        shareInPercKg: 0,
        amountKg: 0,
        amountKgDm: 0,
        price: null,
        dm: 0,
        forage: 0,
        ash: 0,
        omd: null,
        ivos: null,
        cProtein: 0,
        solubleProtein: 0,
        nh3N: 0,
        nitrate: 0,
        ndf: 0,
        adf: null,
        lignin: null,
        cFibre: 0,
        sugar: null,
        starch: 0,
        fat: 0,
        metabolizedEnergy: 0,
        epd: 0,
        efd: 0,
        nEdairy: null,
        tmp: null,
        rbp: null,
        tmMet: null,
        tmLys: null,
        ph: null,
        ca: 0,
        p: 0,
        mg: 0,
        k: 0,
        na: 0,
        cl: 0,
        s: 0,
        fe: null,
        mn: null,
        zn: null,
        cu: 0,
        co: null,
        se: 0,
        mo: null,
        i: null,
        vitA: 0,
        vitD: 0,
        vitE: 0,
        debs: 0,
        c16: 0,
        rfcp: null,
        tfcp: null,
        rfch: null,
        tfch: null,
        bst: null,
        eStructo: null,
        neBeef: null,
        lacticAcid: null,
        formicAcid: null,
        byturicAcid: null,
        indf: 0,
        aaT20: 0,
        pbV20: 0,
        neL20: 0,
        co2Feed: null,
        sortIndex: null,
        supportsSfr: false,
        supportsLfu: false,
        isMixed: true,
        isActive: true,
        created: "",
        updated: "",
      };

      for (const key in feedMixBluePrint) {
        feedMixBluePrint[key] = feed[key];
      }
      return feedMixBluePrint;
    });

  const getCharacterLengthFeedMix = (data: ITableDataRow[]) => {
    const feedMixTableSizingObj: IFeedMixTableSizing = { feedName: 0, feedNumber: 0, feedGroup: 0 };
    data.forEach((tableRow) => {
      if (feedMixTableSizingObj.feedName < tableRow?.feedName?.length) {
        feedMixTableSizingObj.feedName = tableRow.feedName.length;
      }
      if (
        tableRow?.feedNumber &&
        feedMixTableSizingObj.feedNumber < tableRow?.feedNumber?.toString().length
      ) {
        feedMixTableSizingObj.feedNumber = tableRow.feedNumber.toString().length;
      }
      if (
        tableRow?.feedGroup &&
        feedMixTableSizingObj.feedGroup < tableRow?.feedGroup?.toString().length
      ) {
        feedMixTableSizingObj.feedGroup = tableRow.feedGroup.toString().length;
      }
      if (tableRow.nutrientName && feedMixTableSizingObj.feedName < tableRow.nutrientName.length) {
        feedMixTableSizingObj.feedName = tableRow.nutrientName.length;
      }
    });

    return feedMixTableSizingObj;
  };

  return {
    sortPropertiesOfCustomerFeeds,
    sortPropertiesOfFeedMix,
    sortPropertiesOfFeedDietItems,
    sortPropertiesOfControlParameterItems,
    getCharacterLengthFeedMix,
  };
}

export default useTableHeaderSorter;
