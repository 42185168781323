import { createContext, useReducer } from "react";

import customerFeedsReducer from "pages/customer-feeds/context/customerFeedsReducer";

import IContextProps from "interfaces/IContextProps";
import ICustomerFeedsState from "interfaces/ICustomerFeedsState";
import IAction from "interfaces/IAction";

import ICustomerFeed from "interfaces/ICustomerFeed";
import IFeedsState from "interfaces/IFeedsState";

interface ICustomerFeedsContext {
  customerFeedsState: ICustomerFeedsState;
  customerFeedsDispatch: React.Dispatch<IAction>;
}

export const CustomerFeedsContext = createContext<ICustomerFeedsContext>(
  {} as ICustomerFeedsContext
);

const CustomerFeedsProvider = ({ children }: IContextProps): JSX.Element => {
  const [customerFeedsState, customerFeedsDispatch] = useReducer(customerFeedsReducer, {
    feeds: [],
    feedsToAdd: [],
    feedsToUpdate: [],
    feedsToDelete: [],
    selectedFeed: undefined,
  } as IFeedsState<ICustomerFeed>);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = { customerFeedsState, customerFeedsDispatch };

  return <CustomerFeedsContext.Provider value={values}>{children}</CustomerFeedsContext.Provider>;
};

export default CustomerFeedsProvider;
