/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useToggle from "hooks/useToggle";
import Table from "components/table/Table";
import printConfig from "../json/printConfig.json";
import typeSpecsPrintFor from "../json/typeSpecsPrintFor.json";

interface ISectionsToPrint {
  [key: string]: boolean;
}

interface FeedMixPrintProps {
  name: string;
  handleOnChange: (value: string) => void;
  sectionsToPrint: ISectionsToPrint;
  setPrintFor: (value: [string]) => void;
  setPrintForValues?: (value: number[], category: string) => void;
  setIncludeAllNutrients: (value: boolean) => void;
  includeAllNutrients: boolean;
  printFor: string[];
}

interface PrintForTable {
  printMixName?: string;
  Alt1: number;
  Alt2: number;
  Alt3: number;
  Alt4: number;
  Alt5: number;
}

/**
 * @description Displays Print page for Feed mix & handles print config updates
 */
const FeedMixPrint = ({
  name,
  handleOnChange,
  sectionsToPrint,
  setPrintFor,
  setPrintForValues,
  setIncludeAllNutrients,
  includeAllNutrients,
  printFor,
}: FeedMixPrintProps) => {
  const { t } = useTranslation();
  const [printForEnabled, togglePrintForEnabled] = useToggle(false);
  const [printNutrientsEnabled, togglePrintNutrientsEnabled] = useToggle(false);

  useEffect(() => {
    if (sectionsToPrint.MixRecipe && !printForEnabled) {
      togglePrintForEnabled(true);
    } else if (!sectionsToPrint.MixRecipe && printForEnabled) {
      togglePrintForEnabled(false);
    }

    if (sectionsToPrint.NutrientsSection && !printNutrientsEnabled) {
      togglePrintNutrientsEnabled(true);
    } else if (!sectionsToPrint.NutrientsSection && printNutrientsEnabled) {
      togglePrintNutrientsEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsToPrint]);

  const handleTableUpdate = (row: PrintForTable) => {
    const rowValues = Object.values(row).filter((value) => typeof value === "number") as number[];

    if (!setPrintForValues || !rowValues || !row.printMixName) {
      return;
    }

    setPrintForValues(rowValues, row.printMixName);
  };

  return (
    <>
      <h3>{name}</h3>
      <div className="section">
        <h3>{t("printSections")}</h3>
        <ul>
          {printConfig.sectionsToPrint.feedMix.map((section, index) => (
            <li key={section}>
              <label className="print-checkbox lm__checkbox lm__tick" htmlFor={`print-${index}`}>
                <input
                  id={`print-${index}`}
                  type="checkbox"
                  checked={sectionsToPrint[section]}
                  onChange={() => {
                    handleOnChange(section);
                  }}
                />

                <label />
                <span className="margin-left-5">{t(section)}</span>
              </label>
            </li>
          ))}
        </ul>

        <div className={`margin-top-30 ${!printNutrientsEnabled && "disabled"}`}>
          <h3>{t("nutrientHeader")}</h3>
          <label className="print-checkbox lm__checkbox lm__tick" htmlFor="print-all">
            <input
              id="print-all"
              type="checkbox"
              checked={includeAllNutrients}
              onChange={() => setIncludeAllNutrients(!includeAllNutrients)}
            />
            <label />
            <span className="margin-left-5">{t("includeNutrientsControlParameters")}</span>
          </label>
        </div>
      </div>

      <div className="section">
        <h3>{t("printMixFor")}</h3>
        <div className={`print-for ${!printForEnabled && "disabled"}`}>
          <div className="print-for-radio margin-bottom-30 margin-right-15">
            {printConfig.printMixFor.map((section, index) => (
              <div key={section} className="lm__radio type-2 width-100 ">
                <input
                  id={`printFor-${index}`}
                  type="radio"
                  defaultChecked={printFor.includes(section)}
                  name="printMixFor"
                  onClick={() => {
                    setPrintFor([section]);
                  }}
                />
                <p className="margin-left-5">{t(section)}</p>
              </div>
            ))}
          </div>
          <div className="print-for-table">
            <Table
              data={printConfig.printMixForDefaultData}
              typeSpecs={typeSpecsPrintFor}
              onUpdatedRow={handleTableUpdate}
              keyPrefix="feedMixPrint"
              disableHighlight
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedMixPrint;
