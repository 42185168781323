import { useRef, useEffect, useMemo } from "react";
import { debounce } from "lodash";

const useDebounce = <T extends (...args: any[]) => any>(callback: T, delay: number): T => {
  const ref = useRef<() => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, delay);
  }, [delay]);

  return debouncedCallback as unknown as T;
};

export default useDebounce;
