/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useErrorHandler } from "react-error-boundary";

import CustomerFeeds from "pages/customer-feeds/parts/CustomerFeeds";
import customerFeedsTypeSpecs from "pages/customer-feeds/json/typeSpecsOfCustomerFeed.json";
import standardFeedsTypeSpecs from "pages/standard-feeds/json/typeSpecsOfStandardFeed.json";

import { StandardFeedsContext } from "pages/standard-feeds/context/StandardFeedsProvider";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";

import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useConfirm from "hooks/useConfirm";

import {
  setCustomerFeeds,
  clearCustomerFeeds,
  updateSelectedCustomerFeed,
  addNewCustomerFeed,
  removeCustomerFeed,
} from "pages/customer-feeds/context/customerFeedsActions";

import {
  setStandardFeeds,
  updateSelectedStandardFeed,
} from "pages/standard-feeds/context/standardFeedsActions";

import AddRemoveFeedsNew from "components/add-remove-feeds/AddRemoveFeeds";
import BasePage from "components/base-page/BasePage";

import useToggle from "hooks/useToggle";
import useFeedHandler from "hooks/useFeedHandler";
import useOnPageLeave from "hooks/useOnPageLeave";
import useSaveBeforeRouteChange from "hooks/useSaveBeforeRouteChange";

import {
  BASE_CUSTOMER_FEEDS_URL,
  BASE_STANDARD_FEEDS_URL,
  BASE_FEED_MIX_URL,
} from "assets/constants";
import { setCurrentCustomer } from "pages/choose-customer/context/customerActions";
import ITypeSpec from "interfaces/ITypeSpec";
import IStandardFeed from "interfaces/IStandardFeed";
import IIndexable from "interfaces/IIndexable";
import ICustomerFeed from "interfaces/ICustomerFeed";
import { Feed } from "types";
import LMButton from "components/lm-button/LMButton";

interface CustomerFeedsPageParams {
  customerId: string;
}

const CustomerFeedsPage = (): JSX.Element => {
  const {
    standardFeedsState,
    standardFeedsState: { feeds: standardFeeds, selectedFeed: selectedStandardFeed },
    standardFeedsDispatch,
  } = useContext(StandardFeedsContext);

  const {
    customerFeedsState,
    customerFeedsState: {
      feeds: customerFeeds,
      selectedFeed: selectedCustomerFeed,
      feedsToAdd,
      feedsToDelete,
      feedsToUpdate,
    },
    customerFeedsDispatch,
  } = useContext(CustomerFeedsContext);

  const {
    customerState: { currentCustomer, customerDetails },
    customerDispatch,
    getCustomerDetails,
  } = useContext(CustomerContext);

  const { copyFeedMixToDatabase } = useSaveFeedRequest();

  const history = useHistory();
  const [displayAddRemoveFeeds, toggleDisplayAddRemoveFeeds] = useToggle(false);
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const handleError = useErrorHandler();
  const { isConfirmed } = useConfirm();
  const { customerId }: CustomerFeedsPageParams = useParams();
  const cTypeSpecs: ITypeSpec = customerFeedsTypeSpecs;
  const sTypeSpecs: ITypeSpec = standardFeedsTypeSpecs;
  const propsToDisplayInAddRemove = ["feedGroup", "feedName", "feedNumber", "id"];
  const [filteredFeeds, setFilteredFeeds] = useState<IStandardFeed[]>([]);
  const [searchInput, setSearchInput] = useState<string>();

  const {
    addASpecificFeed,
    removeSpecificFeed,
    getFeeds: getCustomerFeeds,
    isLoading: isCustomerFeedsLoading,
    clearFeeds,
    postFeeds,
    deleteFeeds,
    putFeeds,
  } = useFeedHandler(customerFeedsState);

  const { getFeeds: getStandardFeeds, isLoading: isStandardFeedsLoading } =
    useFeedHandler(standardFeedsState);

  const fetchCustomerDetails = useCallback(async () => {
    try {
      await getCustomerDetails(customerId);
    } catch (error) {
      return handleError(error);
    }
  }, [customerId, getCustomerDetails]);

  const fetchCustomerFeeds = useCallback(async (id: string) => {
    try {
      const result = await getCustomerFeeds(`${BASE_CUSTOMER_FEEDS_URL}?customerId=${id}`);
      customerFeedsDispatch(setCustomerFeeds(result));
    } catch (error) {
      return handleError(error);
    }
  }, []);

  const fetchStandardFeeds = async () => {
    try {
      const result = await getStandardFeeds(BASE_STANDARD_FEEDS_URL);
      standardFeedsDispatch(setStandardFeeds(result));
    } catch (error) {
      return handleError(error);
    }
  };

  const handleOnCustomerFeedSelection = useCallback(
    (selectedRow: any) => {
      customerFeedsDispatch(updateSelectedCustomerFeed(selectedRow));
    },
    [customerFeedsDispatch]
  );

  const handleOnStandardFeedSelection = useCallback(
    (selectedRow: any) => {
      standardFeedsDispatch(updateSelectedStandardFeed(selectedRow));
    },
    [standardFeedsDispatch]
  );

  const addStandardFeedToCustomerFeed = useCallback(
    (doubleClickedFeed?: IStandardFeed) => {
      if (!selectedStandardFeed && !doubleClickedFeed) {
        isConfirmed(t("noSelectedRow"), "alert");
        return;
      }

      let standardFeedToAddToCustomerFeeds;

      if (doubleClickedFeed) {
        standardFeedToAddToCustomerFeeds = standardFeeds.find(
          (standardFeed) => standardFeed.id === doubleClickedFeed.id
        );
      } else if (selectedStandardFeed) {
        standardFeedToAddToCustomerFeeds = standardFeeds.find(
          (standardFeed) => standardFeed.id === selectedStandardFeed.id
        );
      }

      if (!standardFeedToAddToCustomerFeeds) {
        return;
      }

      const uniquePropertiesOfCustomerFeed: IIndexable = {
        isMixed: false,
        isActive: true,
        customerId: customerDetails?.customer.customerId,
        customer: customerDetails?.customer,
      };

      const newCustomerFeed: ICustomerFeed = {
        ...standardFeedToAddToCustomerFeeds,
      } as unknown as ICustomerFeed;

      Object.keys(uniquePropertiesOfCustomerFeed).forEach((key) => {
        newCustomerFeed[key] = uniquePropertiesOfCustomerFeed[key];
      });

      if (standardFeedToAddToCustomerFeeds) {
        const { feedGroup, feedName, feedNumber } = newCustomerFeed;

        // Find duplicates already in left table
        const duplicatesFeeds = customerFeeds.filter(
          (feed) =>
            feed.feedGroup === feedGroup &&
            feed.feedName &&
            feedName &&
            feed.feedName.includes(feedName) &&
            feed.feedNumber === feedNumber
        );
        // Find duplicates in left table added now
        const duplicatesFeedsToAdd = feedsToAdd.filter(
          (_feed) =>
            _feed.feedGroup === feedGroup &&
            _feed.feedName &&
            feedName &&
            _feed.feedName.includes(feedName) &&
            _feed.feedNumber === feedNumber
        );

        /* This extra filter is needed if adding multiple of a
      feed that didn't exist in left table before */

        const duplicates = duplicatesFeeds
          .concat(duplicatesFeedsToAdd)
          .filter(
            (feed, index, arr) => index === arr.findIndex((item) => item.feedName === feed.feedName)
          );

        // If we have duplicates we add (index) to the feedName
        /**
         * If we have duplicates we add (index) to the feedName based on
         * duplicates length. We need to check if it already exists a feed
         * with (index), in that case we + on the index. This could happen
         * if a user has added three feeds feed, feed(1), feed(2) and
         * then removed feed(1).
         */
        if (duplicates && duplicates.length) {
          if (
            !duplicates.some(
              (duplicate) =>
                duplicate.feedName === `${newCustomerFeed.feedName}(${duplicates.length})`
            )
          ) {
            newCustomerFeed.feedName = `${newCustomerFeed.feedName}(${duplicates.length})`;
          } else {
            newCustomerFeed.feedName = `${newCustomerFeed.feedName}(${duplicates.length + 1})`;
          }
        }
      }

      customerFeedsDispatch(addNewCustomerFeed(addASpecificFeed(newCustomerFeed)));
    },
    [
      feedsToAdd,
      currentCustomer,
      customerFeedsDispatch,
      selectedStandardFeed,
      standardFeeds,
      standardFeedsDispatch,
      t,
    ]
  );

  const removeCustomerFeedAndPutItBackIntoStandardFeed = useCallback(() => {
    if (!selectedCustomerFeed) {
      isConfirmed(t("noSelectedRow"), "alert");
      return;
    }

    const customerFeedToRemove: ICustomerFeed | undefined = customerFeeds.find(
      (customerFeed) => customerFeed.id === selectedCustomerFeed.id
    );

    if (!customerFeedToRemove) {
      return;
    }

    customerFeedsDispatch(removeCustomerFeed(removeSpecificFeed(customerFeedToRemove)));
  }, [selectedCustomerFeed, customerFeeds, customerFeedsDispatch, t]);

  const clearFeedsCallback = useCallback(() => {
    customerFeedsDispatch(clearCustomerFeeds(clearFeeds()));
  }, [customerFeedsDispatch]);

  const promptSaveToDB = async () => {
    const answer = await isConfirmed(t("unsavedChanges"), "reset", () => {});
    if (answer) {
      if (feedsToAdd.length) {
        const mixFeedsToAdd: Feed[] = [];
        const customerFeedsToAdd = feedsToAdd
          .map((feed) => {
            if (feed.isMixed) {
              mixFeedsToAdd.push(feed);
            }
            return feed;
          })
          .filter((_feed) => !_feed.isMixed);
        if (customerFeedsToAdd.length) {
          await postFeeds(BASE_CUSTOMER_FEEDS_URL, customerFeedsToAdd);
        }
        if (mixFeedsToAdd.length) {
          mixFeedsToAdd.forEach(async (feed) => {
            const { feedMixToCopy, feedName, feedNumber } = feed;
            if (!feedMixToCopy || !feedName || !feedNumber) {
              return;
            }
            await copyFeedMixToDatabase(feedMixToCopy, feedName, feedNumber);
          });
        }
      }
      if (feedsToDelete.length) {
        const mixFeedsToDelete: ICustomerFeed[] = [];
        const customerFeedsToDelete = feedsToDelete
          .map((feed) => {
            if (feed.isMixed) {
              mixFeedsToDelete.push(feed);
            }
            return feed;
          })
          .filter((_feed) => !_feed.isMixed);
        if (customerFeedsToDelete.length) {
          await deleteFeeds(BASE_CUSTOMER_FEEDS_URL, customerFeedsToDelete);
        }
        if (mixFeedsToDelete.length) {
          await deleteFeeds(BASE_FEED_MIX_URL, mixFeedsToDelete);
        }
      }

      if (feedsToUpdate.length) {
        const mixFeedsToUpdate: Feed[] = [];
        const customerFeedsToUpdate = feedsToUpdate
          .map((feed) => {
            if (feed.isMixed) {
              mixFeedsToUpdate.push(feed);
            }
            return feed;
          })
          .filter((_feed) => !_feed.isMixed);

        if (mixFeedsToUpdate.length) {
          await putFeeds(BASE_FEED_MIX_URL, mixFeedsToUpdate);
        }
        if (customerFeedsToUpdate.length) {
          await putFeeds(BASE_CUSTOMER_FEEDS_URL, customerFeedsToUpdate);
        }
        clearFeedsCallback();
      }
    }
  };

  const handleOnClick = async () => {
    if (feedsToAdd.length || feedsToDelete.length) {
      try {
        await promptSaveToDB();
      } catch (error) {
        return handleError(error);
      }
    }
    toggleDisplayAddRemoveFeeds();
  };

  const filterOutMixFeeds = (feeds: ICustomerFeed[]): ICustomerFeed[] =>
    feeds.filter((feed) => !feed.isMixed);

  const unsaved = !!(feedsToAdd.length || feedsToDelete.length || feedsToUpdate.length);
  useOnPageLeave(unsaved);

  useEffect(() => {
    if (customerId && currentCustomer && !standardFeeds.length) {
      fetchStandardFeeds();
    }

    if (!customerId && currentCustomer) {
      history.replace(`${url}/${currentCustomer.customerId}`);
    } else if (!customerId && !currentCustomer) {
      isConfirmed(t("mustChooseCustomer"), "alert");
      history.replace("/choose-customer");
    }
  }, [currentCustomer, customerId, history, t, url]);

  useEffect(() => {
    if (customerId && !currentCustomer) {
      fetchCustomerDetails();
    }
  }, [currentCustomer, customerId, fetchCustomerDetails]);

  useEffect(() => {
    if (customerDetails && !currentCustomer) {
      customerDispatch(setCurrentCustomer(customerDetails.customer));
    }
  }, [currentCustomer, customerDetails, customerDispatch]);

  const handleOnRouteChange = async () => {
    if (feedsToAdd.length) {
      const mixFeedsToAdd: Feed[] = [];
      const customerFeedsToAdd = feedsToAdd
        .map((feed) => {
          if (feed.isMixed) {
            mixFeedsToAdd.push(feed);
          }
          return feed;
        })
        .filter((_feed) => !_feed.isMixed);
      if (customerFeedsToAdd.length) {
        await postFeeds(BASE_CUSTOMER_FEEDS_URL, customerFeedsToAdd);
      }
      
      if (mixFeedsToAdd.length) {
        const promises = mixFeedsToAdd.map(feed => {
          const { feedMixToCopy, feedName, feedNumber } = feed;
          if (!feedMixToCopy || !feedName || !feedNumber) {
            return null;
          }
          return copyFeedMixToDatabase(feedMixToCopy, feedName, feedNumber);
        }).filter(Boolean);
        await Promise.all(promises);
      }

    }
    if (feedsToDelete.length) {
      const mixFeedsToDelete: ICustomerFeed[] = [];
      const customerFeedsToDelete = feedsToDelete
        .map((feed) => {
          if (feed.isMixed) {
            mixFeedsToDelete.push(feed);
          }
          return feed;
        })
        .filter((_feed) => !_feed.isMixed);
      if (customerFeedsToDelete.length) {
        await deleteFeeds(BASE_CUSTOMER_FEEDS_URL, customerFeedsToDelete);
      }
      if (mixFeedsToDelete.length) {
        await deleteFeeds(BASE_FEED_MIX_URL, mixFeedsToDelete);
      }
    }

    if (feedsToUpdate.length) {
      const mixFeedsToUpdate: Feed[] = [];
      const customerFeedsToUpdate = feedsToUpdate
        .map((feed) => {
          if (feed.isMixed) {
            mixFeedsToUpdate.push(feed);
          }
          return feed;
        })
        .filter((_feed) => !_feed.isMixed);

      if (mixFeedsToUpdate.length) {
        await putFeeds(BASE_FEED_MIX_URL, mixFeedsToUpdate);
      }
      if (customerFeedsToUpdate.length) {
        await putFeeds(BASE_CUSTOMER_FEEDS_URL, customerFeedsToUpdate);
      }
    }
    clearFeedsCallback();
  };

  useSaveBeforeRouteChange({
    when: unsaved,
    callback: handleOnRouteChange,
    resetChanges: () => fetchCustomerFeeds(currentCustomer?.customerId!),
  });

  /**
   * Checks if double clicked row exists in the customers feeds.
   * Removes that feed from the customers feeds
   */
  const handleOnDoubleClickLeft = useCallback(
    (row: any) => {
      const selectedFeed: ICustomerFeed | undefined = customerFeeds.find(
        (feed) => feed.id === row.id
      );

      if (selectedFeed) {
        customerFeedsDispatch(removeCustomerFeed(removeSpecificFeed(selectedFeed)));
      }
    },
    [customerFeeds, customerFeedsDispatch]
  );

  /**
   * Checks if double clicked row exists in standard feeds.
   * Add that feed to the customers feeds
   */
  const handleOnDoubleClickRight = (row: any) => {
    const selectedFeed = standardFeeds.find((feed) => feed.id === row.id);
    if (selectedFeed) {
      addStandardFeedToCustomerFeed(selectedFeed);
    }
  };

  const handleFeedFilter = (value: string) => {
    const filteredStandardFeeds = standardFeeds.filter((feed) =>
      feed.feedName?.toLowerCase().includes(value.toLowerCase())
    );

    setSearchInput(value);
    if (!filteredStandardFeeds) {
      setFilteredFeeds([] as IStandardFeed[]);
    } else {
      setFilteredFeeds(filteredStandardFeeds);
    }
  };

  const rightSideData = searchInput ? filteredFeeds : standardFeeds;

  return (
    <BasePage pageName="customer-feeds">
      {!currentCustomer ? (
        <div role="button" tabIndex={0} onKeyPress={() => history.push("/choose-customer")}>
          <h2 className="text-center">{t("customerOverviewNoCustomerSelected")}</h2>
        </div>
      ) : (
        <>
          {displayAddRemoveFeeds && (
            <>
              <div className="buttons-wrapper">
                <LMButton click={handleOnClick} text={t("done")} type="button" />
              </div>

              <AddRemoveFeedsNew
                leftSidePropsToDisplay={propsToDisplayInAddRemove}
                leftSideName={t("customerFeeds")}
                leftSideData={filterOutMixFeeds(customerFeeds)}
                leftSideTypeSpecs={cTypeSpecs}
                isLeftSideLoading={isCustomerFeedsLoading}
                onLeftSideSelection={handleOnCustomerFeedSelection}
                onLeftButtonClick={addStandardFeedToCustomerFeed}
                rightSidePropsToDisplay={propsToDisplayInAddRemove}
                rightSideName={t("standardFeeds")}
                rightSideData={rightSideData}
                rightSideTypeSpecs={sTypeSpecs}
                isRightSideLoading={isStandardFeedsLoading}
                onRightSideSelection={handleOnStandardFeedSelection}
                onRightButtonClick={removeCustomerFeedAndPutItBackIntoStandardFeed}
                onDoubleClickLeft={handleOnDoubleClickLeft}
                onDoubleClickRight={handleOnDoubleClickRight}
                handleFeedFilter={handleFeedFilter}
              />
            </>
          )}
          {!displayAddRemoveFeeds && (
            <CustomerFeeds
              currentCustomer={currentCustomer}
              toggle={toggleDisplayAddRemoveFeeds}
              isLoading={isCustomerFeedsLoading}
              fetchCustomerFeeds={fetchCustomerFeeds}
              unsavedCustomerFeeds={unsaved}
              saveUnsavedCustomerFeeds={promptSaveToDB}
            />
          )}
        </>
      )}
    </BasePage>
  );
};

export default withAuthenticationRequired(CustomerFeedsPage);
