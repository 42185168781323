interface IButtonProps {
  click?: (arg?: any) => void;
  disabled?: true | false;
  text?: string | JSX.Element;
  type: "button" | "submit";
  className?: string | JSX.Element;
}

const LMButton = ({ click, disabled, text, type, className }: IButtonProps): JSX.Element => {
  const cssClasses: string[] = [`lm__primary-btn margin-left-10 ${className || "reverse-state"}`];

  if (disabled) {
    cssClasses.push("disabled");
  }

  const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (click) {
      click();
    }
  };

  return (
    <button
      className={cssClasses.join(" ")}
      disabled={disabled ? true : undefined}
      onClick={handleOnClick}
      type={type === "button" ? "button" : "submit"}
    >
      {text}
    </button>
  );
};

export default LMButton;
