/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";

import ChooseCustomerPage from "pages/choose-customer/ChooseCustomerPage";
import CustomerFeedsPage from "pages/customer-feeds/CustomerFeedsPage";
import CustomerOverviewPage from "pages/customer-overview/CustomerOverviewPage";
import HomePage from "pages/home/HomePage";
import Logout from "pages/logout/Logout";
import FeedMixPage from "pages/feed-mix/FeedMixPage";
import FeedPlanCalculationPage from "pages/feed-plan-calculation/FeedPlanCalculationPage";
import StandardFeedsPage from "pages/standard-feeds/StandardFeedsPage";

import Navbar from "components/navbar/Navbar";
import Modal from "components/modal/Modal";
import ModalContent from "components/modal/parts/SaveAlertModal";

import useConfirm from "hooks/useConfirm";

// Import i18 to be bundeled
import "./i18n";

const App = (): JSX.Element => {
  const { getCurrentUser } = useContext(CustomerContext);
  const { prompt = "", isOpen = false, proceed, cancel, type, resetChanges } = useConfirm();

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <HashRouter getUserConfirmation={() => {}}>
      <div className="app lm__contents tab-navigation margin-top-20">
        <Navbar />
        <Switch>
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/choose-customer" component={ChooseCustomerPage} />
          <Route exact path="/customer-overview/:customerId?" component={CustomerOverviewPage} />
          <Route path="/feed-plan-calculation" component={FeedPlanCalculationPage} />
          <Route exact path="/customer-feeds/:customerId?" component={CustomerFeedsPage} />
          <Route path="/feed-mix/:feedNumber?" component={FeedMixPage} />
          <Route exact path="/standard-feeds" component={StandardFeedsPage} />
        </Switch>
        <Modal show={isOpen} hide={cancel}>
          <ModalContent
            contentText={prompt}
            proceed={proceed}
            cancel={cancel}
            type={type}
            resetChanges={resetChanges}
          />
        </Modal>
      </div>
    </HashRouter>
  );
};

export default App;
