/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useMemo, useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useErrorHandler } from "react-error-boundary";

import typeSpecs from "pages/customer-feeds/json/typeSpecsOfCustomerFeed.json";
import buttons from "pages/customer-feeds/json/customerFeedsPageButtons.json";

import Modal from "components/modal/Modal";
import ModalContent from "pages/customer-feeds/parts/SaveModalContent";
import LMButton from "components/lm-button/LMButton";
import Table from "components/table/Table";
import Header from "components/header/Header";

import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";

import {
  addCustomerFeedToCustomerFeedsToUpdate,
  clearCustomerFeeds,
  editCustomerFeedsToAdd,
  updateCustomerFeedsAfterCalculation,
  updateSelectedCustomerFeed,
} from "pages/customer-feeds/context/customerFeedsActions";

import useModal from "hooks/useModal";
import useCalcSFR from "hooks/useCalcSFR";
import useFeedHandler from "hooks/useFeedHandler";
import useConfirm from "hooks/useConfirm";

import ICustomerFeed from "interfaces/ICustomerFeed";
import ICustomer from "interfaces/ICustomer";
import ITypeSpec from "interfaces/ITypeSpec";
import ITableDataRow from "interfaces/ITableDataRow";

interface ICustomerFeedsPageButton {
  id: number;
  disabled: boolean;
  purpose: string;
  text: string;
  type: string;
}

interface CustomerFeedsProps {
  isLoading: boolean;
  toggle?: () => void;
  currentCustomer: ICustomer | undefined;
  fetchCustomerFeeds: (customerId: string) => Promise<void>;
  unsavedCustomerFeeds?: boolean;
  saveUnsavedCustomerFeeds: () => void;
}

const CustomerFeeds = ({
  isLoading,
  toggle,
  currentCustomer,
  fetchCustomerFeeds,
  unsavedCustomerFeeds,
  saveUnsavedCustomerFeeds,
}: CustomerFeedsProps): JSX.Element => {
  const {
    customerFeedsState,
    customerFeedsState: { feeds, feedsToAdd, selectedFeed },
    customerFeedsDispatch,
  } = useContext(CustomerFeedsContext);
  const {
    customerState: { readOnlyMode, userHiddenTableColumns },
  } = useContext(CustomerContext);

  const [lastUpdatedCustomerFeed, setLastUpdatedCustomerFeed] = useState<ICustomerFeed>();
  const [toggleCalcSFRButton, setToggleCalcSFRButton] = useState(false);
  const [currentSortOrder, setCurrentSortOrder] = useState<ITableDataRow[]>();
  const [isActiveFilter, toggleIsActiveFilter] = useState(false);
  const [activeFeeds, setIsActiveFeeds] = useState<ICustomerFeed[]>();
  const feedHandler = useFeedHandler(customerFeedsState);
  const handleError = useErrorHandler();
  const modalHandler = useModal();
  const { isConfirmed } = useConfirm();
  const customerFeedsTypeSpecs = useRef<ITypeSpec>(typeSpecs);
  const { calc } = useCalcSFR();
  const { t } = useTranslation();
  const { language } = i18n;

  const handleCalcSFR = useCallback(async () => {
    if (selectedFeed) {
      try {
        const calculatedFeed: ICustomerFeed = await calc(selectedFeed);
        if (calculatedFeed) {
          customerFeedsDispatch(
            updateCustomerFeedsAfterCalculation(calculatedFeed, currentSortOrder)
          );
        }
      } catch (error) {
        return handleError(error);
      }
    } else {
      isConfirmed(t("noSelectedRow"), "alert");
    }
  }, [calc, customerFeedsDispatch, selectedFeed, t, currentSortOrder]);

  const handleOnClick = useCallback(
    async (button: ICustomerFeedsPageButton) => {
      switch (button.purpose) {
        case "add/remove":
          if (unsavedCustomerFeeds && currentCustomer?.customerId) {
            saveUnsavedCustomerFeeds();
            await fetchCustomerFeeds(currentCustomer?.customerId);
          } else if (toggle) {
            customerFeedsDispatch(clearCustomerFeeds(feedHandler.clearFeeds("DELETE")));
            toggle();
            break;
          }
          break;
        case "calcSFR/LFU":
          if (toggleCalcSFRButton) {
            return handleCalcSFR();
          }
          break;
        case "saveSelectedAsNew":
          if (!selectedFeed) {
            isConfirmed(t("noSelectedRow"), "alert");
            break;
          }
          return modalHandler.toggleState();

        case "save":
          if (!unsavedCustomerFeeds || !currentCustomer?.customerId) {
            return isConfirmed(t("nothingToSave"), "alert");
          }
          try {
            await saveUnsavedCustomerFeeds();
            await fetchCustomerFeeds(currentCustomer?.customerId);
            return await isConfirmed(t("saveSuccessful"), "alert");
          } catch (error) {
            return isConfirmed(t("saveUnsuccessful"), "alert");
          }

        default:
          return;
      }

      customerFeedsDispatch(updateSelectedCustomerFeed());
    },
    [
      toggleCalcSFRButton,
      customerFeedsDispatch,
      handleCalcSFR,
      modalHandler,
      selectedFeed,
      t,
      toggle,
      unsavedCustomerFeeds,
    ]
  );

  const onSelectedCustomerFeed = useCallback(
    async (newSelectedRow: any) => {
      const copyNewSelectedRow = newSelectedRow;
      if (copyNewSelectedRow) {
        if (copyNewSelectedRow.isMixed && !copyNewSelectedRow.feedMixToCopy) {
          copyNewSelectedRow.feedMixToCopy = newSelectedRow?.id;
        }

        customerFeedsDispatch(updateSelectedCustomerFeed(copyNewSelectedRow));
        if (copyNewSelectedRow.supportsLfu === false && copyNewSelectedRow.supportsSfr === false) {
          setToggleCalcSFRButton(false);
        } else if (copyNewSelectedRow.feedGroup && copyNewSelectedRow.feedGroup === 99) {
          setToggleCalcSFRButton(false);
        } else {
          setToggleCalcSFRButton(true);
        }
      }

      if (copyNewSelectedRow === undefined && !modalHandler.toggleModal) {
        setToggleCalcSFRButton(false);
        customerFeedsDispatch(updateSelectedCustomerFeed());
      }
    },
    [modalHandler.toggleModal, customerFeedsDispatch]
  );

  const onUpdatedCustomerFeed = useCallback((updatedCustomerFeed: ICustomerFeed) => {
    onSelectedCustomerFeed(updatedCustomerFeed);
    setLastUpdatedCustomerFeed(updatedCustomerFeed);
  }, []);

  const handleUpdatedCustomerFeed = useCallback(() => {
    if (lastUpdatedCustomerFeed) {
      if (
        feedsToAdd.length &&
        feedsToAdd.some((feed: any) => feed.id === lastUpdatedCustomerFeed.id)
      ) {
        customerFeedsDispatch(
          editCustomerFeedsToAdd(feedHandler.editFeedsToAdd(lastUpdatedCustomerFeed))
        );
      } else {
        customerFeedsDispatch(
          addCustomerFeedToCustomerFeedsToUpdate(
            feedHandler.addFeedToFeedsToUpdate(lastUpdatedCustomerFeed)
          )
        );
      }
    }

    setLastUpdatedCustomerFeed(undefined);
  }, [customerFeedsDispatch, feedHandler, feedsToAdd, lastUpdatedCustomerFeed]);

  useEffect(() => {
    const asyncFetchCustomerFeeds = async () => {
      try {
        if (currentCustomer?.customerId) {
          await fetchCustomerFeeds(currentCustomer.customerId);
        }
      } catch (error) {
        return handleError(error);
      }
    };
    asyncFetchCustomerFeeds();
  }, [currentCustomer?.customerId]);

  useEffect(() => {
    if (lastUpdatedCustomerFeed) {
      handleUpdatedCustomerFeed();
    }
  }, [handleUpdatedCustomerFeed, lastUpdatedCustomerFeed]);

  /**
   * This useEffect keeps all active feeds in a state.
   * If the user has "show only active feeds" filter on
   * we fill the table with these feeds.
   * It runs everytime we add or remove a feed.
   */
  useEffect(() => {
    setIsActiveFeeds(feeds.filter((_feed) => _feed.isActive));
  }, [feeds.length]);

  const renderButtons = useMemo(
    (): JSX.Element[] =>
      buttons.map((button) => {
        if (feeds.length) {
          let isDisabled: boolean | undefined = true;
          if (!button.disabled || toggleCalcSFRButton === true) {
            isDisabled = undefined;
          }

          return (
            <LMButton
              key={button.id}
              click={() => handleOnClick(button)}
              disabled={isDisabled}
              text={t(button.text)}
              type="button"
            />
          );
        }

        if (button.text === "addRemoveFeeds") {
          return (
            <LMButton
              key={button.id}
              click={() => handleOnClick(button)}
              text={t(button.text)}
              type="button"
              disabled={readOnlyMode}
            />
          );
        }

        return (
          <LMButton
            key={button.id}
            click={() => handleOnClick(button)}
            disabled
            text={t(button.text)}
            type="button"
          />
        );
      }),
    [toggleCalcSFRButton, feeds.length, handleOnClick, t, readOnlyMode]
  );

  const readOnlyClass = readOnlyMode ? "read-only" : "";
  const customerFeedsHiddenColumns = userHiddenTableColumns
    ? userHiddenTableColumns.customerFeedColumns
    : undefined;

  return (
    <>
      <Header
        title="customerFeeds"
        hideColumnsDropdown={feeds[0]}
        hideColumnsTableType="customerFeedColumns"
      />
      <div className="sub-header-buttons margin-bottom-15">
        <div className="left-wrapper">
          <h3 className="customer-name">
            {!currentCustomer && ""}
            {currentCustomer && currentCustomer.name}
            {currentCustomer && !currentCustomer.name && currentCustomer.fullName1}
          </h3>
          <label
            className="lm__checkbox lm__tick show-only-active-checkbox"
            htmlFor="showOnlyActive"
          >
            <input
              type="checkbox"
              checked={isActiveFilter}
              id="showOnlyActive"
              onChange={() => {
                toggleIsActiveFilter(!isActiveFilter);
              }}
            />
            <label />
            <span className="margin-left-5">{t("showOnlyActiveFeeds")}</span>
          </label>
        </div>
        <div className="buttons-wrapper">{renderButtons}</div>
      </div>
      {feeds.length ? (
        <div
          className={`sticky-col-4 ${readOnlyClass} sticky-header ${
            (language === "sv" || language === "lang-SV") && "lang-sv"
          }`}
        >
          {/**
           * Data in table depends on isActiveFilter.
           * If filter is active we send activeFeeds from state.
           * If filter is NOT active we send all feeds.
           */}
          <Table
            data={isActiveFilter && activeFeeds ? activeFeeds : feeds}
            keyPrefix="feedParameters"
            onSelectedRow={onSelectedCustomerFeed}
            onUpdatedRow={onUpdatedCustomerFeed}
            typeSpecs={customerFeedsTypeSpecs.current}
            readOnly={readOnlyMode}
            userHiddenTableColumns={customerFeedsHiddenColumns}
            unsavedItems={unsavedCustomerFeeds}
            getSortOrder={(tableSortOrder) => setCurrentSortOrder(tableSortOrder)}
          />
        </div>
      ) : (
        <h3>{isLoading ? t("loading") : t("emptyCustomerFeedsResult")}</h3>
      )}
      <Modal
        headerText="saveAsNewFeed"
        show={modalHandler.toggleModal}
        hide={modalHandler.toggleState}
      >
        <ModalContent
          saveFeed={feedHandler.saveFeedNameFromModal}
          closeModal={modalHandler.toggleState}
        />
      </Modal>
    </>
  );
};

export default CustomerFeeds;
