import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ErrorBoundary } from "react-error-boundary";
import configuration from "config";
import StandardFeedsProvider from "pages/standard-feeds/context/StandardFeedsProvider";
import CustomerFeedsProvider from "pages/customer-feeds/context/CustomerFeedsProvider";
import CustomerProvider from "pages/choose-customer/context/CustomerProvider";
import ConfirmProvider from "components/modal/context/ConfirmContextProvider";
import ErrorPage from "components/error-page/ErrorPage";
import App from "./App";

// This is the main scss file where all other scss files are being imported.
import "./assets/scss/main.scss";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={configuration.authDomain}
      clientId={configuration.authClientId}
      audience={configuration.authAudience}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <ConfirmProvider>
          <StandardFeedsProvider>
            <CustomerFeedsProvider>
              <CustomerProvider>
                <App />
              </CustomerProvider>
            </CustomerFeedsProvider>
          </StandardFeedsProvider>
        </ConfirmProvider>
      </ErrorBoundary>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("feedSmartApp")
);
