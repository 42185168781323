import { useCallback } from "react";

function useConversion() {
  const removeAllNonNumbersExceptPeriod = useCallback(
    (value: string): string => value.replace(/[^\d.]/g, ""),
    []
  );

  return { removeAllNonNumbersExceptPeriod };
}

export default useConversion;
