import { useCallback, useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";

interface IDropdownProps {
  onChange?: (arg?: any) => void;
  disabled?: true | false;
  className?: string;
  children?: JSX.Element[];
  multiple?: true | false;
  placeholder?: string;
}

const LMDropdown = ({
  onChange,
  disabled,
  className,
  children,
  multiple,
  placeholder,
}: IDropdownProps): JSX.Element => {
  const nutrientItems = useRef<HTMLUListElement | null>(null);
  const nutrientDropdown = useRef<HTMLDivElement | null>(null);

  const toggleDropdown: React.MouseEventHandler<HTMLSpanElement> = () => {
    if (!disabled) {
      const classList = nutrientItems.current?.classList;
      classList?.toggle("visible");
    }
  };

  const handleClickOutside = useCallback(() => {
    const classList = nutrientItems.current?.classList;
    classList?.remove("visible");
  }, []);

  useOnClickOutside(nutrientDropdown, handleClickOutside);

  const disabledClass = disabled ? "disabled" : "";

  return (
    <div ref={nutrientDropdown} className="lm-dropdown-div">
      {multiple === true ? (
        <div className={`dropdown-check-list ${disabledClass}`}>
          {/* eslint-disable-next-line */}
          <div className="anchor" onClick={toggleDropdown}>
            {placeholder}
          </div>
          <ul ref={nutrientItems} className="items">
            {children}
          </ul>
        </div>
      ) : (
        <select onChange={onChange} disabled={disabled} className={`lm-dropdown ${className}`}>
          {children}
        </select>
      )}
    </div>
  );
};

export default LMDropdown;
