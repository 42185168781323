/**
 * @description Function to convert JSON Array to Object
 * @param jsonArray 
 * @returns {Object} jsonObject
 */
export const convertJsonArrayToObject = (jsonArray: any) => Object.fromEntries(jsonArray);

/**
 * @description Function to convert Object to JSON Array
 * @param {Object} dataObject
 * @returns {Array} jsonDataArray
 */
export const convertObjectToJsonArray = (dataObject: any) => {
  if (typeof dataObject !== "object" || dataObject === null) {
    throw new Error("Invalid input: dataObject must be an object");
  }

  const jsonDataArray: [string, any][] = [];

  Object.keys(dataObject).forEach((key) => {
    jsonDataArray.push([key, dataObject[key]]);
  });

  return jsonDataArray;
};

/**
 * @description Cows cannot eat too much, from a certain yield level, 100% will be too much.
 * So this adjusts the required amount of energy due to mobilization.
 * @param {number} arNe
 * @param {number} yieldLevel
 * @returns {number} arNe
 */
export const adjustForEnergyMobilization = (arNe: number, yieldLevel: number): number => {
  if (yieldLevel >= 48) {
    return arNe * 0.95;
  }

  if (yieldLevel >= 45) {
    return arNe * 0.97;
  }

  if (yieldLevel >= 42) {
    return arNe * 0.99;
  }

  return arNe;
};

/**
 * @description Cows cannot eat too much, from a certain yield level, 100% will be too much.
 * So this adjusts the required amount of protein due to mobilization.
 * @param {number} arMp
 * @param {number} yieldLevel
 * @returns {number} arMp
 */
export const adjustForProteinMobilization = (arMp: number, yieldLevel: number): number => {
  if (yieldLevel >= 48) {
    return arMp * 0.93;
  }

  if (yieldLevel >= 45) {
    return arMp * 0.95;
  }

  if (yieldLevel >= 42) {
    return arMp * 0.97;
  }

  return arMp;
};

/**
 * @description Function to get the needed amount of feed by NE
 * @param {number} rrNe 
 * @param {number} bKg 
 * @param {number} bNe 
 * @param {number} aNe 
 * @returns {number} calculated amount of feed
 */
export const getNeededAmountOfFeedAByNe = (
  rrNe: number,
  bKg: number,
  bNe: number,
  aNe: number
): number => {
  let aKg: number = 0;
  if (aNe > 0) {
    aKg = (rrNe - bKg * bNe) / aNe;
    aKg = Math.round(aKg * 100) / 100;
    aKg = parseFloat(aKg.toFixed(2));
  }
  return aKg;
};

/**
 * @description Function to get the needed amount of feed by MP
 * @param {number} rrMp 
 * @param {number} bKg 
 * @param {number} bMp 
 * @param {number} aMp 
 * @returns {number} calculated amount of feed
 */
export const getNeededAmoutOfFeedAByMp = (
  rrMp: number,
  bKg: number,
  bMp: number,
  aMp: number
): number => {
  let aKg: number = 0;
  if (aMp > 0) {
    aKg = (rrMp - bKg * bMp) / aMp;
    aKg = Math.round(aKg * 100) / 100;
    aKg = parseFloat(aKg.toFixed(2));
  }
  return aKg;
};
