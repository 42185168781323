/* eslint-disable no-restricted-syntax */
import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "components/table/Table";
import Loader from "components/loader/Loader";

import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";

import typeSpecsOfFeedMix from "pages/feed-mix/json/typeSpecsOfFeedMix.json";

import ITableDataRow from "interfaces/ITableDataRow";

interface ICustomerFeedPlanProps {
  isLoading?: boolean;
}

const CustomerFeedMixTable = ({ isLoading }: ICustomerFeedPlanProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    customerFeedsState: { feeds },
  } = useContext(CustomerFeedsContext);

  const trimCustomerFeedPlans = useCallback(
    () => {
      const propertiesToDisplay = ["id", "feedGroup", "feedNumber", "feedName", "updated"];

      const customerFeedMixes = feeds.filter((feed) => feed.isMixed);

      return customerFeedMixes.map((element) => {
        const trimmedElement: ITableDataRow = {};
        for (const key in element) {
          if (propertiesToDisplay.includes(key)) {
            trimmedElement[key] = element[key] ? element[key] : "";
          }
        }
        return trimmedElement;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feeds]
  );

  const onSelectedRow = useCallback(
    (selectedRow: ITableDataRow) => {
      if (!selectedRow) return;

      const clickedCustomerFeedPlan = feeds?.find(
        (customerFeedPlan) => customerFeedPlan.id === selectedRow.id
      );

      if (!clickedCustomerFeedPlan) return;

      history.push(`/feed-mix/${clickedCustomerFeedPlan.id}`);
    },
    [feeds, history]
  );

  let emptyTableText = (
    <>
      <Loader size="small" />
      <span className="margin-left-15">{t("loading")}</span>
    </>
  );
  if (feeds === undefined) {
    emptyTableText = <h3>{t("fetchingError")}</h3>;
  } else if (!isLoading && feeds?.filter((feed) => feed.isMixed).length === 0) {
    emptyTableText = <h3>{t("emptyFeedMixResult")}</h3>;
  }

  return feeds && feeds?.filter((feed) => feed.isMixed).length ? (
    <Table
      data={trimCustomerFeedPlans()}
      typeSpecs={typeSpecsOfFeedMix}
      keyPrefix="feedParameters"
      onSelectedRow={onSelectedRow}
    />
  ) : (
    <div>{emptyTableText}</div>
  );
};

export default CustomerFeedMixTable;
