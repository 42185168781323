/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Area,
  ComposedChart,
  Line,
  CartesianGrid,
  XAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import LMDropdown from "components/lm-dropdown/LMDropdown";

import IFeedPlanDropdownOptionsState from "interfaces/IFeedPlanDropdownOptionsState";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import IGraphChartResults from "interfaces/IGraphChartResults";
import IControlParameterSettingsRequest from "interfaces/IControlParameterSettingsRequest";

import { lowerFirstLetter } from "pages/feed-plan-calculation/parts/FeedPlanOverview";

import useSaveFeedRequest from "hooks/useSaveFeedRequest";

interface IGraphsWrapperProps {
  graphChartData: IGraphChartResults[];
  feedPlan: ICustomerFeedPlan;
  graphDropdownState: IFeedPlanDropdownOptionsState[] | undefined;
  updateGraphDropdownState: (
    graphDropdownState: IFeedPlanDropdownOptionsState[] | undefined,
    operation: string
  ) => void;
  enableEdit: boolean;
}
interface LineChartRow {
  name: string;
  steps: string;
  value: number;
  range: [number, number];
}

const GraphsWrapper = ({
  graphChartData,
  feedPlan,
  graphDropdownState,
  updateGraphDropdownState,
  enableEdit,
}: IGraphsWrapperProps) => {
  const { t } = useTranslation();
  const { t: tWithKeyPrefix } = useTranslation("", {
    keyPrefix: "controlParameters",
  });

  const { getDefaultControlParameterSettings } = useSaveFeedRequest();
  const [defaultParameters, setDefaultParameters] = useState<IControlParameterSettingsRequest[]>(
    []
  );

  useEffect(() => {
    const getDefaultParameters = async () => {
      const res = await getDefaultControlParameterSettings(feedPlan, true);
      setDefaultParameters(res);
    };
    getDefaultParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGraphOptionChange = useCallback(
    (position: number) => {
      // updating an object instead of a Map
      const updatedCheckedState = graphDropdownState?.map((item, index) => {
        if (index === position) {
          item.visible = !item.visible;
        }
        return item;
      });
      updateGraphDropdownState(updatedCheckedState, "graphSelection");
    },
    [updateGraphDropdownState, graphDropdownState]
  );

  const renderGraphParameterOptions = (): JSX.Element[] => {
    if (graphDropdownState && graphDropdownState.length) {
      return graphDropdownState.map((option, index) => (
        <li key={option.name} className="dropdown-item">
          <label className="lm__checkbox lm__tick dropdown-checkbox" htmlFor={option.name}>
            <input
              id={option.name}
              type="checkbox"
              value={option.value || ""}
              name="graphOptions"
              checked={graphDropdownState ? graphDropdownState[index].visible : false}
              onChange={() => handleGraphOptionChange(index)}
              disabled={graphDropdownState ? graphDropdownState[index].disable : false}
            />
            <label />
            <span className="margin-left-5">{tWithKeyPrefix(lowerFirstLetter(option.name))}</span>
          </label>
        </li>
      ));
    }
    return defaultParameters.map((option, index) => (
      <li key={option.name} className="dropdown-item">
        <label className="lm__checkbox lm__tick dropdown-checkbox" htmlFor={option.name}>
          <input
            id={option.name}
            type="checkbox"
            value={option.value || ""}
            name="graphOptions"
            checked={false}
            onChange={() => handleGraphOptionChange(index)}
            disabled
          />
          <label />
          <span className="margin-left-5">{tWithKeyPrefix(lowerFirstLetter(option.name))}</span>
        </label>
      </li>
    ));
  };

  /**
   * @description Function to generate data for line chart.
   * @param {Object} option - Object containing data for line chart.
   * @returns {Array} lineChartRowArray - Array of objects containing data for line chart.
   */
  const generateLineChartData = (option: IGraphChartResults) => {
    const lineChartRowArray: LineChartRow[] = [];
    const lineChartName = option.nutrientName;
    Object.entries(option).forEach(([key, value]) => {
      if (key !== "nutrientName") {
        lineChartRowArray.push({
          name: lineChartName,
          steps: key,
          value: value.value,
          range: [value.min, value.max],
        });
      }
    });
    return lineChartRowArray;
  };

  /**
   * @description Function to determine what lowest point in graph should be.
   * We get either the lowest input value from user or lowest min value
   * from yieldLevel. This value is used as graph starting point in Y-axis.
   * Eg yield X (min: 22, max: 44, value: "33") = 22 is returned.
   * Eg yield Y (min: 22, max: 44, value: "12") = 12 is returned.
   */
  const getMin = (option: any) => {
    const lowestMinValue: any = Object.values(option)
      .filter((x: any) => typeof x === "object")
      .reduce((prev: any, curr: any) => (prev.min < curr.min ? prev : curr));

    const lowestInputValue: any = Object.values(option)
      .filter((x: any) => typeof x === "object")
      .reduce((prev: any, curr: any) =>
        parseInt(prev.value) < parseInt(curr.value) ? prev : curr
      );

    if (lowestMinValue.min < parseInt(lowestInputValue.value)) {
      return lowestMinValue.min;
    }
    return parseInt(lowestInputValue.value);
  };

  /**
   * @description Function to determine what highest point in graph should be.
   * We get either the highest input value from user or highest max value
   * from yieldLevel. This value is used as graph ending point in Y-axis.
   * Eg yield X (min: 22, max: 44, value: "33") = 44 is returned.
   * Eg yield Y (min: 22, max: 44, value: "60") = 60 is returned.
   */
  const getMax = (option: any) => {
    const highestMinValue: any = Object.values(option)
      .filter((x: any) => typeof x === "object")
      .reduce((prev: any, curr: any) => (prev.min > curr.min ? prev : curr));

    const highestInputValue: any = Object.values(option)
      .filter((x: any) => typeof x === "object")
      .reduce((prev: any, curr: any) =>
        parseInt(prev.value) > parseInt(curr.value) ? prev : curr
      );

    if (highestMinValue.max > parseInt(highestInputValue.value)) {
      return highestMinValue.min;
    }
    return parseInt(highestInputValue.value);
  };

  const renderGraphCharts = useMemo(
    (): JSX.Element[] =>
      graphChartData.map((option) => (
        <div
          style={{ width: "100%", minWidth: "250px", height: "150px" }}
          className="card-2"
          key={option.nutrientName}
        >
          <ResponsiveContainer>
            <ComposedChart
              data={generateLineChartData(option)}
              margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
            >
              <XAxis dataKey="steps">
                <Label value={option.nutrientName} offset={0} position="bottom" />
              </XAxis>
              <YAxis hide domain={[getMin(option), getMax(option)]} />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <Area dataKey="range" stroke="#CCC" fill="#DFDFDF" />
              <Line type="monotone" dataKey="value" stroke="#009636" strokeOpacity="1" />
              <Tooltip position={{ y: -100 }} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )),
    [graphChartData]
  );

  return (
    <div className={`graphs-wrapper margin-right-15 ${!enableEdit ? "read-only" : "open"}`}>
      <div className="graphs-header">
        <LMDropdown multiple disabled={!enableEdit} placeholder={t("showGraphs")}>
          {renderGraphParameterOptions()}
        </LMDropdown>
      </div>
      <div className="graphs-content">
        <div className="card-row">{renderGraphCharts}</div>
      </div>
    </div>
  );
};

export default GraphsWrapper;
