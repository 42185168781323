import IAction from "interfaces/IAction";
import ITableDataRow from "interfaces/ITableDataRow";

// Action types
export const CLEAR_SELECTED_TABLE_DATA_ROW = "CLEAR_SELECTED_TABLE_DATA_ROW";
export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const SET_TABLE_DATA_KEY_PREFIX = "SET_TABLE_DATA_KEY_PREFIX";
export const SORT_TABLE_DATA_COLUMN = "SORT_TABLE_DATA_COLUMN";
export const UPDATE_TABLE_DATA_ROW = "UPDATE_TABLE_DATA_ROW";
export const UPDATE_SELECTED_TABLE_DATA_ROW = "UPDATE_SELECTED_TABLE_DATA_ROW";

// Action creators
export const clearSelectedTableDataRow = (): IAction => ({ type: CLEAR_SELECTED_TABLE_DATA_ROW });

export const setTableData = (tableData: ITableDataRow[]): IAction => ({
  type: SET_TABLE_DATA,
  payload: tableData,
});

export const sortTableDataColumn = (key: string, asc: boolean, groupSort?: string[]): IAction => ({
  type: SORT_TABLE_DATA_COLUMN,
  payload: { key, asc, groupSort },
});

export const setTableDataKeyPrefix = (keyPrefix: string): IAction => ({
  type: "SET_TABLE_DATA_KEY_PREFIX",
  payload: keyPrefix,
});

export const updateTableDataRow = (updatedTableDataRow: ITableDataRow, index: number): IAction => ({
  type: UPDATE_TABLE_DATA_ROW,
  payload: { updatedTableDataRow, index },
});

export const updateSelectedTableDataRow = (tableDataRow?: ITableDataRow | null): IAction => ({
  type: UPDATE_SELECTED_TABLE_DATA_ROW,
  payload: tableDataRow,
});
