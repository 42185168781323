import { useCallback, useRef } from "react";
import TableHeadData from "components/table/parts/TableHeadData";

import IIndexable from "interfaces/IIndexable";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";

interface TableHeadRowProps {
  tableDataHeadRow: string[];
  tableGroupSortHeadProperties?: string[];
  feedMixTableSizing?: IIndexable;
  userHiddenTableColumns?: number[];
  onYieldLevelHeadClick?: (header: string) => void;
  feedPlan?: ICustomerFeedPlan;
}

const TableHeadRow = ({
  tableDataHeadRow,
  tableGroupSortHeadProperties,
  feedMixTableSizing,
  userHiddenTableColumns,
  onYieldLevelHeadClick,
  feedPlan,
}: TableHeadRowProps): JSX.Element => {
  const thisTableHeadRow = useRef<HTMLTableRowElement>(null);

  const getFeedMixSizing = useCallback(
    (tableDataHeadColumn) => {
      if (tableDataHeadColumn === "nutrientName") {
        return feedMixTableSizing?.feedName;
      }
      if (feedMixTableSizing) {
        return feedMixTableSizing[tableDataHeadColumn];
      }
    },
    [feedMixTableSizing]
  );

  return (
    <tr className="table-row" ref={thisTableHeadRow}>
      {tableDataHeadRow.map((tableDataHeadColumn, index) => (
        <TableHeadData
          tableGroupSortHeadProperties={tableGroupSortHeadProperties}
          key={tableDataHeadColumn}
          tableDataHeadColumn={tableDataHeadColumn}
          feedMixTableSizing={
            feedMixTableSizing ? getFeedMixSizing(tableDataHeadColumn) : undefined
          }
          hidden={userHiddenTableColumns?.some((hiddenItem) => hiddenItem === index)}
          onYieldLevelHeadClick={onYieldLevelHeadClick}
          columnIndex={index}
          feedPlan={feedPlan}
        />
      ))}
    </tr>
  );
};

export default TableHeadRow;
