/* eslint-disable array-callback-return */

import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "react-error-boundary";

import TablesWrapper from "pages/feed-plan-calculation/parts/TablesWrapper";
import GraphsWrapper from "pages/feed-plan-calculation/parts/GraphsWrapper";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import {
  setCurrentCustomer,
  setCurrentFeedPlan,
  setCurrentFeedPlanControlSettings,
} from "pages/choose-customer/context/customerActions";

import Loader from "components/loader/Loader";

import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import IFeedDiet from "interfaces/IFeedDiet";
import IControlParameter from "interfaces/IControlParameter";
import IControlParameterResults from "interfaces/IControlParameterResults";
import IGraphChartResults from "interfaces/IGraphChartResults";
import IFeedPlanDropdownOptionsState from "interfaces/IFeedPlanDropdownOptionsState";
import IControlParameterSettingsRequest from "interfaces/IControlParameterSettingsRequest";

import useTableHeaderSorter from "hooks/useTableHeaderSorter";
import useInterpolation from "hooks/useInterpolation";
import useSaveBeforeRouteChangeFeedPlan from "hooks/useSaveBeforeRouteChangeFeedPlan";
import useConfirm from "hooks/useConfirm";
import useSaveFeedRequest from "hooks/useSaveFeedRequest";

export function getFeedPlanIdFromUrl(url: string): string | undefined {
  const feedPlanId = url.split("/").pop();

  if (feedPlanId && !Number.isNaN(parseInt(feedPlanId))) {
    return feedPlanId;
  }

  return undefined;
}

export function lowerFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

interface FeedPlanOverviewProps {
  unsavedFeedDietItems: boolean;
  setUnsavedFeedDietItems: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedControlParameterItems: boolean;
  setUnsavedControlParameterItems: React.Dispatch<React.SetStateAction<boolean>>;
  onResetChanges: () => Promise<void>;
}

const FeedPlanOverview = ({
  unsavedFeedDietItems,
  setUnsavedFeedDietItems,
  unsavedControlParameterItems,
  setUnsavedControlParameterItems,
  onResetChanges,
}: FeedPlanOverviewProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { getYieldInterpolationValues } = useInterpolation();
  const history = useHistory();
  const handleError = useErrorHandler();
  const { isConfirmed } = useConfirm();
  const { t: tWithKeyPrefix } = useTranslation("", {
    keyPrefix: "controlParameters",
  });

  const { sortPropertiesOfFeedDietItems, sortPropertiesOfControlParameterItems } =
    useTableHeaderSorter();

  const [checkedItems, setCheckedItems] = useState<IFeedPlanDropdownOptionsState[] | undefined>(
    undefined
  );

  const [checkedGraphItems, setCheckedGraphItems] = useState<
    IFeedPlanDropdownOptionsState[] | undefined
  >(undefined);

  const {
    getDefaultControlParameterSettings,
    getFeedPlanVisibleInTableOptions,
    getFeedPlanVisibleInGraphOptions,
    updateControlParameterItemInCurrentFeedPlan,
    saveUpdatedFeedDietItemsInCurrentFeedPlan,
    saveUpdatedControlParametersItemsInCurrentFeedPlan,
  } = useSaveFeedRequest();

  const {
    customerState: {
      currentCustomer,
      currentFeedPlan,
      customerDetails,
      currentFeedPlanControlSettings,
      readOnlyMode,
    },
    customerDispatch,
    getCurrentCustomerFeedPlan,
    getCustomerDetails,
  } = useContext(CustomerContext);

  const fetchCurrentFeedPlan = useCallback(
    async (customerFeedPlanId: number) => {
      try {
        await getCurrentCustomerFeedPlan(customerFeedPlanId);
      } catch (error) {
        handleError(new Error(t("fetchingError")));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCurrentCustomerFeedPlan, t]
  );

  const fetchCustomerDetails = useCallback(async () => {
    try {
      await getCustomerDetails(currentFeedPlan?.customerId as string);
    } catch (error) {
      handleError(new Error(t("fetchingError")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFeedPlan?.customerId, getCustomerDetails]);

  const onRouteChange = useCallback(async () => {
    if (currentFeedPlan) {
      if (unsavedControlParameterItems) {
        const updatedCurrentFeedPlan = await saveUpdatedControlParametersItemsInCurrentFeedPlan(
          currentFeedPlan,
          currentFeedPlanControlSettings
        );
        const updatedFeedDietItems = await saveUpdatedFeedDietItemsInCurrentFeedPlan(
          updatedCurrentFeedPlan
        );
        customerDispatch(setCurrentFeedPlan(updatedFeedDietItems));
      } else {
        const updatedFeedDietItems = await saveUpdatedFeedDietItemsInCurrentFeedPlan(
          currentFeedPlan
        );
        customerDispatch(setCurrentFeedPlan(updatedFeedDietItems));
      }

      setUnsavedFeedDietItems(false);
      setUnsavedControlParameterItems(false);
      customerDispatch(setCurrentFeedPlanControlSettings(undefined));
    }
  }, [
    currentFeedPlan,
    currentFeedPlanControlSettings,
    customerDispatch,
    saveUpdatedFeedDietItemsInCurrentFeedPlan,
    saveUpdatedControlParametersItemsInCurrentFeedPlan,
    setUnsavedControlParameterItems,
    setUnsavedFeedDietItems,
    unsavedControlParameterItems,
  ]);

  useSaveBeforeRouteChangeFeedPlan({
    when: unsavedFeedDietItems || unsavedControlParameterItems,
    callback: onRouteChange,
    resetChanges: onResetChanges,
  });

  /**
   * @description Function to fetch default Dropdown options from API.
   * These values are loaded initially when the page is loaded and called
   * every time if there are any changes to Current Feed Plan Settings.
   * @returns
   */
  const handleFetchDefaultControlParameterOptions = async () => {
    if (currentFeedPlan) {
      let defaultControlParameters: IControlParameterSettingsRequest[] = [];
      defaultControlParameters = await getDefaultControlParameterSettings(currentFeedPlan, true);

      if (currentFeedPlan && checkedItems === undefined) {
        setCheckedItems(
          getFeedPlanVisibleInTableOptions(currentFeedPlan, defaultControlParameters)
        );
      } else if (currentFeedPlan && checkedItems?.length === 0) {
        setCheckedItems(
          getFeedPlanVisibleInTableOptions(currentFeedPlan, defaultControlParameters)
        );
      }

      if (currentFeedPlan && checkedGraphItems === undefined) {
        setCheckedGraphItems(
          getFeedPlanVisibleInGraphOptions(currentFeedPlan, defaultControlParameters)
        );
      } else if (currentFeedPlan && checkedGraphItems?.length === 0) {
        setCheckedGraphItems(
          getFeedPlanVisibleInGraphOptions(currentFeedPlan, defaultControlParameters)
        );
      }
    }
  };

  useEffect(() => {
    handleFetchDefaultControlParameterOptions();
    // eslint-disable-next-line
  }, [currentFeedPlan]);

  useEffect(() => {
    const feedPlanId = getFeedPlanIdFromUrl(pathname);
    if (feedPlanId) {
      fetchCurrentFeedPlan(parseInt(feedPlanId));
    }
  }, [fetchCurrentFeedPlan, pathname]);

  useEffect(() => {
    const feedPlanId = getFeedPlanIdFromUrl(pathname);
    if (!feedPlanId && currentFeedPlan) {
      history.replace(`${pathname}/${currentFeedPlan.id}`);
    }

    if (!feedPlanId && !currentFeedPlan) {
      isConfirmed(t("mustChooseCustomerFeedPlan"), "alert");
      history.replace("/customer-overview");
    }
  }, [currentFeedPlan, history, pathname, t, isConfirmed]);

  useEffect(() => {
    if (
      (currentFeedPlan && !currentCustomer) ||
      (currentFeedPlan && currentFeedPlan.customerId !== currentCustomer?.customerId)
    ) {
      fetchCustomerDetails();
    }
  }, [currentCustomer, currentFeedPlan, fetchCustomerDetails]);

  useEffect(() => {
    if (
      (customerDetails && !currentCustomer) ||
      (customerDetails && customerDetails?.customer?.customerId !== currentCustomer?.customerId)
    ) {
      customerDispatch(setCurrentCustomer(customerDetails.customer));
    }
  }, [currentCustomer, customerDetails, customerDispatch]);

  /**
   * @description Function to create Feed Diet table data.
   * These values rely on each other therefore we need to update the table
   * every time one the amount or percentage input changes.
   * @param feedPlan - Which is the Feed Plan data
   * @returns - feedDietData Array
   */
  const createFeedDietTableData = (feedPlan: ICustomerFeedPlan) => {
    let feedDietTableData: IFeedDiet[] = feedPlan.feeds.map((feed) => {
      const feedDiet: IFeedDiet = {} as IFeedDiet;

      const matchingFeedDietItem = feedPlan.feedDietItems.find(
        (feedDietItem) => feedDietItem.customerFeedId === feed.id
      );

      feedDiet.isOptimized = matchingFeedDietItem?.isOptimized as boolean;
      feedDiet.feedName = feed.feedName as string;
      feedDiet.customerFeedId = matchingFeedDietItem?.customerFeedId;
      feedDiet.sortIndex = matchingFeedDietItem?.sortIndex;

      if (feedPlan.animalType.name !== "milkCow") {
        feedPlan.yieldLevels.forEach((value) => {
          feedDiet[value.description] = matchingFeedDietItem?.yieldLevelValues[`${value.level}.00`];
        });
      } else if (matchingFeedDietItem?.yieldLevelValues) {
        Object.keys(matchingFeedDietItem.yieldLevelValues).forEach((key) => {
          feedDiet[key] = matchingFeedDietItem.yieldLevelValues[key];
        });
      }

      return feedDiet;
    });

    if (feedDietTableData.length > 0) {
      return feedDietTableData.sort((a, b) => {
        if (a.sortIndex! > b.sortIndex!) return 1;
        if (a.sortIndex! < b.sortIndex!) return -1;
        return 0;
      });
    }

    feedDietTableData = [] as IFeedDiet[];
    const defaultFeedDiet: IFeedDiet = {} as IFeedDiet;

    const yieldValues = getYieldInterpolationValues(
      feedPlan.yieldLevelStart,
      feedPlan.yieldLevelStep,
      feedPlan.yieldLevelStop
    );

    defaultFeedDiet.isOptimized = false;
    defaultFeedDiet.feedName = "";
    defaultFeedDiet.customerFeedId = 0;
    if (feedPlan.animalType.name !== "milkCow") {
      feedPlan.yieldLevels.forEach((value) => {
        defaultFeedDiet[`#${value.description}`] = "";
      });
    } else {
      yieldValues.forEach((value) => {
        defaultFeedDiet[`#${value}`] = "";
      });
    }
    feedDietTableData.push(defaultFeedDiet);

    return feedDietTableData.sort((a, b) => {
      if (a.sortIndex! > b.sortIndex!) return 1;
      if (a.sortIndex! < b.sortIndex!) return -1;
      return 0;
    });
  };

  const getCheckedOptions = (items: IFeedPlanDropdownOptionsState[], nutrient: IControlParameter) =>
    items.some((item) => {
      if (item.value === nutrient.name && item.visible === true) {
        return true;
      }
    });

  /**
   * @description Function to create Control Parameter table data.
   * These values rely on each other therefore we need to update the table
   * every time one of the amount or percentage input changes.
   * @param feedPlan - Which is the Feed Plan data
   * @returns - Control Parameter Data Array
   */
  const createControlParameterTableData = (feedPlan: ICustomerFeedPlan) => {
    const controlParameterTableDataFiltered: IControlParameter[] =
      feedPlan.controlParameters.filter((nutrient: IControlParameter) =>
        checkedItems ? getCheckedOptions(checkedItems, nutrient) : false
      );

    const controlParameterTableData: IControlParameterResults[] =
      controlParameterTableDataFiltered.map((nutrient: IControlParameter) => {
        const nutrientData: IControlParameterResults = {} as IControlParameterResults;
        nutrientData.nutrientName = tWithKeyPrefix(lowerFirstLetter(nutrient.name)) as string;

        Object.keys(nutrient.controlParameterBoundaries.boundaries).forEach((key, index) => {
          const NUTRIENT_KEY_VALUE_FIXED = 4;

          let nutrientValue =
            nutrient.controlParameterBoundaries.boundaries[key].value?.toFixed(
              NUTRIENT_KEY_VALUE_FIXED
            );

          let nutrientMax =
            nutrient.controlParameterBoundaries.boundaries[key].max?.toFixed(
              NUTRIENT_KEY_VALUE_FIXED
            );

          let nutrientMin =
            nutrient.controlParameterBoundaries.boundaries[key].min?.toFixed(
              NUTRIENT_KEY_VALUE_FIXED
            );

          nutrientValue = parseFloat(nutrientValue);
          nutrientMax = parseFloat(nutrientMax);
          nutrientMin = parseFloat(nutrientMin);

          if (feedPlan.animalType.name !== "milkCow") {
            key = feedPlan.yieldLevels[index].description;
          }

          if (nutrientValue < nutrientMin) {
            nutrientData[`#${key}`] = `#min#${nutrientValue}`;
          } else if (nutrientValue > nutrientMax) {
            nutrientData[`#${key}`] = `#max#${nutrientValue}`;
          } else {
            nutrientData[`#${key}`] = `${nutrientValue}`;
          }
        });

        return nutrientData;
      });

    if (controlParameterTableData.length === 0) {
      // Load dummy data
      const dummyNutrientData: IControlParameterResults = {} as IControlParameterResults;
      dummyNutrientData.nutrientName = "";
      
      const yieldValues = getYieldInterpolationValues(
        feedPlan.yieldLevelStart,
        feedPlan.yieldLevelStep,
        feedPlan.yieldLevelStop
      );

      if (feedPlan.animalType.name !== "milkCow") {
        feedPlan.yieldLevels.forEach((value) => {
          dummyNutrientData[`#${value.description}`] = "";
        });
      } else {
        yieldValues.forEach((value) => {
          dummyNutrientData[`#${value}`] = 0;
        });
      }
      controlParameterTableData.push(dummyNutrientData);
    }

    return controlParameterTableData;
  };

  /**
   * @description Function to create Graph Chart data.
   * These values rely on Feed Diet and Control Parameters therefore we need to update the graph
   * every time one of the amount or percentage input changes.
   * @param feedPlan - Which is the Feed Plan data
   * @returns - Graph Data Object
   */
  const createGraphChartData = (feedPlan: ICustomerFeedPlan) => {
    const graphChartDataFiltered: IControlParameter[] = feedPlan.controlParameters.filter(
      (nutrient: IControlParameter) =>
        checkedGraphItems ? getCheckedOptions(checkedGraphItems, nutrient) : false
    );

    return graphChartDataFiltered.map((nutrient: IControlParameter) => {
      const graphData: IGraphChartResults = {} as IGraphChartResults;
      graphData.nutrientName = tWithKeyPrefix(lowerFirstLetter(nutrient.name)) as string;

      Object.keys(nutrient.controlParameterBoundaries.boundaries).forEach((key) => {
        graphData[key] = {
          min: nutrient.controlParameterBoundaries.boundaries[key].min,
          max: nutrient.controlParameterBoundaries.boundaries[key].max,
          value: nutrient.controlParameterBoundaries.boundaries[key].value.toFixed(2),
        };
      });

      return graphData;
    });
  };

  /**
   * @description Function to handle any change on Dropdown options.
   * These values rely on dropdown checkboxes therefore we need to update the state
   * every time one of the checkbox input changes.
   * @param updatedState - Consist of the updated dropdown selections
   * @param operation - Whether Control Parameter or Graph Dropdown is Changed
   * @param updatedGraphViaTableState - Uses Latest Graph dropdown selections state
   * @returns
   */
  const handleOnUpdatedControlParameterItem = useCallback(
    async (
      updatedState: IFeedPlanDropdownOptionsState[] | undefined,
      operation: string,
      updatedGraphViaTableState: IFeedPlanDropdownOptionsState[] | undefined = []
    ) => {
      if (!currentFeedPlan) {
        return;
      }

      let defaultControlParameters: IControlParameterSettingsRequest[] = [];

      if (currentFeedPlanControlSettings === undefined) {
        defaultControlParameters = await getDefaultControlParameterSettings(currentFeedPlan);
      } else {
        defaultControlParameters = currentFeedPlanControlSettings;
      }

      const updatedControlParameterItems = await updateControlParameterItemInCurrentFeedPlan(
        updatedState,
        defaultControlParameters,
        operation,
        updatedGraphViaTableState
      );

      if (!unsavedControlParameterItems) {
        setUnsavedControlParameterItems(true);
      }

      customerDispatch(setCurrentFeedPlanControlSettings(updatedControlParameterItems));
    },
    [
      currentFeedPlan,
      currentFeedPlanControlSettings,
      customerDispatch,
      getDefaultControlParameterSettings,
      unsavedControlParameterItems,
      setUnsavedControlParameterItems,
      updateControlParameterItemInCurrentFeedPlan,
    ]
  );

  /**
   * @description Function to handle any change on Graph Dropdown options.
   * These values rely on graph dropdown checkboxes therefore we need to update the state
   * every time one of the checkbox input changes.
   * @param updateCheckedState - Consist of the updated dropdown selections
   * @param operation - Whether Control Parameter or Graph Dropdown is Changed
   * @returns
   */
  const updateGraphDropdownState = (
    updateCheckedState: IFeedPlanDropdownOptionsState[] | undefined,
    operation: string
  ) => {
    if (operation === "controlParameterSelection") {
      // Loop the current graph options and disable/enable according to control parameters
      if (checkedGraphItems && checkedItems) {
        const updateGraphOptions: IFeedPlanDropdownOptionsState[] = checkedGraphItems;

        updateGraphOptions.forEach((item, index) => {
          if (checkedItems[index].visible === true) {
            item.disable = checkedItems[index].disable;
          } else if (checkedItems[index].visible === false) {
            item.visible = false;
            item.disable = true;
          }
        });

        setCheckedGraphItems(updateGraphOptions);
        handleOnUpdatedControlParameterItem(
          updateCheckedState,
          "controlParameterSetting",
          updateGraphOptions
        );
      }
    } else {
      setCheckedGraphItems(updateCheckedState);
      handleOnUpdatedControlParameterItem(updateCheckedState, "graphParameterSetting");
    }
  };

  /**
   * @description Function to handle any change on Control Parameter Dropdown options.
   * These values rely on control dropdown checkboxes therefore we need to update the state
   * every time one of the checkbox input changes.
   * @param updateCheckedState - Consist of the updated dropdown selections
   * @returns
   */
  const updateTableDropdownState = (
    updateCheckedState: IFeedPlanDropdownOptionsState[] | undefined
  ) => {
    setCheckedItems(updateCheckedState);
    updateGraphDropdownState(updateCheckedState, "controlParameterSelection");
  };

  return (
    <div className="feed-plan-overview">
      <div className="sub-header">
        <h3 className="feed-plan-name">
          {currentFeedPlan ? currentFeedPlan.name : t("noFeedPlanChosen")}
        </h3>
        <h3 className="customer-name">
          {!currentCustomer && ""}
          {currentCustomer?.name && currentCustomer.name}
          {!currentCustomer?.name && currentCustomer?.fullName1}
        </h3>
      </div>
      <div className="wrapper">
        {currentFeedPlan && currentFeedPlan.id ? (
          <TablesWrapper
            emptyData={currentFeedPlan.feedDietItems.length ? undefined : true}
            feedDietData={sortPropertiesOfFeedDietItems(createFeedDietTableData(currentFeedPlan))}
            controlParameterData={sortPropertiesOfControlParameterItems(
              createControlParameterTableData(currentFeedPlan)
            )}
            feedPlan={currentFeedPlan}
            tableDropdownState={checkedItems}
            updateTableDropdownState={updateTableDropdownState}
            setUnsavedFeedDietItems={setUnsavedFeedDietItems}
            unsavedFeedDietItems={unsavedFeedDietItems}
            enableEdit={!readOnlyMode}
          />
        ) : (
          <div>
            <Loader size="small" />
            <span className="margin-left-15">{t("loading")}</span>
          </div>
        )}
        {currentFeedPlan && currentFeedPlan.id ? (
          <GraphsWrapper
            graphChartData={createGraphChartData(currentFeedPlan)}
            graphDropdownState={checkedGraphItems}
            feedPlan={currentFeedPlan}
            updateGraphDropdownState={updateGraphDropdownState}
            enableEdit={!readOnlyMode}
          />
        ) : (
          <div className="margin-left-15">
            <Loader size="small" />
            <span className="margin-left-15">{t("loading")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedPlanOverview;
