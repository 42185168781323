import { Feed } from "types";
import IFeedsState from "../../interfaces/IFeedsState";

/**
 * @description When a new row is added to standardFeedsState.standardFeeds,
 * that row is also added to standardFeedsState.standardFeedsToAdd
 * allowing for easy access when all the newly rows are going to be sent to the backend.
 * @param {Object} standardFeedsState
 * @param {Object} feedToBeSaved
 * @returns The new state with the updated standardFeedsToAdd
 */
export const addNewOrCopiedFeedToFeedsToAdd = <S extends IFeedsState<Feed>>(
  state: S,
  feedToBeSaved: Feed
): S => {
  const feedGroupFeedNumberCombo = `${feedToBeSaved.feedGroup}${feedToBeSaved.feedNumber}`;
  const feedsToAddTemp = [...state.feedsToAdd];
  let feedsToDeleteTemp = [...state.feedsToDelete];

  const feedsToDeleteFeedGroupsAndFeedNumbersCombo = feedsToDeleteTemp.map(
    (feed) => `${feed.feedGroup}${feed.feedNumber}`
  );

  if (feedsToDeleteFeedGroupsAndFeedNumbersCombo.includes(feedGroupFeedNumberCombo)) {
    feedsToDeleteTemp = feedsToDeleteTemp.filter(
      (feed) => `${feed.feedGroup}${feed.feedNumber}` !== feedGroupFeedNumberCombo
    );
  } else {
    feedsToAddTemp.push(feedToBeSaved);
  }

  return { ...state, feedsToAdd: feedsToAddTemp, feedsToDelete: feedsToDeleteTemp };
};

/**
 * @description Adds a feed to the feedsToDelete array in the state.
 * @param {Object} state
 * @param {Object} feedToBeRemoved
 * @returns {Object} state with the feedToBeRemoved added to the feedsToDelete array
 */
export const addFeedToFeedsToDelete = <S extends IFeedsState<Feed>>(
  state: S,
  feedToBeRemoved: Feed
) => {
  const feedGroupFeedNumberCombo = `${feedToBeRemoved.feedGroup}${feedToBeRemoved.feedNumber}`;
  const feedsToDeleteTemp = [...state.feedsToDelete];
  let feedsToAddTemp = [...state.feedsToAdd];

  const feedsToAddFeedGroupsAndFeedNumbersCombo = feedsToAddTemp.map(
    (feed) => `${feed.feedGroup}${feed.feedNumber}`
  );

  if (feedsToAddFeedGroupsAndFeedNumbersCombo.includes(feedGroupFeedNumberCombo)) {
    feedsToAddTemp = feedsToAddTemp.filter(
      (feed) => `${feed.feedGroup}${feed.feedNumber}` !== feedGroupFeedNumberCombo
    );
  } else {
    feedsToDeleteTemp.push(feedToBeRemoved);
  }

  return { ...state, feedsToAdd: feedsToAddTemp, feedsToDelete: feedsToDeleteTemp };
};

/**
 * @description Finds the highest Id in the feeds array and return that plus one
 * @param {Object} state
 * @returns {number} New id based on the highest current id in the standardFeeds array.
 */
export const getNewRowId = <S extends IFeedsState<Feed>>(state: S): number => {
  if (state.feeds.length === 0) {
    return 1;
  }

  const arrayWithJustIds = state.feeds.map((feed) => feed.id);
  const highestId = arrayWithJustIds.reduce((currentHighest, currentId) =>
    currentId! > currentHighest! ? currentId : currentHighest
  );

  return highestId! + 1;
};
