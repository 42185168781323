import { useState } from "react";

function useModal() {
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleAlertModal, setToggleAlertModal] = useState(false);

  function toggleState() {
    setToggleModal(!toggleModal);
  }

  function toggleAlertState() {
    setToggleAlertModal(!toggleAlertModal);
  }

  return { toggleModal, toggleState, toggleAlertModal, toggleAlertState };
}

export default useModal;
