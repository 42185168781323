import IAction from "interfaces/IAction";
import ICustomerFeed from "interfaces/ICustomerFeed";
import IFeedsState from "interfaces/IFeedsState";

// Action types
export const ADD_CUSTOMER_FEED_TO_CUSTOMER_FEEDS_TO_UPDATE =
  "ADD_CUSTOMER_FEED_TO_CUSTOMER_FEEDS_TO_UPDATE";
export const ADD_NEW_CUSTOMER_FEED = "ADD_NEW_CUSTOMER_FEED";
export const CLEAR_CUSTOMER_FEEDS = "CLEAR_CUSTOMER_FEEDS";
export const COPY_SELECTED_CUSTOMER_FEED = "COPY_SELECTED_CUSTOMER_FEED";
export const EDIT_CUSTOMER_FEEDS_TO_ADD = "EDIT_CUSTOMER_FEEDS_TO_ADD";
export const REMOVE_CUSTOMER_FEED = "REMOVE_CUSTOMER_FEED";
export const REMOVE_SELECTED_CUSTOMER_FEED = "REMOVE_SELECTED_CUSTOMER_FEED";
export const SET_CUSTOMER_FEEDS = "SET_CUSTOMER_FEEDS";
export const UPDATE_CUSTOMER_FEEDS = "UPDATE_CUSTOMER_FEEDS";
export const UPDATE_CUSTOMER_FEEDS_AFTER_CALCULATION = "UPDATE_CUSTOMER_FEEDS_AFTER_CALCULATION";
export const UPDATE_SELECTED_CUSTOMER_FEED = "UPDATE_SELECTED_CUSTOMER_FEED";

export const addCustomerFeedToCustomerFeedsToUpdate = (
  newState: IFeedsState<ICustomerFeed>
): IAction => ({ type: ADD_CUSTOMER_FEED_TO_CUSTOMER_FEEDS_TO_UPDATE, payload: newState });

export const addNewCustomerFeed = (newState: IFeedsState<ICustomerFeed>): IAction => ({
  type: ADD_NEW_CUSTOMER_FEED,
  payload: newState,
});

export const removeCustomerFeed = (newState: IFeedsState<ICustomerFeed>): IAction => ({
  type: REMOVE_CUSTOMER_FEED,
  payload: newState,
});

export const removeSelectedCustomerFeed = (newState: IFeedsState<ICustomerFeed>): IAction => ({
  type: REMOVE_SELECTED_CUSTOMER_FEED,
  payload: newState,
});

export const clearCustomerFeeds = (newState: IFeedsState<ICustomerFeed>): IAction => ({
  type: CLEAR_CUSTOMER_FEEDS,
  payload: newState,
});

export const editCustomerFeedsToAdd = (newState: IFeedsState<ICustomerFeed>): IAction => ({
  type: EDIT_CUSTOMER_FEEDS_TO_ADD,
  payload: newState,
});

export const copySelectedCustomerFeed = (newState: IFeedsState<ICustomerFeed>): IAction => ({
  type: COPY_SELECTED_CUSTOMER_FEED,
  payload: newState,
});

export const setCustomerFeeds = (newState: IFeedsState<ICustomerFeed>): IAction => ({
  type: SET_CUSTOMER_FEEDS,
  payload: newState,
});

export const updateCustomerFeeds = (feeds: ICustomerFeed[]): IAction => ({
  type: UPDATE_CUSTOMER_FEEDS,
  payload: feeds,
});

export const updateCustomerFeedsAfterCalculation = (
  feed: ICustomerFeed,
  sortOrder: any
): IAction => ({
  type: UPDATE_CUSTOMER_FEEDS_AFTER_CALCULATION,
  payload: { feed, sortOrder },
});

export const updateSelectedCustomerFeed = (feed?: ICustomerFeed): IAction => ({
  type: UPDATE_SELECTED_CUSTOMER_FEED,
  payload: feed,
});
