import { useCallback, useMemo, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";

import navLinks from "components/navbar/json/navLinks.json";

interface Link {
  text: string;
  path: string;
}

const Navbar = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    customerState: { currentCustomer, currentFeedPlan, currentFeedMix },
  } = useContext(CustomerContext);
  const history = useHistory();
  const location = useLocation();
  const links: Link[] = navLinks;

  const handleOnClick = useCallback(
    (link: Link) => {
      // Detect if a user has entered a value in url before /#/ - if they have we remove it

      if (
        location.pathname === link.path ||
        location.pathname === `${link.path}/${currentFeedPlan?.id}` ||
        location.pathname === `${link.path}/${currentFeedMix?.id}` ||
        location.pathname === `${link.path}/${currentFeedMix?.id}/add-remove`
      ) {
        return;
      }
      // eslint-disable-next-line
      return history.push(link.path);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, location.pathname, currentFeedMix]
  );

  const renderNavLinks = useMemo(() => {
    let navLinkClasses: string[] = ["nav-link", "tab-navigation__tab", "js-tab"];

    return links.map((link) => {
      // eslint-disable-next-line
      location.pathname === link.path
        ? navLinkClasses.push("is-active")
        : (navLinkClasses = navLinkClasses.filter((c) => c !== "is-active"));

      if (
        location.pathname === `${link.path}/${currentCustomer?.customerId}` ||
        location.pathname === `${link.path}/${currentFeedPlan?.id}/settings` ||
        location.pathname === `${link.path}/${currentFeedPlan?.id}/add-remove` ||
        location.pathname === `${link.path}/${currentFeedPlan?.id}` ||
        location.pathname === `${link.path}/create-feed-plan`
      ) {
        navLinkClasses.push("is-active");
      }

      return (
        // eslint-disable-next-line
        <span
          className={navLinkClasses.join(" ")}
          key={link.text}
          onClick={() => handleOnClick(link)}
        >
          {t(link.text)}
        </span>
      );
    });
  }, [
    currentCustomer?.customerId,
    currentFeedPlan?.id,
    handleOnClick,
    links,
    location.pathname,
    t,
  ]);

  return <nav className="navbar tab-navigation__tab-container">{renderNavLinks}</nav>;
};

export default Navbar;
