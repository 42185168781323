import IAction from "interfaces/IAction";
import ITableDataRow from "interfaces/ITableDataRow";
import ITableDataState from "interfaces/ITableDataState";
import _ from "lodash";

import {
  CLEAR_SELECTED_TABLE_DATA_ROW,
  SET_TABLE_DATA,
  SET_TABLE_DATA_KEY_PREFIX,
  SORT_TABLE_DATA_COLUMN,
  UPDATE_TABLE_DATA_ROW,
  UPDATE_SELECTED_TABLE_DATA_ROW,
} from "./tableDataActions";

// ########## HELPER FUNCTIONS BELOW ##########

function clearSelectedTabledDataRow(
  tableDataState: ITableDataState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: IAction
): ITableDataState {
  return { ...tableDataState, selectedTableDataRow: undefined };
}

function setTableData(tableDataState: ITableDataState, action: IAction): ITableDataState {
  return { ...tableDataState, tableData: action.payload };
}

function setTableDataKeyPrefix(tableDataState: ITableDataState, action: IAction): ITableDataState {
  return { ...tableDataState, tableDataKeyPrefix: action.payload };
}

function sortTableDataColumn(tableDataState: ITableDataState, action: IAction): ITableDataState {
  const tableDataTemp = [...tableDataState.tableData];
  const { key, asc, groupSort } = action.payload;

  if (groupSort) {
    const checkKeyExistForGroupSort: boolean = groupSort.some((column: string) => column === key);
    if (checkKeyExistForGroupSort) {
      const sortOrder: any = asc === true ? "asc" : "desc";
      const groupSortedTableData = _.orderBy(tableDataTemp, groupSort, [sortOrder, sortOrder]);
      return {
        ...tableDataState,
        tableData: groupSortedTableData,
      };
    }
  }

  if (asc) {
    tableDataTemp.sort((a, b): number => {
      if (typeof a[key] === "number") {
        return a[key] - b[key];
      }
      if (a[key] > b[key]) {
        return 1;
      }
      if (a[key] < b[key]) {
        return -1;
      }
      return 0;
    });
  } else {
    tableDataTemp.sort((a, b): number => {
      if (typeof a[key] === "number") {
        return b[key] - a[key];
      }
      if (b[key] > a[key]) {
        return 1;
      }
      if (b[key] < a[key]) {
        return -1;
      }
      return 0;
    });
  }

  return {
    ...tableDataState,
    tableData: tableDataTemp,
  };
}

function updateSelectedTableDataRow(
  tableDataState: ITableDataState,
  action: IAction
): ITableDataState {
  if (action.payload) {
    return { ...tableDataState, selectedTableDataRow: action.payload };
  }
  return { ...tableDataState, selectedTableDataRow: undefined };
}

// ########## Helper functions ##########

/**
 *
 * @param tableDataState
 * @param updatedTableDataRow
 * @returns The new state with the updated lastUpdatedTableDataRow.
 */
function updateLastEditedTableDataRow(
  tableDataState: ITableDataState,
  updatedTableDataRow: ITableDataRow
): ITableDataState {
  return { ...tableDataState, lastUpdatedTableDataRow: updatedTableDataRow };
}

function updateTableDataRow(tableDataState: ITableDataState, action: IAction): ITableDataState {
  const tableDataTemp = [...tableDataState.tableData];
  const { updatedTableDataRow, index } = action.payload;

  // Update the lastUpdatedTableDataRow on the tableDataState with the updatedTableDataRow
  const UpdTableDataState = updateLastEditedTableDataRow(tableDataState, updatedTableDataRow);

  tableDataTemp.splice(index, 1, updatedTableDataRow);

  return { ...UpdTableDataState, tableData: tableDataTemp };
}

function tableDataReducer(tableDataState: ITableDataState, action: IAction): ITableDataState {
  const args: [ITableDataState, IAction] = [tableDataState, action];

  switch (action.type) {
    case CLEAR_SELECTED_TABLE_DATA_ROW:
      return clearSelectedTabledDataRow(...args);
    case SET_TABLE_DATA:
      return setTableData(...args);
    case SET_TABLE_DATA_KEY_PREFIX:
      return setTableDataKeyPrefix(...args);
    case SORT_TABLE_DATA_COLUMN:
      return sortTableDataColumn(...args);
    case UPDATE_TABLE_DATA_ROW:
      return updateTableDataRow(...args);
    case UPDATE_SELECTED_TABLE_DATA_ROW:
      return updateSelectedTableDataRow(...args);
    default:
      return tableDataState;
  }
}

export default tableDataReducer;
