import { useContext, useCallback } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useSaveBeforeRouteChange from "hooks/useSaveBeforeRouteChange";

import useTableHeaderSorter from "hooks/useTableHeaderSorter";
import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useConfirm from "hooks/useConfirm";
import useToggle from "hooks/useToggle";

import typeSpecsOfFeedMix from "pages/feed-mix/json/typeSpecsOfFeedMix.json";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { setCurrentFeedMix } from "pages/choose-customer/context/customerActions";

import LMButton from "components/lm-button/LMButton";
import Table from "components/table/Table";
import Loader from "components/loader/Loader";

import IFeedMix from "interfaces/IFeedMix";
import ICustomerFeed from "interfaces/ICustomerFeed";
import { IFeedMixItem } from "interfaces/IFeedMixUpdateRequest";

import CustomerFeedMixTable from "./CustomerFeedMixTable";

interface FeedMixOverviewProps {
  toggleUnsavedFeedMix: (val?: boolean) => void;
  unsavedFeedMix: boolean;
  onResetChanges: () => Promise<void>;
}

/**
 * @description Displays & handles data for tables of the current selected Feed mix.
 * @returns Tables of data from current selected Feed mix
 * or list of Feed mixes from current selected customer.
 */
const FeedMixOverview = ({
  toggleUnsavedFeedMix,
  unsavedFeedMix,
  onResetChanges,
}: FeedMixOverviewProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { isConfirmed } = useConfirm();
  const [isLoading, toggleIsLoading] = useToggle(false);
  const { feedNumber } = useParams<{ feedNumber: string }>();
  const {
    customerState: { currentCustomer, currentFeedMix, readOnlyMode, userHiddenTableColumns },
    customerDispatch,
  } = useContext(CustomerContext);

  const { updateFeedMixItemsInDatabase } = useSaveFeedRequest();
  const { sortPropertiesOfFeedMix } = useTableHeaderSorter();

  const handleOnClickAddRemove = useCallback(() => {
    history.push(`${url}/add-remove`);
  }, [history, url]);

  /**
   * @description Triggers function updateFeedMixItemsInDatabase that
   * sends Feed mix calculation request to backend.
   * Stores that response as current Feed mix in state.
   * The changed Feed mix is not saved in database.
   */
  const handleOnClickCalc = useCallback(async () => {
    if (!currentFeedMix || !currentFeedMix.feedMixItems) {
      return;
    }

    if (currentFeedMix?.shareInPercKg && currentFeedMix.shareInPercKg !== 100) {
      return isConfirmed(t("feedMixAmountError"), "alert");
    }

    toggleIsLoading(true);
    const res = await updateFeedMixItemsInDatabase(
      {
        feedMixItems: currentFeedMix?.feedMixItems,
      },
      currentFeedMix?.id,
      false
    );

    customerDispatch(setCurrentFeedMix({ ...res, ...res?.summary }));
    toggleUnsavedFeedMix(true);
    toggleIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFeedMix]);

  /**
   * @description Callback function for useSaveBeforeRouteChange hook.
   * Saves Feed mix values to database.
   */
  const onRouteChange = async () => {
    if (currentFeedMix && currentFeedMix.feedMixItems) {
      const res = await updateFeedMixItemsInDatabase(
        {
          feedMixItems: currentFeedMix.feedMixItems,
        },
        currentFeedMix.id,
        true
      );

      customerDispatch(setCurrentFeedMix({ ...res, ...res?.summary }));
      toggleUnsavedFeedMix(false);
    }
  };

  /**
   * @description Hook that runs everytime user tries to change tab with unsaved Feed mix.
   * Prompts user to save before leaving page.
   * Runs onRouteChange if user chooses to confirm prompt.
   */
  useSaveBeforeRouteChange({
    when: unsavedFeedMix,
    callback: onRouteChange,
    resetChanges: onResetChanges,
  });

  /**
   * @description Maps feedMixItems from current selected Feed mix
   * & finds Customer Feed for each item. Returns data as IFeedMix and sorts
   * array based on sortIndex.
   */
  const parseFeedMixItems = useCallback(() => {
    const feedMixItems: IFeedMixItem[] | undefined = currentFeedMix?.feedMixItems;
    const feeds: ICustomerFeed[] | undefined = currentFeedMix?.feeds;

    if (feedMixItems?.length && feeds?.length) {
      return sortPropertiesOfFeedMix(
        feedMixItems.map((feedMixItem: IFeedMixItem): IFeedMix => {
          const feed: ICustomerFeed | undefined = feeds.find(
            (x: ICustomerFeed) => x.id === feedMixItem.customerFeedId
          );
          return { ...feedMixItem, ...feed, sortIndex: feedMixItem.sortIndex } as IFeedMix;
        })
      ).sort((a, b) => {
        if (a?.sortIndex! > b?.sortIndex!) {
          return 1;
        }
        if (a?.sortIndex! < b?.sortIndex!) {
          return -1;
        }
        return 0;
      });
    }
    return [{} as IFeedMix];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFeedMix]);

  const renderTableData = useCallback(() => {
    if (!currentFeedMix) {
      return [{} as IFeedMix];
    }
    return sortPropertiesOfFeedMix([currentFeedMix]);
  }, [currentFeedMix, sortPropertiesOfFeedMix]);

  const currentCustomerName =
    currentCustomer && currentCustomer.name ? currentCustomer.name : currentCustomer?.fullName1;

  const readOnlyClass = readOnlyMode ? "read-only" : "";

  const isEmptyTableData = currentFeedMix ? undefined : true;
  const isEmptyTableDataItems =
    currentFeedMix?.feeds && currentFeedMix?.feeds.length ? undefined : true;

  const feedMixHiddenColumns = userHiddenTableColumns
    ? userHiddenTableColumns.feedMixColumns
    : undefined;

  return currentFeedMix && feedNumber ? (
    <>
      <div className="sub-header">
        <h3 className="customer-name">{currentCustomerName}</h3>

        {isLoading && (
          <div className="margin-left-15">
            <Loader size="small" />
            <span className="margin-left-15">{t("loading")}</span>
          </div>
        )}

        <div className="button-wrapper">
          <LMButton
            click={handleOnClickCalc}
            text={t("calculateValues")}
            type="button"
            disabled={!feedNumber || readOnlyMode || !unsavedFeedMix}
          />
          <LMButton
            click={handleOnClickAddRemove}
            text={t("addRemoveFeeds")}
            type="button"
            disabled={!feedNumber || readOnlyMode}
          />
        </div>
      </div>
      <div className={`${readOnlyClass}`}>
        <h2 className="mix-table-title">{t("mix")}</h2>
        <Table
          data={renderTableData()}
          emptyTableData={isEmptyTableData}
          keyPrefix="feedParameters"
          typeSpecs={typeSpecsOfFeedMix}
          readOnly
          feedMixTableSizing={[...parseFeedMixItems(), currentFeedMix]}
          userHiddenTableColumns={feedMixHiddenColumns}
        />
      </div>
      <div className={`${readOnlyClass}`}>
        <h2 className="mix-table-title">{t("feedsInMix")}</h2>
        <Table
          data={parseFeedMixItems()}
          emptyTableData={isEmptyTableDataItems}
          keyPrefix="feedParameters"
          typeSpecs={typeSpecsOfFeedMix}
          feedMixTableSizing={[...parseFeedMixItems(), currentFeedMix]}
          toggleUnsavedFeedMix={toggleUnsavedFeedMix}
          userHiddenTableColumns={feedMixHiddenColumns}
          unsavedItems={unsavedFeedMix}
        />
      </div>
    </>
  ) : (
    <CustomerFeedMixTable />
  );
};

export default FeedMixOverview;
