import { useCallback } from "react";

import LMButton from "components/lm-button/LMButton";

interface ISwitchButtonsProps {
  disableRight: boolean;
  disableLeft: boolean;
  handleOnRightButtonClick: () => void;
  handleOnLeftButtonClick: () => void;
}

type TButtonClicked = "RIGHT" | "LEFT";

const SwitchButtons = ({
  disableRight,
  disableLeft,
  handleOnRightButtonClick,
  handleOnLeftButtonClick,
}: ISwitchButtonsProps) => {
  const handleOnClick = useCallback(
    (clickedButton: TButtonClicked) => {
      if (clickedButton === "RIGHT") {
        handleOnRightButtonClick();
      }

      if (clickedButton === "LEFT") {
        handleOnLeftButtonClick();
      }
    },
    [handleOnLeftButtonClick, handleOnRightButtonClick]
  );

  return (
    <div className="switch-buttons">
      <div className="right">
        <LMButton
          className="lm__blue-btn lm__small-btn lm__base-icon-btn add-custom-feed material-icons"
          type="button"
          text="arrow_right"
          disabled={disableRight}
          click={() => handleOnClick("RIGHT")}
        />
      </div>
      <div className="left margin-top-5">
        <LMButton
          className="lm__blue-btn lm__small-btn lm__base-icon-btn add-custom-feed material-icons"
          type="button"
          text="arrow_left"
          disabled={disableLeft}
          click={() => handleOnClick("LEFT")}
        />
      </div>
    </div>
  );
};

export default SwitchButtons;
