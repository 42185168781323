interface IUseInterpolationReturnValue {
  getYieldInterpolationValues: (start: number, step: number, stop: number) => string[];
}

function useInterpolation(): IUseInterpolationReturnValue {
  const getYieldInterpolationValues = (start: number, step: number, stop: number): string[] => {
    const interpolationValues: string[] = [];
    let nextValue = start;

    // The push value here is in a weird format, I have to do this in order
    // to control the order of the properties in the object that will be used
    // later when creating the feedDietTableData. Numbers
    // (or rather number-like-keys) are always placed first in an object
    // and I don't want that, hence the hash-tag injected in the number.
    // These hash-tags will be removed later before rendering the value in the
    // TableHeadData-component.

    const parseValue = (value: number) => value.toString().split("").join("#");

    if (step === 0) {
      interpolationValues.push(parseValue(start));

      if (start !== stop) {
        interpolationValues.push(parseValue(stop));
      }

      return interpolationValues;
    }

    while (nextValue <= stop) {
      interpolationValues.push(parseValue(nextValue));
      nextValue += step;
    }

    if (Number(interpolationValues[interpolationValues.length - 1]) !== stop) {
      interpolationValues.push(parseValue(stop));
    }

    return interpolationValues;
  };

  return { getYieldInterpolationValues };
}

export default useInterpolation;
