import ICustomerFeed from "interfaces/ICustomerFeed";
import ICustomerFeedPlan, { IFeedDietItem } from "interfaces/ICustomerFeedPlan";
import ITableDataRow from "interfaces/ITableDataRow";
import {
  convertJsonArrayToObject,
  convertObjectToJsonArray,
  adjustForEnergyMobilization,
  adjustForProteinMobilization,
  getNeededAmountOfFeedAByNe,
  getNeededAmoutOfFeedAByMp,
} from "./helpers/useFeedDietOptimizationHelper";

function useFeedDietOptimization() {
  
  /**
   * @descrtiption Function to calculate Fill in the Values in the Feed Diet Feed Yield Amounts
   * @param startValue - Which consists start Value of Yield Level
   * @param stopValue - Which consists end Value of Yield Level
   * @param interpolationArray - Which consists data of customer Feed Yield Amounts
   * @returns - interpolationArray
   */
  const calculateLinearInterpolationOnArray = (
    startValue: number,
    stopValue: number,
    interpolationArray: any
  ) => {
    const length: number = interpolationArray.length - 1;
    if (stopValue > startValue) {
      const step = (stopValue - startValue) / length;
      for (let i = 0; i < length + 1; i++) {
        interpolationArray[i][1] = parseFloat((startValue + step * i).toFixed(2));
      }
    } else if (startValue > stopValue) {
      const step = (startValue - stopValue) / length;
      for (let i = length; i >= 0; i--) {
        interpolationArray[i][1] = parseFloat((startValue - step * i).toFixed(2));
      }
    } else {
      for (let i = 0; i < length; i++) {
        interpolationArray[i][1] = startValue;
      }
    }
    return interpolationArray;
  };

  /**
   * @descrtiption Function to check Feed Net Energy and Metabolized Protein
   * @param customerFeedId - Which consists value of Customer Feed Id
   * @param customerFeeds - Which consists data of customer Feeds
   * @returns - feedNetEnergy, feedMetabolizedProtein, DM for Array
   */
  const checkEnergyAndMetabolizedProteinValues = (
    customerFeedPlan: ICustomerFeedPlan | undefined
  ) => {
    let isInvalidValue: boolean = false;
    if (customerFeedPlan) {
      customerFeedPlan.feeds.forEach((item) => {
        if (item.nEdairy === null || item.tmp === null || item.dm === null) {
          isInvalidValue = true;
        }
      });
    }
    return isInvalidValue;
  };

  /**
   * @descrtiption Function to calculate Feed Net Energy and Metabolized Protein
   * @param customerFeedId - Which consists value of Customer Feed Id
   * @param customerFeeds - Which consists data of customer Feeds
   * @returns - feedNetEnergy, feedMetabolizedProtein, DM for Array
   */
  const calculateFeedNetEnergyAndMetabolizedProtein = (
    customerFeedId: number,
    customerFeeds: ICustomerFeed[]
  ): number[] => {
    let feedNetEnergy: number = 0;
    let feedMetabolizedProtein: number = 0;
    let neDiaryYieldValue: string | number | null = 0;
    let tmpYieldValue: string | number | null = 0;
    let DM: string | number | null = 0;
    customerFeeds.forEach((item) => {
      if (item.id === customerFeedId) {
        neDiaryYieldValue = item.nEdairy;
        tmpYieldValue = item.tmp;
        DM = item.dm;
      }
    });

    feedNetEnergy = parseFloat(neDiaryYieldValue.toFixed(2));
    feedMetabolizedProtein = parseFloat(tmpYieldValue.toFixed(2));
    return [feedNetEnergy, feedMetabolizedProtein, DM];
  };

  const calculateLinearInterpolationForFeedDiet = (feedData: ITableDataRow) => {
    const feedDietArray: any = convertObjectToJsonArray(feedData);
    let feedDietYieldValueArray: any = feedDietArray.slice(4);
    const startValue: number = feedDietYieldValueArray[0][1];
    const endValue: number = feedDietYieldValueArray[feedDietYieldValueArray.length - 1][1];
    feedDietYieldValueArray = calculateLinearInterpolationOnArray(
      startValue,
      endValue,
      feedDietYieldValueArray
    );
    feedDietArray.forEach((item: any, index: number) => {
      if (feedDietYieldValueArray[index] && item[0] === feedDietYieldValueArray[index][0]) {
        item[1] = [feedDietYieldValueArray[index][1]];
      }
    });
    const feedDietObject: any = convertJsonArrayToObject(feedDietArray);
    return feedDietObject;
  };

  const getFeedDietWeightForYieldValue = (yieldValue: string, feedDietItem: IFeedDietItem) => {
    const yieldValuesForFeedDiet = convertObjectToJsonArray(feedDietItem.yieldLevelValues);
    const requiredYieldData: any = yieldValuesForFeedDiet.find((item) => item[0] === yieldValue);
    const weight: number = parseFloat(requiredYieldData[1]);
    return weight;
  };

  /**
   * @descrtiption Function to calculate Required Energy for DryCow
   * @param animalWeight - Which consists of value of Animal Weight
   * @param customerFeedPlan - Which consists of Customer Feed Plan data
   * @param index - Which consists of Yield level position
   * @returns - animalRequiredNetEnergy
   */
  const getRequiredEnergyForDryCow = (
    animalWeight: number | undefined,
    customerFeedPlan: ICustomerFeedPlan | undefined,
    index: number
  ) => {
    const a = 42.4;
    const b = 0.75;
    const c = 0.975;
    const g = 6.9;
    let animalRequiredNetEnergy = 0;

    if (animalWeight && customerFeedPlan) {
      let neIndex: number = 0;
      customerFeedPlan.yieldLevels[index].values.forEach((item1, index1) => {
        if (item1.name === "nePreg") {
          neIndex = index1;
        }
      });
      const nePreg: number = customerFeedPlan.yieldLevels[index].values[neIndex].value;
      animalRequiredNetEnergy = (a * animalWeight ** b * c + nePreg) * g;
      animalRequiredNetEnergy = Math.round(animalRequiredNetEnergy * 100) / 100;
    }
    return animalRequiredNetEnergy;
  };

  /**
   * @descrtiption Function to calculate Required Metabolized Protein for DryCow
   * @param animalWeight - Which consists of value of Animal Weight
   * @param customerFeedPlan - Which consists of Customer Feed Plan data
   * @param index - Which consists of Yield level position
   * @returns - animalRequiredMetabolizedProtein
   */
  const getRequiredMetabolizedProteinForDryCow = (
    animalWeight: number | undefined,
    customerFeedPlan: ICustomerFeedPlan | undefined,
    index: number
  ) => {
    const a = 2.75;
    const b = 0.5;
    const c = 0.2;
    const d = 0.6;
    const e = 0.67;
    let animalRequiredMetabolizedProtein = 0;
    if (animalWeight && customerFeedPlan) {
      let tmpIndex: number = 0;
      customerFeedPlan.yieldLevels[index].values.forEach((item1, index1) => {
        if (item1.name === "mpPreg") {
          tmpIndex = index1;
        }
      });
      const mpPreg: number = customerFeedPlan.yieldLevels[index].values[tmpIndex].value;
      animalRequiredMetabolizedProtein =
        (a * animalWeight ** b + c * animalWeight ** d) / e + mpPreg;
      animalRequiredMetabolizedProtein = Math.round(animalRequiredMetabolizedProtein * 100) / 100;
    }
    return animalRequiredMetabolizedProtein;
  };

  /**
   * @descrtiption Function to calculate Required Net Energy
   * @param animalWeight - Which consists of value of Animal Weight
   * @param yieldLevel - Which is Value of the Yield Level
   * @returns - animalRequiredNetEnergy
   */
  const getRequiredNetEnergy = (
    animalWeight: number | undefined,
    yieldLevelString: string
  ): number => {
    const a = 42.4;
    const b = 0.75;
    const c = 0.975;
    const d = 435;
    const e = 0.7298;
    const f = 2;
    const g = 6.9;

    const yieldLevel = parseInt(yieldLevelString);
    let animalRequiredNetEnergy = 0;
    if (animalWeight) {
      const yieldValue: number = yieldLevel;
      animalRequiredNetEnergy =
        (a * animalWeight ** b * c + (d * yieldValue + e * yieldValue ** f)) * g;
      animalRequiredNetEnergy = Math.round(animalRequiredNetEnergy * 100) / 100;
      animalRequiredNetEnergy = adjustForEnergyMobilization(animalRequiredNetEnergy, yieldLevel)
    }

    return animalRequiredNetEnergy;
  };

  /**
   * @descrtiption Function to calculate Required Metabolized Protein
   * @param animalWeight - Which consists of value of Animal Weight
   * @param yieldLevel - Which is Value of the Yield Level
   * @returns - animalRequiredMetabolizedProtein
   */
  const getRequiredMetabolizedProtein = (
    animalWeight: number | undefined,
    yieldLevelString: string
  ): number => {
    const a = 2.75;
    const b = 0.5;
    const c = 0.2;
    const d = 0.6;
    const e = 0.67;
    const f = 1.29;
    const g = 34;
    const h = 0.000196;
    const i = 2;

    const yieldLevel = parseInt(yieldLevelString);
    let animalRequiredMetabolizedProtein = 0;
    if (animalWeight) {
      const yieldValue: number = yieldLevel;
      animalRequiredMetabolizedProtein =
        (a * animalWeight ** b + c * animalWeight ** d) / e +
        (f * (yieldValue * g) + h * (yieldValue * g) ** i);
      animalRequiredMetabolizedProtein = Math.round(animalRequiredMetabolizedProtein * 100) / 100;
      animalRequiredMetabolizedProtein = adjustForProteinMobilization(
        animalRequiredMetabolizedProtein,
        yieldLevel
      );
    }

    return animalRequiredMetabolizedProtein;
  };

  /**
   * @descrtiption Function to calculate Remaining Energy and Protein required for Animal
   * to get the Optimized diet.
   * @param arNe - Which consists value of Required Energy for Animal
   * @param arMp - Which consists value of Required Metabolized Protein for Animal
   * @param feedDiets - Which consists data of all the Feed Diet Items
   * @param yieldValue - Which is Value of the Yield for the Animal Type
   * @param customerFeeds - Which is all the Customer Feeds available.
   * @returns - remainingNetEnergy, remainingMetabolizedProtein in Array
   */
  const getRemainingNetEnergyAndMetabolizedProtein = (
    arNe: number,
    arMp: number,
    feedDiets: IFeedDietItem[],
    yieldValue: string,
    customerFeeds: ICustomerFeed[]
  ): number[] => {
    let remainingNetEnergy: number = arNe;
    let remainingMetabolizedProtein: number = arMp;
    const DRY_MATTER_KEY = 2;
    feedDiets.forEach((feedDietItem: IFeedDietItem) => {
      const weight: number = getFeedDietWeightForYieldValue(yieldValue, feedDietItem);
      const feedNetEnergyAndMetabolizedProtein: number[] =
        calculateFeedNetEnergyAndMetabolizedProtein(feedDietItem.customerFeedId, customerFeeds);
      remainingNetEnergy -=
        (weight *
          feedNetEnergyAndMetabolizedProtein[0] *
          feedNetEnergyAndMetabolizedProtein[DRY_MATTER_KEY]) /
        1000;
      remainingMetabolizedProtein -=
        (weight *
          feedNetEnergyAndMetabolizedProtein[1] *
          feedNetEnergyAndMetabolizedProtein[DRY_MATTER_KEY]) /
        1000;
    });
    remainingNetEnergy = Math.round(remainingNetEnergy);
    remainingMetabolizedProtein = Math.round(remainingMetabolizedProtein);
    return [remainingNetEnergy, remainingMetabolizedProtein];
  };

  /**
   * @descrtiption Function to calculate required Amount for Feed A and Feed B
   * @param rrNe - Which consists data of remaining Net Energy
   * @param rrMp - Which consists data of remaining Metabolized Protein
   * @param aNe - Which consists data of Feed A Net Energy
   * @param aMp - Which consists data of Feed A Metabolized Protein
   * @param bNe - Which consists data of Feed B Net Energy
   * @param bMp - Which consists data of Feed B Metabolized Protein
   * @param aDM - Which consists data of Feed A Dry Matter
   * @param bDM - Which consists data of Feed B Dry Matter
   * @returns - requiredNetEnergy, requiredMetabolizedProtein in Array
   */
  const getNeededAmountOfFeedB = (
    rrNe: number,
    rrMp: number,
    aNe: number,
    aMp: number,
    bNe: number,
    bMp: number,
    aDM: number,
    bDM: number
  ): number[] => {
    const KILOGRAM_UNIT = 1000;
    let aKg: number = 0;
    let bKg: number = (aNe * rrMp - aMp * rrNe) / (aNe * bMp - aMp * bNe);

    if (bKg < 0) {
      let multiplierNetEnergy: number = rrNe / aNe;
      let multiplierMetabolizedProtein: number = rrMp / aMp;
      multiplierNetEnergy = parseFloat(multiplierNetEnergy.toFixed(2));
      multiplierMetabolizedProtein = parseFloat(multiplierMetabolizedProtein.toFixed(2));
      aKg =
        multiplierNetEnergy > multiplierMetabolizedProtein
          ? multiplierNetEnergy / aDM
          : multiplierMetabolizedProtein / aDM;
    } else {
      aKg = (rrNe - bKg * bNe) / aNe;
      aKg /= aDM;
    }
    bKg = bKg < 0 ? 0 : bKg / bDM;

    if (aKg < 0) {
      aKg = 0;
      let multiplierNetEnergy: number = rrNe / bNe;
      let multiplierMetabolizedProtein: number = rrMp / bMp;
      multiplierNetEnergy = parseFloat(multiplierNetEnergy.toFixed(2));
      multiplierMetabolizedProtein = parseFloat(multiplierMetabolizedProtein.toFixed(2));
      if (multiplierNetEnergy < 0 && multiplierMetabolizedProtein < 0) {
        bKg = 0;
      } else {
        bKg =
          multiplierNetEnergy > multiplierMetabolizedProtein
            ? multiplierNetEnergy / bDM
            : multiplierMetabolizedProtein / aDM;
      }
    }
    aKg *= KILOGRAM_UNIT;
    bKg *= KILOGRAM_UNIT;
    aKg = parseFloat(aKg.toFixed(2));
    bKg = parseFloat(bKg.toFixed(2));

    return [aKg, bKg];
  };

  //* ############ STEP 4 in req. doc.
  /**
   * @descrtiption Function to calculate Required Energy and Protein for Animal
   * for particular Yield Level.
   * @param customerFeedPlan - Which consists data of Customer Feed Plan
   * @param yieldValue - Which is Value of the Yield for the Animal Type
   * @param animalWeight - Which is Animal Weight
   * @param index - Which is the position of the Yield Level
   * @returns - requiredNetEnergy, requiredMetabolizedProtein in Array
   */
  const getRequiredNetEnergyAndMetabolizedProteinForYield = (
    customerFeedPlan: ICustomerFeedPlan | undefined,
    yieldValue: string,
    animalWeight: number | undefined,
    index: number
  ) => {
    let requiredNetEnergy: number = 0;
    let requiredMetabolizedProtein: number = 0;
    // Check Animal Type is different than Milking Cow
    if (customerFeedPlan?.animalType.name !== "milkCow") {
      if (customerFeedPlan) {
        if (customerFeedPlan?.animalType.name === "dryCow") {
          requiredNetEnergy = getRequiredEnergyForDryCow(animalWeight, customerFeedPlan, index);
          requiredMetabolizedProtein = getRequiredMetabolizedProteinForDryCow(
            animalWeight,
            customerFeedPlan,
            index
          );
        } else {
          let neIndex: number = 0;
          let tmpIndex: number = 0;
          customerFeedPlan.yieldLevels[index].values.forEach((item1, index1) => {
            if (item1.name === "neDay") {
              neIndex = index1;
            } else if (item1.name === "tmpDay") {
              tmpIndex = index1;
            }
          });
          requiredNetEnergy = customerFeedPlan.yieldLevels[index].values[neIndex].value;
          requiredMetabolizedProtein = customerFeedPlan.yieldLevels[index].values[tmpIndex].value;
        }
      }
    } else {
      // TODO: Fetch required values from backend instead of calculating in frontend
      // Calculate Animal NE and MP
      requiredNetEnergy = getRequiredNetEnergy(animalWeight, yieldValue);
      requiredMetabolizedProtein = getRequiredMetabolizedProtein(animalWeight, yieldValue);
    }
    return [requiredNetEnergy, requiredMetabolizedProtein];
  };

  /**
   * @descrtiption Function to handle Feed Optimization for the 1 selected Feed.
   * @param feedDietForOptimization - Which consist Feeds that needs to be optimized
   * @param basicFeeds - Which consists of rest of the other feeds in the Feed Diet
   * @param animalWeight - Which is Animal Weight
   * @param customerFeedPlan - Which consists data of Customer Feed Plan
   * @param customerFeeds - Which consists of all Customer Feeds
   * @returns - customerFeedPlan Array
   */
  const optimizeSingleFeedDietItem = (
    feedDietForOptimization: IFeedDietItem[],
    basicFeeds: IFeedDietItem[],
    animalWeight: number | undefined,
    customerFeedPlan: ICustomerFeedPlan | undefined,
    customerFeeds: ICustomerFeed[]
  ) => {
    const YIELD_LEVEL_ID_KEY = 0;
    const YIELD_LEVEL_ID_VALUE = 1;
    const KILOGRAM_UNIT = 1000;

    const yieldValuesDataA = feedDietForOptimization[0].yieldLevelValues;
    const yieldValuesForFeedDietA = convertObjectToJsonArray(yieldValuesDataA);
    yieldValuesForFeedDietA.forEach((item, index) => {
      const yieldValue: string = item[YIELD_LEVEL_ID_KEY];

      const [requiredNetEnergy, requiredMetabolizedProtein] =
        getRequiredNetEnergyAndMetabolizedProteinForYield(
          customerFeedPlan,
          yieldValue,
          animalWeight,
          index
        );

      const [remainingNetEnergy, remainingMetabolizedProtein] =
        getRemainingNetEnergyAndMetabolizedProtein(
          requiredNetEnergy,
          requiredMetabolizedProtein,
          basicFeeds,
          yieldValue,
          customerFeeds
        );

      const [feedNetEnergy, feedMetabolizedProtein, feedDM] =
        calculateFeedNetEnergyAndMetabolizedProtein(
          feedDietForOptimization[0].customerFeedId,
          customerFeeds
        );
      let feedAmountForA: number = 0;
      const rrNe: number = remainingNetEnergy;
      const rrMp: number = remainingMetabolizedProtein;
      const aNe: number = feedNetEnergy;
      const aMp: number = feedMetabolizedProtein;
      const aDM: number = feedDM;

      const multiplierNetEnergy = rrNe / aNe;
      const multiplierMetabolizedProtein = rrMp / aMp;

      if (multiplierNetEnergy < 0 && multiplierMetabolizedProtein < 0) {
        feedAmountForA = 0;
      } else {
        feedAmountForA = multiplierNetEnergy / aDM;
      }

      feedAmountForA *= KILOGRAM_UNIT;
      feedAmountForA = parseFloat(feedAmountForA.toFixed(2));
      item[YIELD_LEVEL_ID_VALUE] = feedAmountForA;
    });
    const updatedFeedDietAYieldValues: any = convertJsonArrayToObject(yieldValuesForFeedDietA);
    feedDietForOptimization[0].yieldLevelValues = updatedFeedDietAYieldValues;
    // Update the current feed Plan
    customerFeedPlan?.feedDietItems.forEach((item, index) => {
      if (
        feedDietForOptimization[index] &&
        item.customerFeedId === feedDietForOptimization[index].customerFeedId
      ) {
        item.yieldLevelValues = feedDietForOptimization[index].yieldLevelValues;
      }
    });
    return customerFeedPlan;
  };

  /**
   * @descrtiption Function to handle Feed Optimization for the 2 selected Feeds.
   * @param feedDietForOptimization - Which consist Feeds that needs to be optimized
   * @param basicFeeds - Which consists of rest of the other feeds in the Feed Diet
   * @param animalWeight - Which is Animal Weight
   * @param customerFeedPlan - Which consists data of Customer Feed Plan
   * @param customerFeeds - Which consists of all Customer Feeds
   * @returns - customerFeedPlan Array
   */
  const optimizeTwoFeedDietItems = (
    feedDietForOptimization: IFeedDietItem[],
    basicFeeds: IFeedDietItem[],
    animalWeight: number | undefined,
    customerFeedPlan: ICustomerFeedPlan | undefined,
    customerFeeds: ICustomerFeed[]
  ) => {
    const yieldValuesDataA = feedDietForOptimization[0].yieldLevelValues;
    const yieldValuesForFeedDietA = convertObjectToJsonArray(yieldValuesDataA);
    const yieldValuesDataB = feedDietForOptimization[1].yieldLevelValues;
    const yieldValuesForFeedDietB = convertObjectToJsonArray(yieldValuesDataB);

    yieldValuesForFeedDietB.forEach((item, index) => {
      const YIELD_LEVEL_ID_KEY = 0;
      const YIELD_LEVEL_ID_VALUE = 1;
      const yieldValue: string = item[YIELD_LEVEL_ID_KEY];

      const [requiredNetEnergy, requiredMetabolizedProtein] =
        getRequiredNetEnergyAndMetabolizedProteinForYield(
          customerFeedPlan,
          yieldValue,
          animalWeight,
          index
        );

      const [remainingNetEnergy, remainingMetabolizedProtein] =
        getRemainingNetEnergyAndMetabolizedProtein(
          requiredNetEnergy,
          requiredMetabolizedProtein,
          basicFeeds,
          yieldValue,
          customerFeeds
        );

      // Calculate Feed A and Feed B NE and MP values
      const [feedBNetEnergy, feedBMetabolizedProtein, feedBDM] =
        calculateFeedNetEnergyAndMetabolizedProtein(
          feedDietForOptimization[1].customerFeedId,
          customerFeeds
        );

      const [feedANetEnergy, feedAMetabolizedProtein, feedADM] =
        calculateFeedNetEnergyAndMetabolizedProtein(
          feedDietForOptimization[0].customerFeedId,
          customerFeeds
        );

      // Calculate Feed B weight
      const [feedAKgAmount, feedBKgAmount] = getNeededAmountOfFeedB(
        remainingNetEnergy,
        remainingMetabolizedProtein,
        feedANetEnergy,
        feedAMetabolizedProtein,
        feedBNetEnergy,
        feedBMetabolizedProtein,
        feedADM,
        feedBDM
      );

      yieldValuesForFeedDietA[index][YIELD_LEVEL_ID_VALUE] = feedAKgAmount;
      item[YIELD_LEVEL_ID_VALUE] = feedBKgAmount;
    });
    const updatedFeedDietAYieldValues: any = convertJsonArrayToObject(yieldValuesForFeedDietA);
    const updatedFeedDietBYieldValues: any = convertJsonArrayToObject(yieldValuesForFeedDietB);
    feedDietForOptimization[0].yieldLevelValues = updatedFeedDietAYieldValues;
    feedDietForOptimization[1].yieldLevelValues = updatedFeedDietBYieldValues;
    // Update the current feed Plan
    customerFeedPlan?.feedDietItems.forEach((item, index) => {
      if (
        feedDietForOptimization[index] &&
        item.customerFeedId === feedDietForOptimization[index].customerFeedId
      ) {
        item.yieldLevelValues = feedDietForOptimization[index].yieldLevelValues;
      }
    });

    return customerFeedPlan;
  };

  /**
   * @description Function to handle Feed Optimization for the selected Feeds.
   * These function performs single feed or double feed optimization depend
   * upon the number of feeds selected by the user.
   * @param customerFeedPlan - Which consist data of Customers Feed Plan
   * @param customerFeeds - Which consists of all Customer Feeds
   * @returns
   */
  const optimizeFeedDietItems = (
    customerFeedPlan: ICustomerFeedPlan | undefined,
    customerFeeds: ICustomerFeed[]
  ) => {
    const feedDietForOptimization: IFeedDietItem[] = [];
    const basicFeeds: IFeedDietItem[] = [];
    const animalWeight: number | undefined = customerFeedPlan?.animalWeight;
    let updatedCustomerFeedplan: ICustomerFeedPlan | undefined = customerFeedPlan;

    // Get the feeds for optimization
    customerFeedPlan?.feedDietItems.forEach((item) => {
      if (item.isOptimized === true) {
        feedDietForOptimization.push(item);
      } else {
        basicFeeds.push(item);
      }
    });
    if (feedDietForOptimization.length === 2) {
      updatedCustomerFeedplan = optimizeTwoFeedDietItems(
        feedDietForOptimization,
        basicFeeds,
        animalWeight,
        customerFeedPlan,
        customerFeeds
      );
    } else if (feedDietForOptimization.length === 1) {
      updatedCustomerFeedplan = optimizeSingleFeedDietItem(
        feedDietForOptimization,
        basicFeeds,
        animalWeight,
        customerFeedPlan,
        customerFeeds
      );
    }
    return updatedCustomerFeedplan;
  };

  return {
    calculateFeedNetEnergyAndMetabolizedProtein,
    calculateLinearInterpolationForFeedDiet,
    calculateLinearInterpolationOnArray,
    checkEnergyAndMetabolizedProteinValues,
    convertJsonArrayToObject,
    convertObjectToJsonArray,
    getFeedDietWeightForYieldValue,
    getRequiredNetEnergy,
    getRequiredMetabolizedProtein,
    getRequiredEnergyForDryCow,
    getRequiredMetabolizedProteinForDryCow,
    getRequiredNetEnergyAndMetabolizedProteinForYield,
    getRemainingNetEnergyAndMetabolizedProtein,
    getNeededAmountOfFeedB,
    getNeededAmountOfFeedAByNe,
    getNeededAmoutOfFeedAByMp,
    optimizeFeedDietItems,
    optimizeSingleFeedDietItem,
    optimizeTwoFeedDietItems,
  };
}

export default useFeedDietOptimization;
