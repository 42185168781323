import { RefObject, useState } from "react";
import useEventListener from "./useEventListener";

interface IUseContextMenuReturnType {
  xPos: string;
  yPos: string;
  showMenu: boolean;
  closeMenu: () => void;
  data: string;
}

function useContextMenu<T extends HTMLElement = HTMLElement>(
  element: RefObject<T>,
  keyPrefix?: string,
  disabled?: boolean,
  callback?: () => void
): IUseContextMenuReturnType {
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [data, setData] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  const getOffset = (evt: any) => {
    let x = 0;
    let y = 0;
    let clickData = "";
    x = evt.layerX;
    y = evt.layerY;
    if (evt.target.innerHTML !== "") {
      clickData = evt.target.innerText;
    }
    return { x, y, data: clickData };
  };

  const handleContextMenu = (e: MouseEvent) => {
    if (keyPrefix === "feedDietData") {
      e.preventDefault();
      e.stopPropagation();
      const offsetArray = getOffset(e);
      const newXPos = offsetArray.x;
      const newYPos = offsetArray.y;
      const clickedData = offsetArray.data;
      setXPos(`${newXPos}px`);
      setYPos(`${newYPos}px`);
      setData(clickedData);
      setShowMenu(true);

      if (callback) {
        callback();
      }
    }
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  useEventListener(
    "contextmenu",
    (event) => {
      if (!disabled) {
        handleContextMenu(event as unknown as MouseEvent);
      }
    },
    element
  );

  return { xPos, yPos, showMenu, closeMenu, data };
}

export default useContextMenu;
