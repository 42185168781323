import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { BASE_STANDARD_FEEDS_URL } from "assets/constants";

import IFeedsState from "interfaces/IFeedsState";
import IPostOptions from "interfaces/IPostOptions";
import IPutOptions from "interfaces/IPutOptions";
import IDeleteOptions from "interfaces/IDeleteOptions";

import { Feed } from "types";
import useConfirm from "hooks/useConfirm";
import { useTranslation } from "react-i18next";

import useTableHeaderSorter from "./useTableHeaderSorter";
import useSaveFeedRequest from "./useSaveFeedRequest";

// Helper functions
import {
  addNewOrCopiedFeedToFeedsToAdd,
  addFeedToFeedsToDelete,
  getNewRowId,
} from "./helpers/useFeedHandlerHelper";

type FeedToClear = "ADD" | "DELETE" | "UPDATE";

/**
 * The useFeedHandler takes care of feed data manipulations
 * and almost always returns a new fresh updated state that you can
 * send straight to the reducer (if you have one). The only
 * thing you need to present to the useFeedHandler is the
 * state of a certain feed type (IStandardFeed/ICustomerFeed)
 * in which the manipulations are supposed to take place. It
 * supplies you with the methods to do so. It also supplies
 * CRUD methods for saving and changing data in the DB.
 * @param {IFeedsState} state
 * @return {IFeedsState} state
 */
const useFeedHandler = <S extends IFeedsState<Feed>>(state: S) => {
  const { get, put, post, delete: deleteFeed, isLoading: isFetchLoading } = useFetch();
  const { sortPropertiesOfCustomerFeeds } = useTableHeaderSorter();
  const [isLoading, setIsLoading] = useState(isFetchLoading);
  const { isConfirmed } = useConfirm();
  const { t } = useTranslation();

  const { copyFeedMixToDatabase } = useSaveFeedRequest();

  useEffect(() => {
    setIsLoading(isFetchLoading);
  }, [isFetchLoading]);

  /**
   * @description Adds a specific feed to the state
   */
  const addASpecificFeed = (specificFeedToAdd: Feed): S => {
    specificFeedToAdd.id = getNewRowId(state);

    state = addNewOrCopiedFeedToFeedsToAdd(state, specificFeedToAdd);

    const tempFeeds = [specificFeedToAdd, ...state.feeds];

    for (let i = 0; i < tempFeeds.length; i++) {
      tempFeeds[i].sortIndex = i;
    }

    return { ...state, feeds: tempFeeds };
  };

  /**
   * @description Adds a feed to the feedsToUpdate array
   * @param feedToAdd 
   * @returns {Object} State & Temporary feeds to update
   */
  const addFeedToFeedsToUpdate = (feedToAdd: Feed): S => {
    const indexIfStandardFeedRowExists = state.feedsToUpdate.findIndex(
      (feed) => feed.id === feedToAdd.id
    );

    const standardFeedsToUpdateTemp = [...state.feedsToUpdate];

    if (indexIfStandardFeedRowExists === -1) {
      standardFeedsToUpdateTemp.push(feedToAdd);
    } else {
      standardFeedsToUpdateTemp.splice(indexIfStandardFeedRowExists, 1, feedToAdd);
    }

    return { ...state, feedsToUpdate: standardFeedsToUpdateTemp };
  };

  /**
   * @description Adds a new feed to the state
   * Get a copy of the first object, then loop through
   * it and reset all values except id which you be equal
   * to the lenght of the array plus one.Use that as the "new" row.
   * @returns {Object} State & Temporary feeds
   */
  const addNewFeed = (): S => {
    const newFeed = { ...state.feeds[0] };

    Object.keys(newFeed).forEach((key) => { 
      if (key === "id") {
        newFeed[key] = getNewRowId(state);
      } else if (key === "supportsLfu" || key === "supportsSfr") {
        newFeed[key] = false;
      } else {
        newFeed[key] = null;
      }
    });

    // Add the new row to state.feedsToAdd in order to (maybe) be sent to the backend later
    state = addNewOrCopiedFeedToFeedsToAdd(state, newFeed);

    if (!state.selectedFeed) {
      const feedsTemp = [...state.feeds];
      feedsTemp.unshift(newFeed);
      return {
        ...state,
        feeds: feedsTemp,
      };
    }

    const indexOfSelectedFeed = state.feeds.indexOf(state.selectedFeed!);
    const feedsTemp = [...state.feeds];

    feedsTemp.splice(indexOfSelectedFeed + 1, 0, newFeed);

    return { ...state, feeds: feedsTemp };
  };

  /**
   * @description Adds a specific feed to the state without saving it to the database
   * @param feedToAdd 
   * @returns {Object} State & Temporary feeds
   */
  const addASpecificFeedNoSave = (feedToAdd: Feed): S => {
    const feedsTemp = [...state.feeds];
    feedsTemp.push(feedToAdd);
    return { ...state, feeds: feedsTemp };
  };

  const clearFeeds = (feedsToClear?: FeedToClear): S => {
    if (feedsToClear === "ADD") {
      return { ...state, feedsToAdd: [] };
    }

    if (feedsToClear === "DELETE") {
      return { ...state, feedsToDelete: [] };
    }

    if (feedsToClear === "UPDATE") {
      return { ...state, feedsToUpdate: [] };
    }

    return { ...state, feeds: [], feedsToAdd: [], feedsToDelete: [], feedsToUpdate: [] };
  };

  /**
   * @description Copies the selected feed
   * @returns {Object} State & Copied temporary feed
   */
  const copySelectedFeed = (): S => {
    const feedToCopy = { ...state.selectedFeed! };

    feedToCopy.id = getNewRowId(state);

    const keysToReset = ["feedGroup", "feedNumber", "feedName"];
    keysToReset.forEach((key) => {
      feedToCopy[key] = null;
    });

    // Add the copied row to state.feedsToAdd in order to (maybe) be sent to the backend later
    state = addNewOrCopiedFeedToFeedsToAdd(state, feedToCopy);

    const feedsTemp = [...state.feeds];
    const indexOfSelectedFeed = state.feeds.indexOf(state.selectedFeed!);
    feedsTemp.splice(indexOfSelectedFeed + 1, 0, feedToCopy);

    return { ...state, feeds: feedsTemp };
  };

  /**
   * @description Deletes feeds from the database
   * @param url 
   * @param feedsToDelete 
   * @param options
   * @returns void 
   */
  const deleteFeeds = async (
    url: string,
    feedsToDelete: Feed[],
    options?: IDeleteOptions
  ): Promise<void> => {
    
    const promises = feedsToDelete.map(async (feed) => {
      let result = await deleteFeed(`${url}/${feed.id}`, { method: "DELETE", ...options });
      let skipError: boolean = false;
      if (result === 400 || result === 500) {
        await isConfirmed(`${t("deleteUnsuccessful")} ${feed.feedName}`, "alert");
        skipError = true;
      }
      if (!result.ok && !skipError) {
        result = await result.json();
        const { errorMessage } = result;
        throw new Error(errorMessage);
      }
    });
    
    await Promise.all(promises);
  };

  /**
   * @description Keeps track of changes to add to db
   * @param feedToEdit 
   * @returns {Object} State & temporary feeds to add
   */
  const editFeedsToAdd = (feedToEdit: Feed): S => {
    const indexIfStandardFeedRowExists = state.feedsToAdd.findIndex(
      (feed) => feed.id === feedToEdit.id
    );

    const feedsToAddTemp = [...state.feedsToAdd];
    feedsToAddTemp.splice(indexIfStandardFeedRowExists, 1, feedToEdit);

    return { ...state, feedsToAdd: feedsToAddTemp };
  };

  /**
   * @description Gets feeds from the database
   * @param url 
   * @returns {Object} State & feeds
   */
  const getFeeds = async (url: string): Promise<S> => {
    const result: { feeds: Feed[] } = await get(url);

    if (url !== BASE_STANDARD_FEEDS_URL) {
      /* This runs if it is a customerFeed, since we don't have a sorting for standardFeeds yet */
      result.feeds = sortPropertiesOfCustomerFeeds(result.feeds);
    }

    return { ...state, feeds: result.feeds };
  };

  /**
   * @description Posts feeds to the database
   * @param url 
   * @param feedsToPost 
   * @param options 
   * @returns void
   */
  const postFeeds = async (
    url: string,
    feedsToPost: Feed[],
    options?: IPostOptions
  ): Promise<void> => {
    
    const promises = feedsToPost.map(async (feed) => {
      delete feed.id;
      delete feed.updated;
      delete feed.created;
    
      let result = await post(url, {
        method: "POST",
        body: JSON.stringify(feed),
        ...options,
      });
    
      if (!result.ok) {
        result = await result.json();
        const { errorMessage } = result;
        throw new Error(errorMessage);
      }
    });
    
    await Promise.all(promises);
  };

  /**
   * @description Puts feeds to the database
   * @param url 
   * @param feedsToPut 
   * @param options
   * @returns void 
   */
  const putFeeds = async (
    url: string,
    feedsToPut: Feed[],
    options?: IPutOptions
  ): Promise<void> => {

    const promises = feedsToPut.map(async (feed) => {
      const { id } = feed;
      delete feed.id;
      delete feed.updated;
      delete feed.created;
    
      let result;
      if (feed.isMixed) {
        const { feedName, feedNumber, isActive } = feed;
    
        result = await put(
          `${url}/${id}?feedName=${feedName}&feedNumber=${feedNumber}&isActive=${isActive}`,
          {
            method: "PUT",
            body: JSON.stringify(feed),
            ...options,
          }
        );
      } else {
        result = await put(`${url}/${id}`, {
          method: "PUT",
          body: JSON.stringify(feed),
          ...options,
        });
      }
    
      if (!result.ok) {
        result = await result.json();
        const { errorMessage } = result;
        throw new Error(errorMessage);
      }
    });
    
    await Promise.all(promises);
  };

  /**
   * @description Removes feeds from the state
   * @returns {Object} State & temporary feeds after removal
   */
  const removeFeeds = (): S => {
    state = addFeedToFeedsToDelete(state, state.selectedFeed!);
    const feedsTemp = state.feeds.filter((feed) => feed !== state.selectedFeed);

    return {
      ...state,
      feeds: feedsTemp,
    };
  };

  /**
   * @description Removes a specific feed from the state
   * @param feedToRemove 
   * @returns {Object} State & temporary feeds after removal
   */
  const removeSpecificFeed = (feedToRemove: Feed): S => {
    state = addFeedToFeedsToDelete(state, feedToRemove);
    const feedTemp = state.feeds.filter((feed) => feed.id !== feedToRemove.id);

    return {
      ...state,
      feeds: feedTemp,
    };
  };

  /**
   * @description Removes a specific feed from the state without deleting it
   * @param feedToRemove 
   * @returns {Object} State & temporary feeds
   */
  const removeSpecificFeedNoDelete = (feedToRemove: Feed): S => {
    const feedTemp = state.feeds.filter((feed) => feed.id !== feedToRemove.id);

    return {
      ...state,
      feeds: feedTemp,
    };
  };

  /**
   * @description Saves the feed name from the modal
   * @param feedName 
   * @returns {Object} State & temporary feeds
   */
  const saveFeedNameFromModal = async (feedName: string) => {
    const feedToCopy = { ...state.selectedFeed! };
    const feedMixId = feedToCopy.id;
    feedToCopy.feedName = feedName;
    feedToCopy.id = getNewRowId(state);

    if (feedToCopy.isMixed) {
      const { feedNumber } = feedToCopy;

      if (feedMixId && feedNumber) {
        const newMix = await copyFeedMixToDatabase(feedMixId, feedName, feedNumber);

        state = addNewOrCopiedFeedToFeedsToAdd(state, newMix);

        const feedsTemp = [...state.feeds];

        const indexOfSelectedFeed = state.feeds.indexOf(state.selectedFeed!);

        if (indexOfSelectedFeed) {
          feedsTemp.splice(indexOfSelectedFeed + 1, 0, feedToCopy);
        } else {
          feedsTemp.unshift(feedToCopy);
        }

        return { ...state, feeds: feedsTemp };
      }
    } else {

      state = addNewOrCopiedFeedToFeedsToAdd(state, feedToCopy);

      const feedsTemp = [...state.feeds];
      const indexOfSelectedFeed = state.feeds.indexOf(state.selectedFeed!);

      if (indexOfSelectedFeed) {
        feedsTemp.splice(indexOfSelectedFeed + 1, 0, feedToCopy);
      } else {
        feedsTemp.unshift(feedToCopy);
      }
      return { ...state, feeds: feedsTemp };
    }
  };
  
  const updatedSelectedFeed = (selectedFeed: Feed): S => ({ ...state, selectedFeed });

  return {
    addASpecificFeed,
    addFeedToFeedsToUpdate,
    addNewFeed,
    addASpecificFeedNoSave,
    clearFeeds,
    copySelectedFeed,
    deleteFeeds,
    editFeedsToAdd,
    getFeeds,
    isLoading,
    postFeeds,
    putFeeds,
    removeFeeds,
    removeSpecificFeed,
    removeSpecificFeedNoDelete,
    saveFeedNameFromModal,
    updatedSelectedFeed,
  };
}

export default useFeedHandler;
