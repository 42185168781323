import { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "react-error-boundary";

import { setReadOnlyMode } from "pages/choose-customer/context/customerActions";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";

import LMButton from "components/lm-button/LMButton";
import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useFeedMixCalculation from "hooks/useFeedMixCalculation";
import IFeedMixCreateRequest from "interfaces/IFeedMixCreateRequest";
import useConfirm from "hooks/useConfirm";

const FeedMixCreateNew = (): JSX.Element => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const history = useHistory();
  const handleError = useErrorHandler();
  const { saveFeedMixToDatabase, updateFeedMixItemsInDatabase } = useSaveFeedRequest();
  const { isConfirmed } = useConfirm();
  const { handleYieldLevelDataCalculation } = useFeedMixCalculation();
  const { yieldLevel } = useParams<{ yieldLevel: string }>();
  const {
    customerState: { currentCustomer, currentFeedPlan, readOnlyMode },
    customerDispatch,
  } = useContext(CustomerContext);
  const [feedNumber, setFeedNumber] = useState<string | undefined>(undefined);
  const [feedName, setFeedName] = useState<string | undefined>(undefined);

  useEffect(
    () => () => {
      setFeedNumber(undefined);
      setFeedName(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnClickCancel = () => goBack();

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!feedNumber || !feedName) {
      return isConfirmed(t("enterAllFields"), "alert");
    }

    const requestObject: IFeedMixCreateRequest = {
      customerId: currentCustomer?.customerId || "",
      feedName,
      feedNumber,
    };

    try {
      const response = await saveFeedMixToDatabase(requestObject);

      if (yieldLevel && currentFeedPlan) {
        try {
          const feedMixItems = handleYieldLevelDataCalculation(yieldLevel, currentFeedPlan);
          await updateFeedMixItemsInDatabase(feedMixItems, response.id, true);
        } catch (_error) {
          return handleError(_error);
        }
      }

      if (readOnlyMode) {
        customerDispatch(setReadOnlyMode(false));
      }

      if (response && response?.id) {
        return history.push(`/feed-mix/${response?.id}`);
      }
    } catch (error) {
      return handleError(error);
    }
  };

  const getYieldDescriptionByLevel = () => {
    const yieldLevelValue: number = parseInt(yieldLevel);
    const yieldLevelData = currentFeedPlan?.yieldLevels.find(
      (_yieldLevel) => _yieldLevel.level === yieldLevelValue
    );
    return yieldLevelData?.description;
  };

  const handleOnNumberChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setFeedNumber(e.target.value);
  const handleOnNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setFeedName(e.target.value);

  return (
    <>
      <div className="sub-header">
        <h3 className="customer-name">
          {currentCustomer && currentCustomer.name
            ? currentCustomer.name
            : currentCustomer?.fullName1}
        </h3>
      </div>
      <form onSubmit={handleOnSubmit}>
        <div className="wrapper">
          <div>
            <p>{t("recentFeedPlansParameters.feedNumber")}</p>
            <input type="number" onChange={handleOnNumberChange} />
          </div>
          <div>
            <p>{t("recentFeedPlansParameters.feedName")}</p>
            <input type="text" onChange={handleOnNameChange} />
          </div>
        </div>

        <div className="button-wrapper">
          <LMButton text={t("createNew")} type="submit" className="createFeedButton" />
          <LMButton
            text={t("cancel")}
            className="cancelFeedButton"
            type="button"
            click={handleOnClickCancel}
          />
        </div>
        {yieldLevel && (
          <div className="margin-top-5 yield-level-info">
            <p>{`${t("createNewMixFeedPlan")} ${currentFeedPlan?.name}`}</p>
            <p>{`${t("createNewMixYieldLevel")} ${getYieldDescriptionByLevel()}`}</p>
          </div>
        )}
      </form>
    </>
  );
};

export default FeedMixCreateNew;
