import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ICustomerDetails from "interfaces/ICustomerDetails";

interface ICustomerInformation {
  data: ICustomerDetails;
}

const CustomerInformation = ({ data }: ICustomerInformation): JSX.Element => {
  const { t } = useTranslation();
  const { t: tWithKeyPrefix } = useTranslation("", { keyPrefix: "chooseCustomerParameters" });
  const customerInfoItems = [
    "accountNumber",
    "organizationNumber",
    "fullName1",
    "address",
    "phone",
    "email",
  ];

  const renderInterestCodes = useMemo(
    (): JSX.Element[] =>
      data.interestCodes.map((code) => (
        <div key={code.id} className="interestCodes">
          <span className="interestDescription">{code.description}</span>
        </div>
      )),
    [data]
  );

  const renderProductionProfiles = useMemo(
    (): JSX.Element[] =>
      data.productionProfiles.map((profile) => (
        <div key={profile.id} className="productionProfile">
          <span className="productionDescription">{profile.description}</span>
          <span className="productionNumber">{profile.no}</span>
        </div>
      )),
    [data]
  );

  const renderCustomerInfo = useMemo(
    (): JSX.Element[] =>
      customerInfoItems.map((item) => (
        <div key={item}>
          <h3>{tWithKeyPrefix(item)}</h3>
          {item !== "address" ? (
            <p>{data.customer[item]}</p>
          ) : (
            <p>{`${data.customer[item]}, ${data.customer.zipCode} ${data.customer.city}`}</p>
          )}
        </div>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, customerInfoItems]
  );

  return (
    <div className="customerInformation">
      <div className="card-row">
        <div className="card">{renderCustomerInfo}</div>
        {data.interestCodes.length > 0 && (
          <div className="card">
            <h3>{t("interestCodes")}</h3>
            {renderInterestCodes}
          </div>
        )}
        {data.productionProfiles.length > 0 && (
          <div className="card">
            <h3>{t("productionProfiles")}</h3>
            {renderProductionProfiles}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerInformation;
