import { useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import useIsFirstRender from "hooks/useIsFirstRender";

import IAction from "interfaces/IAction";

import IIndexable from "interfaces/IIndexable";

interface YieldValues {
  start: number | string;
  step: number | string;
  end: number | string;
}

interface IYieldSettingsState extends IIndexable {
  start: number | string;
  step: number | string;
  end: number | string;
}

function yieldSettingsReducer(
  yieldSettingsState: IYieldSettingsState,
  action: IAction
): IYieldSettingsState {
  switch (action.type) {
    case "UPDATE_START":
      return { ...yieldSettingsState, start: action.payload };
    case "UPDATE_STEP":
      return { ...yieldSettingsState, step: action.payload };
    case "UPDATE_END":
      return { ...yieldSettingsState, end: action.payload };
    default:
      return yieldSettingsState;
  }
}

interface IPreferredYieldSettingsProps {
  onYieldSettingsUpdate?: (yieldSettings: IYieldSettingsState) => void;
  yieldValues?: YieldValues;
}

const PreferredYieldSettings = ({
  onYieldSettingsUpdate,
  yieldValues,
}: IPreferredYieldSettingsProps): JSX.Element => {
  const [yieldSettingsState, dispatch] = useReducer(yieldSettingsReducer, {
    start: yieldValues?.start || "",
    step: yieldValues?.step || "",
    end: yieldValues?.end || "",
  });

  const { t } = useTranslation();
  const { t: tWithKeyPrefix } = useTranslation("", {
    keyPrefix: "createOrEditFeedPlanParameters",
  });
  const firstRender = useIsFirstRender();

  useEffect(() => {
    // Don't want to invoke the onYieldSettingsUpdate on the first render
    if (firstRender) return;

    if (onYieldSettingsUpdate) {
      onYieldSettingsUpdate(yieldSettingsState);
    }
  }, [firstRender, onYieldSettingsUpdate, yieldSettingsState]);

  const handleInputUpdate = useCallback((param: string, inputValue: string) => {
    if (param === "UPDATE_START") {
      dispatch({
        type: "UPDATE_START",
        payload: inputValue !== "" ? parseInt(inputValue) : "",
      });
    } else if (param === "UPDATE_STEP") {
      dispatch({
        type: "UPDATE_STEP",
        payload: inputValue !== "" ? parseInt(inputValue) : "",
      });
    } else if (param === "UPDATE_END") {
      dispatch({
        type: "UPDATE_END",
        payload: inputValue !== "" ? parseInt(inputValue) : "",
      });
    }
  }, []);

  return (
    <div className="yield-settings">
      <p>{tWithKeyPrefix("enterPreferredYieldLevels")}</p>
      <div className="yield-settings-wrapper">
        <div className="input-wrapper">
          <div className="start">
            <label htmlFor="start">{t("start")}</label>
            <input
              value={yieldSettingsState.start}
              onChange={(e) => handleInputUpdate("UPDATE_START", e.target.value)}
              id="start"
              type="number"
              step="1"
              min="20"
              max="50"
            />
          </div>
          <div className="step">
            <label htmlFor="step">{t("step")}</label>
            <input
              value={yieldSettingsState.step}
              onChange={(e) => handleInputUpdate("UPDATE_STEP", e.target.value)}
              id="step"
              type="number"
              step="1"
              min="0"
              max="10"
            />
          </div>
          <div className="end">
            <label htmlFor="end">{t("end")}</label>
            <input
              value={yieldSettingsState.end}
              onChange={(e) => handleInputUpdate("UPDATE_END", e.target.value)}
              id="end"
              type="number"
              step="1"
              min="20"
              max="50"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferredYieldSettings;
