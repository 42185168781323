/* eslint-disable no-restricted-syntax */
import { useCallback, useContext, useMemo, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IDS_NOT_TO_DISPLAY_IN_TABLES } from "assets/constants";

import useToggle from "hooks/useToggle";
import useOnClickOutside from "hooks/useOnClickOutside";
import useContextMenu from "hooks/useContextMenu";
import useFeedDietOptimization from "hooks/useFeedDietOptimization";
import useConfirm from "hooks/useConfirm";
import useEventListener from "hooks/useEventListener";

import { TableDataContext } from "components/table/Table";
import ContextMenu from "components/context-menu/ContextMenu";

import {
  updateTableDataRow,
  updateSelectedTableDataRow,
} from "components/table/context/tableDataActions";

import ITableDataRow from "interfaces/ITableDataRow";
import ITableDataDecimals from "interfaces/ITableDataDecimals";
import IMenuChoice from "interfaces/IMenuChoice";

import TableData from "./TableData";

interface ITableRowProps {
  index: number;
  tableDataRow: ITableDataRow;
  uniqueParamsCombo?: string[];
  checkIfParamsComboForRowIsUnique?: (
    key: string,
    value: string,
    rowId: number
  ) => boolean | undefined;
  optimizedFeedDiets?: number;
  disabled?: boolean;
  keyPrefix?: string;
  handleFeedMixCalculation?: (updatedColumn: string, value: number) => void;
  defaultSelected?: boolean;
  userHiddenTableColumns?: number[];
  tableDataDecimalConfig?: ITableDataDecimals;
  unsavedItems?: boolean;
  disableHighlight?: boolean;
  deleteFeedPlan?: (id: number, name: string) => void;
  tableSortOrder?: (direction: string, row: ITableDataRow) => void;
  onDoubleClickLeft?: (row: any) => void;
  onDoubleClickRight?: (row: any) => void;
}

const TableRow = ({
  tableDataRow,
  index,
  uniqueParamsCombo,
  checkIfParamsComboForRowIsUnique,
  optimizedFeedDiets,
  handleFeedMixCalculation,
  disabled,
  keyPrefix,
  defaultSelected,
  userHiddenTableColumns,
  tableDataDecimalConfig,
  unsavedItems,
  disableHighlight,
  deleteFeedPlan,
  tableSortOrder,
  onDoubleClickLeft,
  onDoubleClickRight,
}: ITableRowProps): JSX.Element => {
  const thisTableRow = useRef<HTMLTableRowElement>(null);
  const [isRowSelected, toggleIsRowSelected] = useToggle(false);
  const { t } = useTranslation();
  const { t: tWithKeyPrefix } = useTranslation("", {
    keyPrefix: "contextMenu",
  });
  const history = useHistory();
  const {
    tableDataState: { tableData },
  } = useContext(TableDataContext);
  const { isConfirmed } = useConfirm();
  const { calculateLinearInterpolationForFeedDiet } = useFeedDietOptimization();
  const { showMenu, xPos, yPos, closeMenu } = useContextMenu(thisTableRow, keyPrefix, disabled);

  let classes = ["table-row"];

  const { dispatch } = useContext(TableDataContext);

  const updateRow = async (key: string, newValue: any): Promise<void> => {
    if (
      key === "isOptimized" &&
      optimizedFeedDiets &&
      optimizedFeedDiets >= 2 &&
      newValue === true
    ) {
      await isConfirmed(t("feedDietDataCheckboxError"), "alert");
      return;
    }

    const tableDataRowToUpdate: ITableDataRow = { ...tableDataRow };
    const currentTableDataRowState: ITableDataRow | undefined = tableDataRowToUpdate.id
      ? tableData.find((row) => row.id === tableDataRowToUpdate.id)
      : undefined;

    if (currentTableDataRowState && currentTableDataRowState[key] === newValue) {
      return;
    }

    if (newValue === "") {
      tableDataRowToUpdate[key] = null;
    } else {
      tableDataRowToUpdate[key] = newValue;
    }

    if (handleFeedMixCalculation && (key === "shareInPercKg" || key === "amountKg")) {
      handleFeedMixCalculation(key, newValue);
    } else {
      dispatch(updateTableDataRow(tableDataRowToUpdate, index));
    }
  };

  const handleOnClick: React.MouseEventHandler<HTMLTableRowElement> = (e) => {
    if (deleteFeedPlan && (e.target as Element).className.includes("deleteFeedPlan")) {
      const { id, name } = tableDataRow;
      if (id && name) {
        return deleteFeedPlan(id, name);
      }
    }

    if (tableSortOrder && (e.target as Element).className.includes("sortTableArrow")) {
      const { innerText } = e.target as HTMLElement;
      return tableSortOrder(innerText, tableDataRow);
    }

    if (!isRowSelected) {
      toggleIsRowSelected(true);
      dispatch(updateSelectedTableDataRow(tableDataRow));
    }
  };

  const handleOnClickOutside = () => {
    if (isRowSelected) {
      toggleIsRowSelected(false);
    }
  };

  useEffect(() => {
    if (defaultSelected) {
      if (!isRowSelected) {
        toggleIsRowSelected(true);
        dispatch(updateSelectedTableDataRow(tableDataRow));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfParamsComboIsUnique = useCallback(
    (key: string, value: string) => {
      if (!checkIfParamsComboForRowIsUnique) {
        return;
      }

      if (!tableDataRow.id) {
        return;
      }

      return checkIfParamsComboForRowIsUnique(key, value, tableDataRow.id);
    },
    [checkIfParamsComboForRowIsUnique, tableDataRow.id]
  );

  const interpolateFeedDietValues = useCallback(() => {
    if (keyPrefix === "feedDietData") {
      const updatedTableRow: ITableDataRow = calculateLinearInterpolationForFeedDiet(tableDataRow);
      dispatch(updateTableDataRow(updatedTableRow, index));
    }
  }, [keyPrefix, tableDataRow, calculateLinearInterpolationForFeedDiet, dispatch, index]);

  const contextMenuChoices: IMenuChoice[] = useMemo(
    () => [
      {
        text: `${tWithKeyPrefix("fillInTheValues")}`,
        callback: interpolateFeedDietValues,
        icon: "edit",
      },
    ],
    [interpolateFeedDietValues, tWithKeyPrefix]
  );

  useOnClickOutside(thisTableRow, handleOnClickOutside);

  useEventListener(
    "dblclick",
    () => {
      if (onDoubleClickLeft) {
        onDoubleClickLeft(tableDataRow);
      }
      if (onDoubleClickRight) {
        onDoubleClickRight(tableDataRow);
      }
    },
    thisTableRow
  );

  const renderTableDataColumns = (): JSX.Element[] => {
    // We don't want to show an id property.
    const tableRowTemp: ITableDataRow = { ...tableDataRow };
    const { id } = tableRowTemp;

    for (const key of IDS_NOT_TO_DISPLAY_IN_TABLES) {
      delete tableRowTemp[key];
    }

    let shouldBeDisabled = disabled;

    if (disabled && tableRowTemp.isMixed && !history.location.pathname.includes("feed-mix")) {
      shouldBeDisabled = false;
    }

    return Object.entries(tableRowTemp).map((_tableData, _index) => (
      <TableData
        key={_tableData[0]}
        tableDataKey={_tableData[0]}
        numberOfDecimals={
          tableDataDecimalConfig &&
          tableDataDecimalConfig[_tableData[0] as keyof ITableDataDecimals]
            ? tableDataDecimalConfig[_tableData[0] as keyof ITableDataDecimals]
            : 0
        }
        tableDataValue={_tableData[1]}
        update={updateRow}
        checkIfParamsComboIsUnique={checkIfParamsComboIsUnique}
        uniqueParamsCombo={uniqueParamsCombo}
        disabled={shouldBeDisabled}
        feedMix={
          tableRowTemp.feedGroup === 99
            ? {
                feedNumber: tableRowTemp.feedNumber,
                feedName: tableRowTemp.feedName,
                id,
              }
            : undefined
        }
        hidden={userHiddenTableColumns?.some((hiddenItem) => hiddenItem === _index)}
        unsavedItems={unsavedItems}
        disableHighlight={disableHighlight}
      />
    ));
  };

  if (isRowSelected) {
    classes.push("selected");
  } else {
    classes = classes.filter((c) => c !== "selected");
  }

  return (
    <tr
      className={`${classes.join(" ")} ${tableDataRow.isMixed ? "feed-mix" : ""} `}
      onClick={handleOnClick}
      ref={thisTableRow}
    >
      {renderTableDataColumns()}
      {showMenu && (
        <td style={{ width: 0, padding: 0 }}>
          <ContextMenu xPos={xPos} yPos={yPos} close={closeMenu} menu={contextMenuChoices} />
        </td>
      )}
    </tr>
  );
};

export default TableRow;
