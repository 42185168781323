import configuration from "config";

const { backendBaseUrl } = configuration;

export const BASE_FEED_MIX_URL = `${backendBaseUrl}/api/customerfeedmixes`;
export const BASE_STANDARD_FEEDS_URL = `${backendBaseUrl}/api/standardFeeds`;
export const BASE_CUSTOMER_FEEDS_URL = `${backendBaseUrl}/api/customerFeeds`;
export const BASE_FEED_CALCULATIONS_URL = `${backendBaseUrl}/api/feedcalculations`;
export const BASE_CUSTOMERS_URL = `${backendBaseUrl}/api/customers`;
export const BASE_FEED_PLAN_URL = `${backendBaseUrl}/api/feedPlans`;
export const BASE_USER_URL = `${backendBaseUrl}/api/users/current`;
export const BASE_ANIMAL_TYPES_URL = `${backendBaseUrl}/api/animaltypes`;
export const BASE_YIELD_LEVEL_URL = `${backendBaseUrl}/api/yieldlevels`;
// eslint-disable-next-line max-len
export const DEFAULT_CONTROL_PARAMETER_SETTINGS_URL = `${backendBaseUrl}/api/defaultcontrolparametersettings`;
export const FEED_SMART_SUPER_ADMIN = "Appl-Epi-FeedSmartSuperAdmin";
export const IDS_NOT_TO_DISPLAY_IN_TABLES = [
  "id",
  "customerId",
  "customerFeedId",
  "printMixName",
  "sortIndex",
];
