import { useTranslation } from "react-i18next";

import LMButton from "components/lm-button/LMButton";
import { IFallbackProps } from "../../interfaces/IError";

/**
 * @description Page: Render Error message triggered by Hook - UseErrorHandler
 * @param {IError} error
 * @param {(...args: unknown[]) => void} resetErrorBoundary
 * @returns JSX.Element
 */
const ErrorPage = ({ error, resetErrorBoundary }: IFallbackProps) => {
  const { t } = useTranslation();
  const { t: tWithPrefix } = useTranslation("", { keyPrefix: "apiErrorStatusMessage" });

  return (
    <div className="ErrorPageWrapper margin-top-30">
      <h1 className="margin-bottom-30 errorHeader">{t("somethingWrong")}</h1>
      <pre className="margin-bottom-30 errorMessage">{tWithPrefix(`error${error.message}`)}</pre>
      <LMButton
        type="button"
        click={() => {
          resetErrorBoundary();
          window.location.replace(window.location.origin);
        }}
        text={t("back")}
      />
    </div>
  );
};

export default ErrorPage;
