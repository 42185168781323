import { useMemo, createContext, useReducer } from "react";
import IContextProps from "interfaces/IContextProps";
import IStandardFeed from "interfaces/IStandardFeed";
import IAction from "interfaces/IAction";
import IFeedsState from "interfaces/IFeedsState";
import standardFeedsReducer from "./standardFeedsReducer";


interface IStandardFeedsContext {
  standardFeedsState: IFeedsState<IStandardFeed>;
  standardFeedsDispatch: React.Dispatch<IAction>;
}

export const StandardFeedsContext = createContext<IStandardFeedsContext>(
  {} as IStandardFeedsContext
);

const StandardFeedsProvider = ({ children }: IContextProps): JSX.Element => {
  const [standardFeedsState, standardFeedsDispatch] = useReducer(standardFeedsReducer, {
    feeds: [],
    feedsToAdd: [],
    feedsToUpdate: [],
    feedsToDelete: [],
  } as IFeedsState<IStandardFeed>);

  const values = useMemo(() => ({
    standardFeedsState,
    standardFeedsDispatch,
  }), [standardFeedsState, standardFeedsDispatch]);

  return <StandardFeedsContext.Provider value={values}>{children}</StandardFeedsContext.Provider>;
};

export default StandardFeedsProvider;
