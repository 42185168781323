import IAction from "interfaces/IAction";
import ICustomerFeed from "interfaces/ICustomerFeed";
import ICustomerFeedsState from "interfaces/ICustomerFeedsState";
import IFeedsState from "interfaces/IFeedsState";

import {
  ADD_CUSTOMER_FEED_TO_CUSTOMER_FEEDS_TO_UPDATE,
  CLEAR_CUSTOMER_FEEDS,
  COPY_SELECTED_CUSTOMER_FEED,
  EDIT_CUSTOMER_FEEDS_TO_ADD,
  ADD_NEW_CUSTOMER_FEED,
  REMOVE_CUSTOMER_FEED,
  SET_CUSTOMER_FEEDS,
  UPDATE_CUSTOMER_FEEDS,
  UPDATE_CUSTOMER_FEEDS_AFTER_CALCULATION,
  UPDATE_SELECTED_CUSTOMER_FEED,
} from "pages/customer-feeds/context/customerFeedsActions";

function updateCustomerFeeds(
  customerFeedsState: IFeedsState<ICustomerFeed>,
  action: IAction
): IFeedsState<ICustomerFeed> {
  return { ...customerFeedsState, feeds: action.payload };
}

function updateCustomerFeedsAfterCalculation(
  customerFeedsState: ICustomerFeedsState,
  action: IAction
): ICustomerFeedsState {
  const feedToUpdate: ICustomerFeed = action.payload.feed;
  const feedsToUpdate = [...customerFeedsState.feedsToUpdate, feedToUpdate];
  const feedsTemp: ICustomerFeed[] = [...customerFeedsState.feeds];
  const indexOfFeed = customerFeedsState.feeds.findIndex((feed) => feed.id === feedToUpdate.id);
  const { sortOrder } = action.payload;
  const indexOfFeedSortOrder = sortOrder.findIndex(
    (feed: ICustomerFeed) => feed.id === feedToUpdate.id
  );


  if (indexOfFeed !== -1) {
    const existingFeed = JSON.parse(JSON.stringify(feedsTemp[indexOfFeed]));

    Object.keys(feedToUpdate).forEach((key) => {
      if (key in existingFeed)
        existingFeed[key] = feedToUpdate[key];
    });

    feedsTemp.splice(indexOfFeed, 1, existingFeed);

    /**
     * If we have a sortOrder we update the sortOrder array with the new feed.
     */
    if (sortOrder && indexOfFeedSortOrder) {
      sortOrder.splice(indexOfFeedSortOrder, 1, existingFeed);
    }
  }

  /**
   * If we have a sortOrder we sort the feeds array based on that before returning.
   */
  if (sortOrder) {
    feedsTemp.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
  }

  return {
    ...customerFeedsState,
    feedsToUpdate,
    feeds: feedsTemp,
  };
}

function updateCustomerFeedsState(customerFeedsState: IFeedsState<ICustomerFeed>, action: IAction) {
  return { ...customerFeedsState, ...action.payload };
}

function updateSelectedCustomerFeed(
  customerFeedsState: IFeedsState<ICustomerFeed>,
  action: IAction
): IFeedsState<ICustomerFeed> {
  if (action.payload) {
    return { ...customerFeedsState, selectedFeed: action.payload };
  }

  return { ...customerFeedsState, selectedFeed: undefined };
}

/**
 * @description Reducer method
 * @param {ICustomerFeedsState} customerFeedsState
 * @param {IAction} action
 * @return {*} {ICustomerFeedsState}
 */
function customerFeedsReducer(
  customerFeedsState: ICustomerFeedsState,
  action: IAction
): ICustomerFeedsState {
  const args: [IFeedsState<ICustomerFeed>, IAction] = [customerFeedsState, action];

  switch (action.type) {
    case UPDATE_CUSTOMER_FEEDS:
      return updateCustomerFeeds(...args);
    case UPDATE_CUSTOMER_FEEDS_AFTER_CALCULATION:
      return updateCustomerFeedsAfterCalculation(...args);
    case UPDATE_SELECTED_CUSTOMER_FEED:
      return updateSelectedCustomerFeed(...args);

    case ADD_CUSTOMER_FEED_TO_CUSTOMER_FEEDS_TO_UPDATE:
    case CLEAR_CUSTOMER_FEEDS:
    case COPY_SELECTED_CUSTOMER_FEED:
    case EDIT_CUSTOMER_FEEDS_TO_ADD:
    case ADD_NEW_CUSTOMER_FEED:
    case REMOVE_CUSTOMER_FEED:
    case SET_CUSTOMER_FEEDS:
      return updateCustomerFeedsState(...args);
    default:
      return customerFeedsState;
  }
}

export default customerFeedsReducer;
