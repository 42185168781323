import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * @description This component is used to logout the user from the application.
 * @returns JSX.Element
 */
const Logout = (): JSX.Element => {
  const { logout } = useAuth0();
  
  useEffect(() => {
    const logOutFunction = async () => {
      await logout({ returnTo: window.location.origin });
    };

    logOutFunction();
  }, [logout]);

  return <div />;
};

export default Logout;
