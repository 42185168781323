import useEventListener from "hooks/useEventListener";

function useOnPageLeave(unsaved: boolean): void {
  const handler = (event: BeforeUnloadEvent): void => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEventListener("beforeunload", (event) => {
    if (unsaved) {
      handler(event as BeforeUnloadEvent);
    }
  });
}

export default useOnPageLeave;
