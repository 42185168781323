import { useContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "react-error-boundary";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import LMButton from "components/lm-button/LMButton";
import Loader from "components/loader/Loader";

import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useToggle from "hooks/useToggle";
import useConfirm from "hooks/useConfirm";

import IFeedMixPrint, { IYieldRangeSets } from "interfaces/IFeedMixPrint";
import IFeedPlanPrint from "interfaces/IFeedPlanPrint";

import FeedMixPrint from "./parts/FeedMixPrint";
import FeedPlanPrint from "./parts/FeedPlanPrint";

interface PrintPageProps {
  type: "feedMix" | "feedPlan";
}

interface ISectionsToPrint {
  [key: string]: boolean;
}

const PrintPage = ({ type }: PrintPageProps) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { isConfirmed } = useConfirm();
  const {
    customerState: { currentFeedMix, currentFeedPlan },
  } = useContext(CustomerContext);
  const { downloadFeedMixPDF, downloadFeedPlanPDF } = useSaveFeedRequest();
  const handleError = useErrorHandler();

  const [sectionsToPrint, setSectionsToPrint] = useState<ISectionsToPrint>({
    FeedDiet: true,
    ControlParameters: true,
    CustomerFeeds: true,
    FeedMixSection: true,
    NutrientsSection: true,
    MixRecipe: true,
  });
  const [printFor, setPrintFor] = useState<string[]>(["KgTotalMix"]);
  const [printForValues, setPrintForValues] = useState<IYieldRangeSets>({
    totalKgMix: [0],
    numberOfAnimals: [0],
  });
  const [comment, setComment] = useState<string>();
  const [feedDietCheckbox, setFeedDietCheckbox] = useState<number[]>([]);
  const [controlParameterCheckbox, setControlParameterCheckbox] = useState<number[]>([]);
  const [checkNutrientsAndControlParameters, setCheckNutrientsAndControlParameters] =
    useState(true);
  const [includeAllNutrients, setIncludeAllNutrients] = useState(false);
  const [isPrintLoading, toggleIsPrintLoading] = useToggle(false);

  const handleOnChange = (value: string) => {
    setSectionsToPrint((prevState) => ({
      ...prevState,
      [value]: !sectionsToPrint[value],
    }));
  };

  const handlePrintForOnChange = (value: number[], category: string) => {
    setPrintForValues({ ...printForValues, [category]: value });
  };

  // eslint-disable-next-line consistent-return
  const renderSetupElements = () => {
    if (type === "feedMix") {
      return (
        <FeedMixPrint
          name={currentFeedMix?.feedName ? currentFeedMix.feedName : ""}
          handleOnChange={(value: string) => handleOnChange(value)}
          sectionsToPrint={sectionsToPrint}
          printFor={printFor}
          setPrintFor={setPrintFor}
          setPrintForValues={(value: number[], category: string) => {
            handlePrintForOnChange(value, category);
          }}
          includeAllNutrients={includeAllNutrients}
          setIncludeAllNutrients={setIncludeAllNutrients}
        />
      );
    }

    if (type === "feedPlan") {
      return (
        <FeedPlanPrint
          currentFeedPlan={currentFeedPlan}
          handleOnChange={(value: string) => handleOnChange(value)}
          sectionsToPrint={sectionsToPrint}
          setFeedDietCheckbox={setFeedDietCheckbox}
          setControlParameterCheckbox={setControlParameterCheckbox}
          checkNutrientsAndControlParameters={checkNutrientsAndControlParameters}
          setCheckNutrientsAndControlParameters={setCheckNutrientsAndControlParameters}
        />
      );
    }
  };

  const handleOnBack = () => {
    goBack();
  };

  const checkForDuplicates = useCallback(() => {
    const section = printFor.includes("KgTotalMix") ? "totalKgMix" : "numberOfAnimals";
    const duplicates = printForValues[section].filter(
      (input, index) => printForValues[section].indexOf(input) !== index && input !== 0
    );
    return !!duplicates.length;
  }, [printFor, printForValues]);

  // eslint-disable-next-line consistent-return
  const handleOnPrint = async () => {
    toggleIsPrintLoading(true);
    try {
      if (currentFeedPlan && type === "feedPlan") {
        const selectedSections = Object.keys(sectionsToPrint).filter(
          (section) =>
            section !== "FeedMixSection" &&
            section !== "NutrientsSection" &&
            section !== "MixRecipe" &&
            sectionsToPrint[section]
        );

        const settings: IFeedPlanPrint = {
          sections: selectedSections,
          feedDietYieldLevels: feedDietCheckbox,
          controlParameterYieldLevels: controlParameterCheckbox,
          includeAllNutrientsAndControlParameters: checkNutrientsAndControlParameters,
          comment,
        };

        const result = await downloadFeedPlanPDF(
          currentFeedPlan.id,
          settings,
          currentFeedPlan.name
        );

        if (result && result.link && result.file) {
          toggleIsPrintLoading(false);
          result.link.click();

          setTimeout(() => {
            URL.revokeObjectURL(result.file);
          }, 5000);
        }
      } else if (currentFeedMix && currentFeedMix.id && currentFeedMix.feedName) {
        if (!Object.values(sectionsToPrint).some((val) => val === true) || !printFor.length) {
          return await isConfirmed(t("enterAllFields"), "alert");
        }

        /**
         * Check if input contains duplicates. Reset values to default & prompt user.
         */
        if (checkForDuplicates()) {
          return await isConfirmed(t("duplicateInputValueError"), "alert");
        }

        const selectedSections = Object.keys(sectionsToPrint).filter(
          (section) =>
            section !== "FeedDiet" &&
            section !== "ControlParameters" &&
            section !== "CustomerFeeds" &&
            sectionsToPrint[section]
        );

        const filteredPrintForValues = Object.entries(printForValues)
          .map((entry) => ({
            [entry[0]]: entry[1].filter((value: number) => value !== 0),
          }))
          .reduce((prev, curr) => ({ ...prev, ...curr }));

        const settings: IFeedMixPrint = {
          sections: selectedSections,
          printFor,
          yieldRangeSets: filteredPrintForValues as IYieldRangeSets,
          includeAllNutrients,
          comment,
        };

        /**
         * downloadFeedMixPDF returns a html element (link) and an url.
         * To automatically download the pdf in browser we click the link
         * and then revoke the url for cleanup afterwards.
         */
        const result = await downloadFeedMixPDF(
          currentFeedMix?.id,
          settings,
          currentFeedMix?.feedName
        );

        if (result && result.link && result.file) {
          toggleIsPrintLoading(false);
          result.link.click();
          setTimeout(() => {
            URL.revokeObjectURL(result.file);
          }, 5000);
        }
      }
    } catch (error) {
      toggleIsPrintLoading(false);
      handleError(error);
    }
  };

  return (
    <div className="print">
      <div className="header">
        <h1>{t("printSetup")}</h1>
        <LMButton className="back-button" type="button" text={t("back")} click={handleOnBack} />
      </div>
      {renderSetupElements()}
      <div className="print-submit">
        <h3>{t("comment")}</h3>
        <textarea
          className="print-comment"
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
        <div className="print-button-wrapper">
          {isPrintLoading && (
            <div className="margin-right-15">
              <Loader size="small" />
              <span className="margin-left-15">{t("loading")}</span>
            </div>
          )}
          <LMButton
            className="print-button"
            type="button"
            text={t("print")}
            click={handleOnPrint}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintPage;
