import IFeedMix from "interfaces/IFeedMix";
import { IFeedMixItem } from "interfaces/IFeedMixUpdateRequest";
import ITableDataRow from "interfaces/ITableDataRow";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import ICustomerFeed from "interfaces/ICustomerFeed";

/**
 * @description Rounds input to X amount of decimals.
 * @param value
 * @param decimals
 * @returns Number
 */
export function roundToDecimal(value: number, decimals?: number) {
  if (!decimals) return Math.round(value);
  const factor = 10 ** decimals;
  return Math.round(value * factor) / factor;
}

function useFeedMixCalculation() {
  // Feeds in Mix calculations
  const calcShareInPercKg = (amountKg: number, totalAmountKg: number) => {
    if (amountKg <= 0 && totalAmountKg <= 0) {
      return 0;
    }
    const calcedVal = (amountKg / totalAmountKg) * 100;

    return roundToDecimal(calcedVal, 2);
  };
  const calcShareInPercDm = (amountKgDm: number, totalAmountKgDm: number) => {
    if (amountKgDm <= 0 && totalAmountKgDm <= 0) {
      return 0;
    }
    const calcedVal = (amountKgDm / totalAmountKgDm) * 100;
    let roundedVal = roundToDecimal(calcedVal, 2);
    if (roundedVal === 0) roundedVal = roundToDecimal(calcedVal, 3);
    return roundedVal;
  };

  const calcAmountKg = (shareInPercKg: number, currShareInPercKg: number, currAmountKg: number) => {
    const calcedVal = (shareInPercKg / currShareInPercKg) * currAmountKg;

    return roundToDecimal(calcedVal, 2);
  };
  const calcAmountKgDm = (amountKg: number, dm: number) => {
    const calcedVal = amountKg * (dm / 1000);
    if (calcedVal === 0) return 0;
    let decimals = 2;
    let roundedValue = roundToDecimal(calcedVal, decimals);
    while (roundedValue === 0) {
      decimals++;
      roundedValue = roundToDecimal(calcedVal, decimals);
    }
    
    return roundedValue;
  };

  // Mix calculations
  const calcTotalAmountKg = (newValue: number, tableData: ITableDataRow[]) => {
    const calcedVal = tableData
      .map(({ amountKg }) => amountKg)
      .reduce((pre, curr) => pre + curr, newValue);
    return roundToDecimal(calcedVal, 2);
  };

  const calcTotalAmountKgDm = (newValue: number, tableData: ITableDataRow[]) => {
    const calcedVal = tableData
      .map(({ amountKgDm }) => amountKgDm)
      .reduce((pre, curr) => pre + curr, newValue);
    if (calcedVal === 0) return 0;
    let decimals = 2;
    let roundedValue = roundToDecimal(calcedVal, decimals);
    while (roundedValue === 0) {
      decimals++;
      roundedValue = roundToDecimal(calcedVal, decimals);
    }
    return roundedValue;
  };

  const calcTotalShareInPercKg = (newValue: number, tableData: ITableDataRow[]) => {
    const calcedVal = tableData
      .map(({ shareInPercKg }) => shareInPercKg)
      .reduce((pre, curr) => pre + curr, newValue);
    return roundToDecimal(calcedVal);
  };

  const calcTotalShareInPercDm = (newValue: number, tableData: ITableDataRow[]) => {
    const calcedVal = tableData
      .map(({ shareInPercDm }) => shareInPercDm)
      .reduce((pre, curr) => pre + curr, newValue);
    return roundToDecimal(calcedVal);
  };

  const handleAmountKgCalculation = (
    newValue: number,
    selectedTableRow: ITableDataRow,
    nonSelectedTableRows: ITableDataRow[],
    tableData: ITableDataRow[],
    currentFeedMix: IFeedMix
  ) => {
    const feedMixValues = {
      amountKg: 0,
      amountKgDm: 0,
      shareInPercKg: 0,
      shareInPercDm: 0,
    };

    // Calc values of Feed Mix & selected table row based on input and store on variables
    const updatedAmountKgDm = calcAmountKgDm(newValue, selectedTableRow.dm);
    feedMixValues.amountKg = calcTotalAmountKg(newValue, nonSelectedTableRows);
    feedMixValues.amountKgDm = calcTotalAmountKgDm(updatedAmountKgDm, nonSelectedTableRows);
    const updatedShareInPercKg = calcShareInPercKg(newValue, feedMixValues.amountKg);
    const updatedShareInPercDm = calcShareInPercDm(updatedAmountKgDm, feedMixValues.amountKgDm);

    selectedTableRow.amountKg = newValue;
    selectedTableRow.amountKgDm = updatedAmountKgDm;
    selectedTableRow.shareInPercKg = updatedShareInPercKg;
    selectedTableRow.shareInPercDm = updatedShareInPercDm;

    // Calc values of remaining table rows and store on variables
    const tableDataCopy = tableData.map((tableRow) => {
      if (tableRow.id !== selectedTableRow.id) {
        return {
          ...tableRow,
          shareInPercKg: tableRow.amountKg
            ? calcShareInPercKg(tableRow.amountKg, feedMixValues.amountKg)
            : 0,
          shareInPercDm: tableRow.amountKgDm
            ? calcShareInPercDm(tableRow.amountKgDm, feedMixValues.amountKgDm)
            : 0,
        };
      }
      return selectedTableRow;
    });

    feedMixValues.shareInPercKg = calcTotalShareInPercKg(
      updatedShareInPercKg,
      tableDataCopy.filter((tableRow) => tableRow.id !== selectedTableRow.id)
    );
    feedMixValues.shareInPercDm = calcTotalShareInPercDm(
      updatedShareInPercDm,
      tableDataCopy.filter((tableRow) => tableRow.id !== selectedTableRow.id)
    );

    // Update feedMixItems in currentFeedMix
    const currentFeedMixCopy = currentFeedMix;

    currentFeedMixCopy.feedMixItems = tableDataCopy.map((tableRow): IFeedMixItem => {
      const currFeedMixItem = currentFeedMixCopy.feedMixItems?.find(
        (item) => item.customerFeedId === tableRow.id
      );

      if (tableRow.id) {
        return {
          customerFeedId: tableRow.id,
          shareInPercDm: tableRow.shareInPercDm,
          shareInPercKg: tableRow.shareInPercKg,
          amountKg: tableRow.amountKg,
          amountKgDm: tableRow.amountKgDm,
          sortIndex: currFeedMixItem?.sortIndex ? currFeedMixItem?.sortIndex : 0,
        };
      }
      return {} as IFeedMixItem;
    });

    return {
      newTableData: tableDataCopy,
      newFeedMixData: { ...currentFeedMixCopy, ...feedMixValues },
    };
  };

  const handleShareInPercKgCalculation = (
    newValue: number,
    selectedTableRow: ITableDataRow,
    nonSelectedTableRows: ITableDataRow[],
    tableData: ITableDataRow[],
    currentFeedMix: IFeedMix
  ) => {
    const feedMixValues = {
      amountKg: 0,
      amountKgDm: 0,
      shareInPercKg: 0,
      shareInPercDm: 0,
    };
    // Calc values of Feed Mix & selected table row
    const currShareInPercKg = selectedTableRow.shareInPercKg;
    const currAmountKg = selectedTableRow.amountKg;
    selectedTableRow.shareInPercKg = newValue;

    selectedTableRow.amountKg = calcAmountKg(newValue, currShareInPercKg, currAmountKg);
    selectedTableRow.amountKgDm = calcAmountKgDm(selectedTableRow.amountKg, selectedTableRow.dm);

    feedMixValues.amountKg = calcTotalAmountKg(selectedTableRow.amountKg, nonSelectedTableRows);
    feedMixValues.amountKgDm = calcTotalAmountKgDm(
      selectedTableRow.amountKgDm,
      nonSelectedTableRows
    );

    selectedTableRow.shareInPercDm = calcShareInPercDm(
      selectedTableRow.amountKgDm,
      feedMixValues.amountKgDm
    );

    const tableDataCopy = tableData.map((tableRow) => {
      if (tableRow.id !== selectedTableRow.id) {
        return {
          ...tableRow,
          shareInPercDm: tableRow.amountKgDm
            ? calcShareInPercDm(tableRow.amountKgDm, feedMixValues.amountKgDm)
            : 0,
        };
      }
      return selectedTableRow;
    });

    feedMixValues.shareInPercDm = calcTotalShareInPercDm(
      selectedTableRow.shareInPercDm,
      tableDataCopy.filter((tableRow) => tableRow.id !== selectedTableRow.id)
    );

    feedMixValues.shareInPercKg = calcTotalShareInPercKg(
      newValue,
      tableDataCopy.filter((tableRow) => tableRow.id !== selectedTableRow.id)
    );

    // Update feedMixItems in currentFeedMix with new values
    const currentFeedMixCopy = currentFeedMix;
    currentFeedMixCopy.feedMixItems = tableDataCopy.map((tableRow): IFeedMixItem => {
      if (tableRow.id) {
        return {
          customerFeedId: tableRow.id,
          shareInPercDm: tableRow.shareInPercDm,
          shareInPercKg: tableRow.shareInPercKg,
          amountKg: tableRow.amountKg,
          amountKgDm: tableRow.amountKgDm,
          sortIndex: tableRow.sortIndex,
        };
      }
      return {} as IFeedMixItem;
    });

    return {
      newTableData: tableDataCopy,
      newFeedMixData: { ...currentFeedMixCopy, ...feedMixValues },
    };
  };

  /**
   * @description Function to calculate feed mix values when a feed mix
   * is created from a feed plan.
   * @param yieldLevel
   * @param feedPlan
   */
  const handleYieldLevelDataCalculation = (yieldLevel: string, feedPlan: ICustomerFeedPlan) => {
    const parsedYieldLevel = `${yieldLevel}.00`;

    const feedsData = feedPlan.feedDietItems.map((item) => {
      const feed: ICustomerFeed | undefined = feedPlan.feeds.find(
        (_feed) => _feed.id === item.customerFeedId
      );
      return { ...feed, ...item };
    });

    const totalData = feedsData
      .map((item) => {
        const dm = Number(item.dm);
        return {
          totalAmountKg: item.yieldLevelValues[parsedYieldLevel],
          totalAmountKgDm: calcAmountKgDm(item.yieldLevelValues[parsedYieldLevel], dm),
        };
      })
      .reduce((pre, curr) => ({
        totalAmountKg: pre.totalAmountKg + curr.totalAmountKg,
        totalAmountKgDm: pre.totalAmountKgDm + curr.totalAmountKgDm,
      }));

    const feedMixItems: IFeedMixItem[] = feedsData.map((item, index) => {
      const feed: ICustomerFeed | undefined = feedPlan.feeds.find(
        (_feed) => _feed.id === item.customerFeedId
      );
      const dm = feed && feed.dm ? Number(feed.dm) : 0;
      const amountKg = item.yieldLevelValues[parsedYieldLevel]
        ? item.yieldLevelValues[parsedYieldLevel]
        : 0;

      return {
        customerFeedId: item.customerFeedId,
        shareInPercDm: calcShareInPercDm(calcAmountKgDm(amountKg, dm), totalData.totalAmountKgDm),
        shareInPercKg: calcShareInPercKg(amountKg, totalData.totalAmountKg),
        amountKg,
        amountKgDm: calcAmountKgDm(amountKg, dm),
        sortIndex: index,
      };
    });

    return { feedMixItems };
  };

  return {
    calcShareInPercKg,
    calcShareInPercDm,
    calcAmountKg,
    calcAmountKgDm,
    calcTotalAmountKg,
    calcTotalAmountKgDm,
    calcTotalShareInPercKg,
    calcTotalShareInPercDm,
    handleAmountKgCalculation,
    handleShareInPercKgCalculation,
    handleYieldLevelDataCalculation,
  };
}

export default useFeedMixCalculation;
