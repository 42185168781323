import ITypeSpec from "../../../interfaces/ITypeSpec";
import IFeedDiet from "../../../interfaces/IFeedDiet";

export default function typeSpecsWithAddedYieldValueProperties(
  feedDietData: IFeedDiet[],
  feedDietDataSpecs: ITypeSpec
): ITypeSpec {
  if (!feedDietData) {
    return feedDietDataSpecs;
  }

  if (feedDietData[0].customerFeedId === 0) {
    return feedDietDataSpecs;
  }

  const updatedTypeSpecs = JSON.parse(JSON.stringify(feedDietDataSpecs));
  const blueprint = feedDietData[0];

  Object.keys(blueprint).forEach((key) => {
    if (!updatedTypeSpecs[key] && parseInt(key)) {
      updatedTypeSpecs[key] = { type: "number", editable: true };
    } else if (!updatedTypeSpecs[key] && (key === "farOff8-3w" || key === "closeUp3-0w")) {
      updatedTypeSpecs[key] = { type: "number", editable: true };
    }
  });

  return updatedTypeSpecs;
}
