import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import useConfirm from "hooks/useConfirm";
import useOnClickOutside from "hooks/useOnClickOutside";
import useSideMenu from "hooks/useSideMenu";

// eslint-disable-next-line max-len
import SideMenuChoices from "components/sidemenu/json/SideMenuChoices.json";

import ISideMenuProp from "interfaces/ISideMenuProp";
import ISideMenuChoices from "interfaces/ISideMenuChoices";

interface MenuProps {
  translation: string | null;
}

interface ISideMenyProps {
  type: string;
  toggleMenu: (val?: boolean) => void;
  unsavedProps: ISideMenuProp;
  callbackProps: any;
}

const MenuChoice = ({ translation }: MenuProps): JSX.Element => (
  <span className="menu-choice">{translation}</span>
);

const SideMenu = ({ type, toggleMenu, unsavedProps, callbackProps }: ISideMenyProps) => {
  const sideMenu = useRef(null);
  const { isConfirmed } = useConfirm();
  const { t } = useTranslation();
  const classes: string[] = ["side-menu"];
  const {
    performCloseOperation,
    performCreateOperation,
    performCopyOperation,
    performDeleteOperation,
    performOverviewOperation,
    performPrintOperation,
    performSaveOperation,
    performSettingsOperation,
    showSideMenuOptions,
  } = useSideMenu();
  let menuChoices: ISideMenuChoices[] = SideMenuChoices;

  const handleCloseMenu: (close?: boolean) => void = useCallback(
    (close = true) => {
      toggleMenu(close);
    },
    [toggleMenu]
  );

  const handleClickOutside = useCallback(() => {
    toggleMenu(false);
  }, [toggleMenu]);

  const handleOnMenuChoiceClick = async (menuChoice: ISideMenuChoices) => {
    toggleMenu();

    switch (menuChoice.text) {
      case "close":
        return performCloseOperation(type);
      case "createNew":
        return performCreateOperation(type);
      case "copyAsNew":
        return performCopyOperation(type, unsavedProps, callbackProps);
      case "delete":
        return performDeleteOperation(type);
      case "overview":
        return performOverviewOperation(type);
      case "print":
        return performPrintOperation(type, unsavedProps, callbackProps);
      case "settings":
        return performSettingsOperation(type, unsavedProps, callbackProps);
      case "save":
        return performSaveOperation(type, unsavedProps, callbackProps);
      default:
        isConfirmed(t("notImplemented"), "alert");
    }
  };

  useOnClickOutside(sideMenu, handleClickOutside);

  menuChoices = showSideMenuOptions(type, menuChoices);

  return (
    <div className={classes.join(" ")} ref={sideMenu}>
      <div className="menu-choices">
        <span
          aria-label="Close menu"
          className="material-icons"
          data-icon="close"
          role="button"
          tabIndex={0}
          onClick={() => handleCloseMenu(false)}
          onKeyDown={() => handleCloseMenu(false)}
        />
        {menuChoices.map((menuChoice) => (
          <div
            className="menu-choice"
            key={menuChoice.text.replace(/\s+/g, "")}
            role="button"
            tabIndex={0}
            onClick={() => handleOnMenuChoiceClick(menuChoice)}
            onKeyDown={() => handleOnMenuChoiceClick(menuChoice)}
          >
            <MenuChoice translation={menuChoice.translation!} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideMenu;
