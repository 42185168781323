import { createContext, useState, useMemo } from "react";
import IConfirmAlertOptions from "interfaces/IConfirmAlertOptions";
import IContextProps from "interfaces/IContextProps";

interface IConfirmContext {
  confirm: IConfirmAlertOptions,
  setConfirm: React.Dispatch<React.SetStateAction<IConfirmAlertOptions>>
}

export const ConfirmContext = createContext<IConfirmContext>({} as IConfirmContext);

/**
 * @description Context provider for confirm alert
 * @param IContextProps 
 * @returns React.FC
 */
const ConfirmContextProvider = ({ children }: IContextProps) => {
  const [confirm, setConfirm] = useState<IConfirmAlertOptions>({
    prompt: "",
    isOpen: false
  });

  /**
   * @description Memoized values for context provider
   * @returns IConfirmContext
   */
  const values = useMemo(() => ({
    confirm,
    setConfirm,
  }), [confirm, setConfirm]);

  return (
    <ConfirmContext.Provider value={values}>
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;