import { useContext, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useConfirm from "hooks/useConfirm";
import useFetch from "hooks/useFetch";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import {
  setCurrentFeedMix,
  setCurrentFeedMixItems,
  setCurrentFeedPlan,
  setCurrentFeedPlanControlSettings,
} from "pages/choose-customer/context/customerActions";

import ISideMenuProp from "interfaces/ISideMenuProp";
import ISideMenuChoices from "interfaces/ISideMenuChoices";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";

import { useTranslation } from "react-i18next";

import { BASE_FEED_PLAN_URL, BASE_FEED_MIX_URL } from "assets/constants";

const useSideMenu = () => {
  const FEED_PLAN_CALCULATION_COMPONENT = "feed-plan-calculation";
  const FEED_MIX_COMPONENT = "feed-mix";

  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { isConfirmed } = useConfirm();
  const { get } = useFetch();

  const {
    deleteFeedMixFromDatabase,
    deleteFeedPlanFromDatabase,
    saveEditedFeedsToDB,
    saveUpdatedFeedDietItemsInCurrentFeedPlan,
    saveUpdatedControlParametersItemsInCurrentFeedPlan,
    updateFeedMixItemsInDatabase,
  } = useSaveFeedRequest();

  const {
    customerState: {
      currentCustomer,
      currentFeedPlan,
      currentFeedPlanControlSettings,
      currentFeedMix,
    },
    customerDispatch,
  } = useContext(CustomerContext);

  const handleResetFeedMix = useCallback(
    async (sideMenuCallBackProp: any) => {
      const feedMixDetails = await get(`${BASE_FEED_MIX_URL}/${currentFeedMix?.id}`);

      if (!feedMixDetails.errorMessage) {
        customerDispatch(setCurrentFeedMix({ ...feedMixDetails, ...feedMixDetails.summary }));
        customerDispatch(setCurrentFeedMixItems(feedMixDetails.feedMixItems));
        sideMenuCallBackProp.setUnsavedFeedMix(false);
      }
    },
    [currentFeedMix, customerDispatch, get]
  );

  const handleResetFeedPlan = useCallback(
    async (sideMenuCallBackProp: any) => {
      const uneditedFeedPlan: ICustomerFeedPlan = await get(
        `${BASE_FEED_PLAN_URL}/${currentFeedPlan?.id}`
      );
      customerDispatch(setCurrentFeedPlan(uneditedFeedPlan));
      sideMenuCallBackProp.setUnsavedFeedDietItems(false);
      sideMenuCallBackProp.setUnsavedControlParameterItems(false);
    },
    [currentFeedPlan, get, customerDispatch]
  );

  const handleUnsavedFeedMix = async (sideMenuCallBackProp: any) => {
    try {
      const answer = await isConfirmed(t("unsavedChanges"), "reset", () =>
        handleResetFeedMix(sideMenuCallBackProp)
      );
      if (!answer || !currentFeedMix) {
        return;
      }

      if (currentFeedMix.shareInPercKg && currentFeedMix.shareInPercKg !== 100) {
        return await isConfirmed(t("feedMixAmountError"), "alert");
      }

      if (currentFeedMix.shareInPercDm && currentFeedMix.shareInPercDm > 100) {
        return await isConfirmed(t("feedMixPercError"), "alert");
      }

      const res = await updateFeedMixItemsInDatabase(
        {
          feedMixItems: currentFeedMix.feedMixItems!,
        },
        currentFeedMix.id,
        true
      );

      customerDispatch(setCurrentFeedMix({ ...res, ...res?.summary }));
      sideMenuCallBackProp.setUnsavedFeedMix(false);
    } catch (error) {
      return isConfirmed(t("saveUnsuccessful"), "alert");
    }

    return isConfirmed(t("saveSuccessful"), "alert");
  };

  const handleUnsavedFeedPlan = async (
    sideMenuValueProp: ISideMenuProp,
    sideMenuCallBackProp: any
  ) => {
    try {
      const answer = await isConfirmed(t("unsavedChanges"), "reset", () =>
        handleResetFeedPlan(sideMenuCallBackProp)
      );

      if (!answer || !currentFeedPlan) {
        return;
      }

      let updatedCurrentFeedPlan;
      let updatedFeedDietItems;

      if (sideMenuValueProp.unsavedFeedPlan) {
        await saveEditedFeedsToDB(currentFeedPlan);
        sideMenuCallBackProp.setUnsavedFeedPlan(false);
      }

      if (sideMenuValueProp.unsavedControlParameterItems) {
        updatedCurrentFeedPlan = await saveUpdatedControlParametersItemsInCurrentFeedPlan(
          currentFeedPlan,
          currentFeedPlanControlSettings
        );
        updatedFeedDietItems = await saveUpdatedFeedDietItemsInCurrentFeedPlan(
          updatedCurrentFeedPlan
        );
      } else if (sideMenuValueProp.unsavedFeedDietItems) {
        updatedFeedDietItems = await saveUpdatedFeedDietItemsInCurrentFeedPlan(currentFeedPlan);
      }

      if (updatedFeedDietItems) {
        customerDispatch(setCurrentFeedPlan(updatedFeedDietItems));
        customerDispatch(setCurrentFeedPlanControlSettings(undefined));

        sideMenuCallBackProp.setUnsavedFeedDietItems(false);
        sideMenuCallBackProp.setUnsavedControlParameterItems(false);
      }
    } catch (error) {
      return isConfirmed(t("saveUnsuccessful"), "alert");
    }

    return isConfirmed(t("saveSuccessful"), "alert");
  };

  const performCloseOperation = (componentType: string) => {
    if (componentType === FEED_PLAN_CALCULATION_COMPONENT) {
      customerDispatch(setCurrentFeedPlan());
      return history.push("/feed-plan-calculation");
    }
    if (componentType === FEED_MIX_COMPONENT) {
      customerDispatch(setCurrentFeedMix());
      return history.push("/feed-mix");
    }
  };

  const performCreateOperation = (componentType: string) => {
    if (componentType === FEED_PLAN_CALCULATION_COMPONENT) {
      return history.push("/feed-plan-calculation/create-feed-plan");
    }
    if (componentType === FEED_MIX_COMPONENT) {
      return history.push("/feed-mix/create-feed-mix");
    }
  };

  const performCopyOperation = async (
    componentType: string,
    sideMenuValueProp: ISideMenuProp,
    sideMenuCallBackProp: any
  ) => {
    if (componentType === FEED_PLAN_CALCULATION_COMPONENT) {
      if (!currentFeedPlan) return;

      const { unsavedFeedPlan, unsavedFeedDietItems, unsavedControlParameterItems } =
        sideMenuValueProp;

      if (unsavedFeedPlan || unsavedFeedDietItems || unsavedControlParameterItems) {
        await handleUnsavedFeedPlan(sideMenuValueProp, sideMenuCallBackProp);
      }
    }

    if (componentType === FEED_MIX_COMPONENT) {
      if (!currentFeedMix) {
        return;
      }
      if (sideMenuValueProp.unsavedFeedMix) {
        await handleUnsavedFeedMix(sideMenuCallBackProp);
      }
    }

    return sideMenuCallBackProp.toggleModal();
  };

  const performDeleteOperation = async (componentType: string) => {
    if (componentType === FEED_PLAN_CALCULATION_COMPONENT) {
      if ((await isConfirmed(t("deleteFeedPlanConfirmation"))) && currentFeedPlan?.id) {
        const result = await deleteFeedPlanFromDatabase(currentFeedPlan?.id);
        if (result.ok) {
          customerDispatch(setCurrentFeedPlan());
          return history.push(`/customer-overview/${currentCustomer?.customerId}`);
        }
      }
    }

    if (componentType === FEED_MIX_COMPONENT) {
      if ((await isConfirmed(t("deleteFeedMixConfirmation"))) && currentFeedMix?.id) {
        const result = await deleteFeedMixFromDatabase(currentFeedMix?.id);
        if (result.ok) {
          customerDispatch(setCurrentFeedMix());
          return history.push(`/customer-feeds/${currentCustomer?.customerId}`);
        }
      }
    }
  };

  const performPrintOperation = async (
    componentType: string,
    sideMenuValueProp: ISideMenuProp,
    sideMenuCallBackProp: any
  ) => {
    if (componentType === FEED_PLAN_CALCULATION_COMPONENT) {
      if (!currentFeedPlan) {
        return;
      }

      const { unsavedFeedPlan, unsavedFeedDietItems, unsavedControlParameterItems } =
        sideMenuValueProp;

      if (unsavedFeedPlan || unsavedFeedDietItems || unsavedControlParameterItems) {
        await handleUnsavedFeedPlan(sideMenuValueProp, sideMenuCallBackProp);
      }

      const lastPartOfUrl = pathname.split("/").pop();
      if (lastPartOfUrl === "add-remove") {
        return history.push(`/feed-plan-calculation/${currentFeedPlan.id}`);
      }

      return history.push(`/feed-plan-calculation/${currentFeedPlan.id}/print`);
    }

    if (componentType === FEED_MIX_COMPONENT) {
      if (!currentFeedMix) {
        return;
      }

      if (sideMenuValueProp.unsavedFeedMix) {
        await handleUnsavedFeedMix(sideMenuCallBackProp);
      }

      return history.push(`/feed-mix/${currentFeedMix.id}/print`);
    }
  };

  const performSaveOperation = async (
    componentType: string,
    sideMenuValueProp: ISideMenuProp,
    sideMenuCallBackProp: any
  ) => {
    if (componentType === FEED_PLAN_CALCULATION_COMPONENT) {
      const { unsavedFeedPlan, unsavedFeedDietItems, unsavedControlParameterItems } =
        sideMenuValueProp;

      if (
        !currentFeedPlan ||
        (!unsavedFeedPlan && !unsavedFeedDietItems && !unsavedControlParameterItems)
      ) {
        return isConfirmed(t("nothingToSave"), "alert");
      }

      await handleUnsavedFeedPlan(sideMenuValueProp, sideMenuCallBackProp);
    }

    if (componentType === FEED_MIX_COMPONENT) {
      if (!sideMenuValueProp.unsavedFeedMix) {
        return isConfirmed(t("nothingToSave"), "alert");
      }
      await handleUnsavedFeedMix(sideMenuCallBackProp);
    }
  };

  const performOverviewOperation = (componentType: string) => {
    if (componentType === FEED_PLAN_CALCULATION_COMPONENT) {
      if (!currentFeedPlan) {
        return;
      }

      return history.push(`/feed-plan-calculation/${currentFeedPlan.id}`);
    }
  };

  const performSettingsOperation = async (
    componentType: string,
    sideMenuValueProp: ISideMenuProp,
    sideMenuCallBackProp: any
  ) => {
    if (componentType === FEED_PLAN_CALCULATION_COMPONENT) {
      if (!currentFeedPlan) {
        return;
      }

      const { unsavedFeedPlan, unsavedFeedDietItems, unsavedControlParameterItems } =
        sideMenuValueProp;

      if (unsavedFeedPlan || unsavedFeedDietItems || unsavedControlParameterItems) {
        await handleUnsavedFeedPlan(sideMenuValueProp, sideMenuCallBackProp);
      }

      return history.push(`/feed-plan-calculation/${currentFeedPlan.id}/settings`);
    }
  };

  const showSideMenuOptions = (componentType: string, menuChoices: ISideMenuChoices[]) => {
    const filteredArray: ISideMenuChoices[] = [...menuChoices];

    return filteredArray
      .filter((menuChoice) => {
        if (!menuChoice.type.some((type) => type === componentType)) {
          return false;
        }

        if (pathname === "/feed-plan-calculation" || pathname === "/feed-mix") {
          return menuChoice.text === "createNew";
        }

        if (pathname.includes("settings")) {
          return menuChoice.text === "overview";
        }

        if (menuChoice.text === "overview") {
          return pathname.includes("settings");
        }

        return true;
      })
      .map((_menuChoice) => ({ ..._menuChoice, translation: t(_menuChoice.text) }))
      .sort((a, b) => {
        if (a.translation > b.translation) return 1;
        if (a.translation < b.translation) return -1;
        return 0;
      });
  };

  return {
    performCloseOperation,
    performCreateOperation,
    performCopyOperation,
    performDeleteOperation,
    performPrintOperation,
    performOverviewOperation,
    performSaveOperation,
    performSettingsOperation,
    showSideMenuOptions,
  };
};

export default useSideMenu;
