import { useContext, useEffect, useCallback, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import useConfirm from "hooks/useConfirm";

import CustomerInformation from "pages/customer-overview/parts/CustomerInformation";
import CustomerFeedPlanTable from "pages/customer-overview/parts/CustomerFeedPlanTable";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { setCurrentCustomer } from "pages/choose-customer/context/customerActions";

import LMButton from "components/lm-button/LMButton";
import Header from "components/header/Header";
import Loader from "components/loader/Loader";
import BasePage from "../../components/base-page/BasePage";

interface CustomerOverviewPageParams {
  customerId: string;
}

const CustomerOverviewPage = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { isConfirmed } = useConfirm();
  const [isLoading, setIsLoading] = useState(true);
  const { customerId }: CustomerOverviewPageParams = useParams();

  const {
    customerState: { currentCustomer, customerDetails },
    getCustomerDetails,
    getCustomerFeedPlans,
    customerDispatch,
  } = useContext(CustomerContext);

  const fetchCustomerDetails = useCallback(async () => {
    await getCustomerDetails(customerId);
  }, [customerId, getCustomerDetails]);

  const fetchCustomerFeedPlans = useCallback(async () => {
    await getCustomerFeedPlans(customerId);
    setIsLoading(false);
  }, [customerId, getCustomerFeedPlans]);

  useEffect(() => {
    if (!customerId && currentCustomer) {
      history.replace(`${url}/${currentCustomer.customerId}`);
    } else if (!customerId && !currentCustomer) {
      isConfirmed(t("mustChooseCustomer"), "alert");
      history.replace("/choose-customer");
    }
  }, [currentCustomer, customerId, history, t, url, isConfirmed]);

  useEffect(() => {
    if (customerId && !customerDetails) {
      fetchCustomerDetails();
    }
  }, [customerDetails, customerId, fetchCustomerDetails]);

  useEffect(() => {
    if (customerDetails && !currentCustomer) {
      customerDispatch(setCurrentCustomer(customerDetails.customer));
    }
  }, [currentCustomer, customerDetails, customerDispatch]);

  useEffect(() => {
    fetchCustomerFeedPlans();
  }, [customerId, fetchCustomerFeedPlans]);

  const handleOnCreateNewClick = useCallback(() => {
    history.push("/feed-plan-calculation/create-feed-plan");
  }, [history]);

  return (
    <BasePage pageName="customer-overview">
      {customerId ? (
        <>
          <Header title="customerOverview" hideReadOnly />
          <div className="sub-header">
            <h3 className="customer-name">
              {currentCustomer && currentCustomer.name}
              {currentCustomer && !currentCustomer.name && currentCustomer.fullName1}
            </h3>
          </div>
          <div className="wrapper">
            <div className="customer-info">
              {customerDetails ? (
                <CustomerInformation data={customerDetails} />
              ) : (
                <div>
                  <Loader size="small" />
                  <span className="margin-left-15">{t("loading")}</span>
                </div>
              )}
            </div>
            <div className="feed-plans">
              <div className="header margin-bottom-20">
                <h1 className="no-margin">{t("customerOverviewFeedTitle")}</h1>
                <div className="buttons-wrapper">
                  <LMButton text={t("createNew")} type="button" click={handleOnCreateNewClick} />
                </div>
              </div>

              <CustomerFeedPlanTable isLoading={isLoading} />
            </div>
          </div>
        </>
      ) : (
        <h2 className="text-center">{t("customerOverviewNoCustomerSelected")}</h2>
      )}
    </BasePage>
  );
};

export default withAuthenticationRequired(CustomerOverviewPage);
