/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useContext, useCallback, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";
import { clearCustomerFeeds } from "pages/customer-feeds/context/customerFeedsActions";
import SearchBar from "pages/choose-customer/parts/SearchBar";

import {
  setCurrentCustomer,
  setCustomerDetails,
  setCurrentFeedMix,
  setCurrentFeedMixItems,
  setCurrentFeedPlan,
} from "pages/choose-customer/context/customerActions";

import BasePage from "components/base-page/BasePage";
import Table from "components/table/Table";
import Header from "components/header/Header";
import Loader from "components/loader/Loader";

import ICustomer from "interfaces/ICustomer";
import IRecentCustomers from "interfaces/IRecentCustomers";

import useFeedHandler from "hooks/useFeedHandler";
import useToggle from "hooks/useToggle";

import typeSpecsChooseCustomer from "./json/typeSpecsOfChooseCustomer.json";
import typeSpecsRecentFeedPlans from "./json/typeSpecsOfRecentFeedPlans.json";

const ChooseCustomerPage = (): JSX.Element => {
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const { customerFeedsState, customerFeedsDispatch } = useContext(CustomerFeedsContext);
  const { clearFeeds } = useFeedHandler(customerFeedsState);
  const [isRecentCustomersLoading, toggleRecentCustomersLoading] = useToggle(false);
  const [showUsersRecentCustomers, toggleShowUsersRecentCustomers] = useToggle(false);
  const [isCustomersLoading, setIsCustomersLoading] = useState(false);
  const [isSearching, toggleIsSearching] = useToggle(false);
  const [noSearchResult, setNoSearchResult] = useState(false);
  const history = useHistory();

  const {
    getRecentCustomers,
    getUserRecentCustomers,
    searchCustomers,
    customerState: {
      currentCustomer,
      customers,
      recentCustomers,
      userRecentCustomers,
      currentUser,
    },
    customerDispatch,
  } = useContext(CustomerContext);

  const handleOnSubmit = useCallback(
    async (inputValue) => {
      toggleIsSearching(true);
      await searchCustomers(inputValue);
      toggleIsSearching(false);
      setIsCustomersLoading(false);
    },
    [searchCustomers]
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (!customers?.length && !noSearchResult) {
      setNoSearchResult(true);
    }
  }, [customers]);

  const onSelectedRow = useCallback(
    (selectedCustomer: ICustomer) => {
      if (currentCustomer && selectedCustomer) {
        if (currentCustomer.customerId !== selectedCustomer.customerId) {
          customerFeedsDispatch(clearCustomerFeeds(clearFeeds()));
          customerDispatch(setCurrentFeedMix());
          customerDispatch(setCurrentFeedMixItems());
          customerDispatch(setCurrentFeedPlan());
        }
      }

      customerDispatch(setCustomerDetails());
      customerDispatch(setCurrentCustomer(selectedCustomer));

      if (selectedCustomer) {
        history.push(`customer-overview/${selectedCustomer.customerId}`);
      }
    },
    [customerDispatch, customerFeedsDispatch, history]
  );

  const renderRecentCustomers = useMemo((): JSX.Element => {
    if (!recentCustomers) {
      return <p>{t("fetchingError")}</p>;
    }

    if (recentCustomers) {
      let emptyTableData: true | undefined;

      if (showUsersRecentCustomers && !userRecentCustomers?.length) {
        emptyTableData = true;
      } else if (!recentCustomers.length) {
        emptyTableData = true;
      } else {
        emptyTableData = undefined;
      }

      let tableData = [{} as IRecentCustomers];

      if (showUsersRecentCustomers && userRecentCustomers?.length) {
        tableData = userRecentCustomers;
      } else if (!showUsersRecentCustomers) {
        tableData = recentCustomers;
      }

      return (
        <Table
          data={tableData}
          onSelectedRow={onSelectedRow}
          typeSpecs={typeSpecsRecentFeedPlans}
          keyPrefix="recentFeedPlansParameters"
          emptyTableData={emptyTableData}
        />
      );
    }

    return <p>{t("emptyResults")}</p>;
  }, [
    recentCustomers,
    userRecentCustomers,
    isRecentCustomersLoading,
    onSelectedRow,
    typeSpecsRecentFeedPlans,
    showUsersRecentCustomers,
  ]);

  useEffect(() => {
    if (currentCustomer) {
      customerDispatch(setCurrentCustomer(currentCustomer));
    }

    if (currentUser && (!recentCustomers || !recentCustomers?.length)) {
      toggleRecentCustomersLoading(true);
      const asyncFetchRecentCustomers = async () => {
        await getRecentCustomers();
        toggleRecentCustomersLoading(false);
      };
      asyncFetchRecentCustomers();
    }

    if (currentUser && (!userRecentCustomers || !userRecentCustomers?.length)) {
      getUserRecentCustomers(currentUser?.username);
    }
  }, [currentCustomer, currentUser]);

  const handleUserRecentCheckboxToggle = () => {
    toggleShowUsersRecentCustomers();
  };

  const handleRefreshList = async () => {
    toggleRecentCustomersLoading(true);
    await getRecentCustomers();
    await getUserRecentCustomers(currentUser?.username!);
    toggleRecentCustomersLoading(false);
  };

  return (
    <BasePage pageName="choose-customer">
      <div className="header margin-top-30 margin-bottom-20">
        <h1 className="no-margin">{t("chooseCustomerTitle")}</h1>
      </div>
      <div className="search-wrapper">
        <SearchBar searchFunction={handleOnSubmit} />
      </div>
      {!isSearching && customers && customers.length > 0 && (
        <Table
          data={customers!}
          onSelectedRow={onSelectedRow}
          typeSpecs={typeSpecsChooseCustomer}
          keyPrefix="chooseCustomerParameters"
        />
      )}

      {isSearching && (
        <div className="loading-wrapper">
          <Loader size="small" />
          <p className="margin-left-15">{t("searching")}</p>
        </div>
      )}

      {customers && customers.length === 0 && (
        <p>
          {isCustomersLoading && t("loading")}
          {noSearchResult && !isSearching && t("emptyResults")}
          {!isCustomersLoading && !noSearchResult && !isSearching && t("makeSearch")}
        </p>
      )}

      {!customers && <p>{t("fetchingError")}</p>}

      <Header title="recentCustomers" hideReadOnly />
      <div className="refresh-list-wrapper">
        <button type="button" onClick={handleRefreshList} className="refresh-list-btn">
          <span className="refresh-icon material-icons">sync</span>
          Reload list
        </button>
      </div>
      {(recentCustomers || userRecentCustomers) && !isRecentCustomersLoading && (
        <>
          <div>{renderRecentCustomers}</div>
          <label
            className="lm__checkbox lm__tick show-only-users-checkbox"
            htmlFor="userRecentFeedPlans"
          >
            <input
              id="userRecentFeedPlans"
              type="checkbox"
              checked={showUsersRecentCustomers}
              onChange={handleUserRecentCheckboxToggle}
            />
            <label />
            <span className="margin-left-5">{t("userRecentCustomers")}</span>
          </label>
        </>
      )}

      {isRecentCustomersLoading && (
        <div className="loading-wrapper">
          <Loader size="small" />
          <p className="margin-left-15">{t("loading")}</p>
        </div>
      )}
    </BasePage>
  );
};

export default withAuthenticationRequired(ChooseCustomerPage);
