import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import sv from "./locales/sv/translation.json";
import en from "./locales/en/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
          translation: en
      },
      sv: {
          translation: sv
      },
    },
    fallbackLng: "sv",
    debug: false,
    react: {
      useSuspense: false,
      bindI18n: "languageChanged",
    },
    load: "all"
  });

export default i18n;