import { useEffect, useContext } from "react";

import BasePage from "components/base-page/BasePage";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";

import LMButton from "components/lm-button/LMButton";
import Header from "components/header/Header";

const Home = (): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, isAuthenticated, error, user, loginWithRedirect } = useAuth0();
  const {
    getCurrentUser,
    customerState: { currentUser },
  } = useContext(CustomerContext);

  /**
   * Fetch current user and store it in state
   * if a user has logged in but no user is stored in state
   */
  useEffect(() => {
    if (!currentUser && isAuthenticated) {
      getCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, currentUser]);

  return (
    <BasePage pageName="home">
      <div className="login-wrapper">
        {isAuthenticated ? (
          <>
            <Header title="home" hideReadOnly />
            <span className="text">
              {user?.name} ({user?.email})
            </span>
            <LMButton
              click={() => document.getElementById("logout-href-link")?.click()}
              text={t("logout")}
              type="button"
            />

            <a 
              id="logout-href-link" 
              href={`${window.location.origin}/#/logout`} 
              aria-label="Logout" 
              className="hidden"
            >
              Logout
            </a>
          </>
        ) : (
          <>
            <h1 className="no-margin  margin-bottom-20">{t("login")}</h1>

            {error && !isLoading && <span className="login-error">{t("loginError")}</span>}

            {isLoading ? (
              <span className="text">{t("loginLoading")}</span>
            ) : (
              <>
                <span className="text">{t("loginDescription")}</span>
                <LMButton click={() => loginWithRedirect()} text={t("login")} type="button" />
              </>
            )}
          </>
        )}
      </div>
    </BasePage>
  );
};

export default Home;
