import { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import IMenuChoice from "interfaces/IMenuChoice";

interface ContextMenuProps {
  xPos: string;
  yPos: string;
  close: () => void;
  menu: IMenuChoice[];
}

const ContextMenu = ({ xPos, yPos, close, menu }: ContextMenuProps): JSX.Element => {
  const contextMenu = useRef(null);
  useOnClickOutside(contextMenu, close);
  return (
    <div className="context-menu" ref={contextMenu} style={{ top: yPos, left: xPos }}>
      {menu.map((menuChoice) => (
        <div
          aria-hidden="true"
          className="menu-choice"
          onClick={menuChoice.callback}
          key={menuChoice.text}
        >
          <span className="icon material-icons">{menuChoice.icon}</span>
          <span className="choice">{menuChoice.text}</span>
        </div>
      ))}
    </div>
  );
};

export default ContextMenu;
