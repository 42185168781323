import IAction from "interfaces/IAction";
import ICustomer from "interfaces/ICustomer";
import ICustomerDetails from "interfaces/ICustomerDetails";
import ICustomerFeed from "interfaces/ICustomerFeed";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import IControlParameterSettingsRequest from "interfaces/IControlParameterSettingsRequest";
import IRecentCustomers from "interfaces/IRecentCustomers";
import IFeedMix from "interfaces/IFeedMix";
import IFeedMixItem from "interfaces/IFeedMixUpdateRequest";
import IUser from "pages/choose-customer/interfaces/IUser";
import IUserHiddenColumns, { ITableTypes } from "interfaces/IUserHiddenColumns";

export const ADD_FEED_TO_CURRENT_FEED_MIX_ITEMS = "ADD_FEED_TO_CURRENT_FEED_MIX_ITEMS";
export const ADD_FEED_TO_CURRENT_FEED_PLAN = "ADD_FEED_TO_CURRENT_FEED_PLAN";
export const ADD_USER_HIDDEN_COLUMNS = "ADD_USER_HIDDEN_COLUMNS";
export const REMOVE_FEED_FROM_CURRENT_FEED_MIX_ITEMS = "REMOVE_FEED_TO_CURRENT_FEED_MIX_ITEMS";
export const REMOVE_FEED_FROM_CURRENT_FEED_PLAN = "REMOVE_FEED_FROM_CURRENT_FEED_PLAN";
export const REMOVE_USER_HIDDEN_COLUMNS = "REMOVE_USER_HIDDEN_COLUMNS";
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER";
export const SET_CURRENT_FEED_PLAN = "SET_CURRENT_FEED_PLAN";
export const SET_CURRENT_FEED_PLAN_CONTROL_SETTINGS = "SET_CURRENT_FEED_PLAN_CONTROL_SETTINGS";
export const SET_CURRENT_FEED_MIX = "SET_CURRENT_FEED_MIX";
export const SET_CURRENT_FEED_MIX_FEEDS = "SET_CURRENT_FEED_MIX_FEEDS";
export const SET_CURRENT_FEED_MIX_ITEMS = "SET_CURRENT_FEED_MIX_ITEMS";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const SET_CUSTOMER_FEED_PLANS = "SET_CUSTOMER_FEED_PLANS";
export const SET_FEED_ORDER_FEED_PLAN = "SET_FEED_ORDER_FEED_PLAN";
export const SET_FEED_ORDER_FEED_MIX = "SET_FEED_ORDER_FEED_MIX";
export const SET_READ_ONLY_MODE = "SET_READ_ONLY_MODE";
export const SET_RECENT_CUSTOMERS = "SET_RECENT_CUSTOMERS";
export const SET_USER_RECENT_CUSTOMERS = "SET_USER_RECENT_CUSTOMERS";
export const SET_USER = "SET_USER";
export const SET_USER_HIDDEN_COLUMNS = "SET_USER_HIDDEN_COLUMNS";

export const addFeedToCurrentFeedMixItems = (feed: ICustomerFeed): IAction => ({
  type: ADD_FEED_TO_CURRENT_FEED_MIX_ITEMS,
  payload: feed,
});
export const removeFeedToCurrentFeedMixItems = (feed: ICustomerFeed): IAction => ({
  type: REMOVE_FEED_FROM_CURRENT_FEED_MIX_ITEMS,
  payload: feed,
});

export const addFeedToCurrentFeedPlan = (feed: ICustomerFeed): IAction => ({
  type: ADD_FEED_TO_CURRENT_FEED_PLAN,
  payload: feed,
});

export const removeFeedFromCurrentFeedPlan = (feed: ICustomerFeed): IAction => ({
  type: REMOVE_FEED_FROM_CURRENT_FEED_PLAN,
  payload: feed,
});

export const setFeedOrderFeedPlan = (feed: ICustomerFeed, direction: string): IAction => ({
  type: SET_FEED_ORDER_FEED_PLAN,
  payload: { feed, direction },
});

export const setFeedOrderFeedMix = (feed: IFeedMix, direction: string): IAction => ({
  type: SET_FEED_ORDER_FEED_MIX,
  payload: { feed, direction },
});

export const setCurrentCustomer = (currentCustomer?: ICustomer): IAction => ({
  type: SET_CURRENT_CUSTOMER,
  payload: currentCustomer,
});

export const setCurrentFeedPlan = (currentFeedPlan?: ICustomerFeedPlan): IAction => ({
  type: SET_CURRENT_FEED_PLAN,
  payload: currentFeedPlan,
});

export const setCurrentFeedPlanControlSettings = (
  currentFeedPlanControlSettings?: IControlParameterSettingsRequest[] | undefined
): IAction => ({
  type: SET_CURRENT_FEED_PLAN_CONTROL_SETTINGS,
  payload: currentFeedPlanControlSettings,
});

export const setCurrentFeedMix = (currentFeedMix?: IFeedMix): IAction => ({
  type: SET_CURRENT_FEED_MIX,
  payload: currentFeedMix,
});

export const setCurrentFeedMixFeeds = (currentFeedMixFeeds?: IFeedMix[]): IAction => ({
  type: SET_CURRENT_FEED_MIX_FEEDS,
  payload: currentFeedMixFeeds,
});

export const setCurrentFeedMixItems = (currentFeedMixItems?: IFeedMixItem[]): IAction => ({
  type: SET_CURRENT_FEED_MIX_ITEMS,
  payload: currentFeedMixItems,
});

export const setCustomers = (chooseCustomer?: ICustomer[]): IAction => {
  if (chooseCustomer) {
    return { type: SET_CUSTOMERS, payload: chooseCustomer };
  }
  return { type: SET_CUSTOMERS, payload: undefined };
};

export const setCustomerDetails = (customerDetails?: ICustomerDetails): IAction => ({
  type: SET_CUSTOMER_DETAILS,
  payload: customerDetails,
});

export const setCustomerFeedPlans = (customerFeedPlans: ICustomerFeedPlan[]): IAction => ({
  type: SET_CUSTOMER_FEED_PLANS,
  payload: customerFeedPlans,
});

export const setReadOnlyMode = (value: boolean): IAction => ({
  type: SET_READ_ONLY_MODE,
  payload: value,
});

export const setRecentCustomers = (recentCustomers?: IRecentCustomers[]): IAction => {
  if (recentCustomers) {
    return { type: SET_RECENT_CUSTOMERS, payload: recentCustomers };
  }
  return { type: SET_RECENT_CUSTOMERS, payload: undefined };
};

export const setUserRecentCustomers = (userRecentCustomers?: IRecentCustomers[]): IAction => {
  if (userRecentCustomers) {
    return { type: SET_USER_RECENT_CUSTOMERS, payload: userRecentCustomers };
  }
  return { type: SET_USER_RECENT_CUSTOMERS, payload: undefined };
};

export const setUser = (user: IUser): IAction => ({ type: SET_USER, payload: user });

export const setUserHiddenColumns = (userHiddenColumns: IUserHiddenColumns): IAction => ({
  type: SET_USER_HIDDEN_COLUMNS,
  payload: userHiddenColumns,
});

export const addUserHiddenColumns = (tableType: ITableTypes, index: number): IAction => ({
  type: ADD_USER_HIDDEN_COLUMNS,
  payload: { tableType, index },
});

export const removeUserHiddenColumns = (tableType: ITableTypes, index: number): IAction => ({
  type: REMOVE_USER_HIDDEN_COLUMNS,
  payload: { tableType, index },
});
