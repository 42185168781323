import { useState } from "react";

import useFetch from "hooks/useFetch";

import { BASE_FEED_CALCULATIONS_URL } from "assets/constants";

import IStandardFeed from "interfaces/IStandardFeed";
import ICustomerFeed from "interfaces/ICustomerFeed";

type Feed = IStandardFeed | ICustomerFeed;

function useCalcSFR() {
  /*
   * Since we can calculate both standardFeeds and customerFeeds
   * we need to take into account the unique properties of the
   * customerFeed and add them later to the result since the
   * backend doesen't do that.
   */
  const uniquePropertiesOfCustomerFeed = ["customerId", "created", "isActive", "isMixed"];
  const [status, setStatus] = useState("idle");
  const fetch = useFetch();

  const calc = async (feed: Feed) => {
    // eslint-disable-next-line max-len
    const url = `${BASE_FEED_CALCULATIONS_URL}?calculateLfuParameters=${feed.supportsLfu}&calculateSfrParameters=${feed.supportsSfr}`;

    setStatus("loading");

    const feedCopy = feed;

    // Dates need to be in Iso format.
    let result = await fetch.put(url, { method: "PUT", body: JSON.stringify(feedCopy) });

    if (result.ok) {
      result = await result.json();

      result.updated = new Date().toISOString();

      // We know it is a customerFeed if it got a customerId property, if so we populate
      // the unique properties.
      if (feedCopy.customerId) {
        uniquePropertiesOfCustomerFeed.forEach((key) => {
          if (key !== "created") {
            result[key] = feedCopy[key];
          }
        });
      }
      setStatus("success");
    } else {
      // If something goes wrong with the claculation we send back undefined and let the
      // component handle it.
      setStatus("error");
      return undefined;
    }

    setStatus("idle");
    return result;
  };

  const calcAll = async () => {
    const url = `${BASE_FEED_CALCULATIONS_URL}/standardfeeds`;
    setStatus("loading");

    const result = await fetch.put(url, {
      method: "PUT",
      body: "",
    });

    if (result.ok) {
      const resultFile = await result
        .json()
        .then(
          (res: any) =>
            new Blob([JSON.stringify(res)], {
              type: "text/plain",
            })
        )
        .then((blob: any) => URL.createObjectURL(blob));

      const link = document.createElement("a");
      link.href = resultFile;
      link.download = "Calculated SFR Results.txt";

      setStatus("success");
      return { link, resultFile };
    }
    setStatus("error");
    return undefined;
  };

  return { status, calc, calcAll };
}

export default useCalcSFR;
