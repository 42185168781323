/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import LMButton from "components/lm-button/LMButton";
import LMDropdown from "components/lm-dropdown/LMDropdown";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";

import {
  setReadOnlyMode,
  addUserHiddenColumns,
  removeUserHiddenColumns,
} from "pages/choose-customer/context/customerActions";

import ITableDataRow from "interfaces/ITableDataRow";
import { ITableTypes } from "interfaces/IUserHiddenColumns";

import { IDS_NOT_TO_DISPLAY_IN_TABLES } from "assets/constants";

interface HeaderProps {
  title: string;
  backButton?: boolean;
  hideReadOnly?: boolean;
  showSideMenu?: (val?: boolean) => void;
  hideColumnsDropdown?: ITableDataRow;
  hideColumnsTableType?: ITableTypes;
}

const Header = ({
  title,
  backButton,
  hideReadOnly,
  hideColumnsDropdown,
  hideColumnsTableType,
  showSideMenu,
}: HeaderProps) => {
  const { t } = useTranslation();
  const { t: tWithKeyPrefix } = useTranslation("", {
    keyPrefix: "feedParameters",
  });

  const disabledColumns = ["feedName", "feedGroup", "feedNumber", "isActive"];

  const { goBack } = useHistory();
  const {
    customerState: { readOnlyMode, userHiddenTableColumns },
    customerDispatch,
  } = useContext(CustomerContext);

  const handleOnBack = () => {
    goBack();
  };

  const handleShowSideMenu = useCallback(() => {
    if (showSideMenu) {
      showSideMenu();
    }
  }, [showSideMenu]);

  const handleOnLockClick = () => {
    customerDispatch(setReadOnlyMode(!readOnlyMode));
  };

  const handleCheckboxChange = (index: number) => {
    if (
      userHiddenTableColumns![hideColumnsTableType!].some(
        (existingIndex: number) => existingIndex === index
      )
    ) {
      customerDispatch(removeUserHiddenColumns(hideColumnsTableType!, index));
    } else {
      customerDispatch(addUserHiddenColumns(hideColumnsTableType!, index));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheckboxChange = useCallback(debounce(handleCheckboxChange, 200), [
    userHiddenTableColumns,
  ]);

  const renderColumnsDropdownOptions = (): JSX.Element[] => {
    const filteredColumnsList = Object.keys(hideColumnsDropdown!).filter(
      (column) => !IDS_NOT_TO_DISPLAY_IN_TABLES.some((id) => id === column)
    );

    return filteredColumnsList.map((row, index) => (
      <li key={row}>
        <label
          className="lm__checkbox lm__tick filter-columns-checkbox"
          htmlFor={`column-${index}`}
        >
          <input
            type="checkbox"
            id={`column-${index}`}
            checked={
              !userHiddenTableColumns![hideColumnsTableType!].some(
                (hiddenId: number) => hiddenId === index
              )
            }
            onChange={() => {
              debounceCheckboxChange(index);
            }}
            disabled={disabledColumns.some((x) => x === row)}
          />
          <label />
          <span>{tWithKeyPrefix(row)}</span>
        </label>
      </li>
    ));
  };

  const noColumns = !hideColumnsDropdown || !hideColumnsTableType;
  const readOnlyLockClass = readOnlyMode ? "highlight-bg" : "";

  return (
    <div className="header">
      <h1 className="header-title">{t(title)}</h1>
      {backButton && (
        <LMButton className="back-button" type="button" text={t("back")} click={handleOnBack} />
      )}

      {!hideReadOnly && (
        <div className={`read-only-lock ${readOnlyLockClass}`}>
          <button className="lock-icon" type="button" onClick={handleOnLockClick}>
            {readOnlyMode ? (
              <>
                <span className="read-only-text">{t("readOnly")}</span>
                <span className="material-icons read-only-icon locked">lock</span>
              </>
            ) : (
              <span className="material-icons read-only-icon">lock_open</span>
            )}
          </button>
        </div>
      )}

      {hideColumnsDropdown && (
        <div className="margin-left-5 hide-show-dropdown">
          <LMDropdown multiple placeholder={t("hideShowColumns")}>
            {noColumns ? [<div>Error</div>] : renderColumnsDropdownOptions()}
          </LMDropdown>
        </div>
      )}

      {showSideMenu && (
        // eslint-disable-next-line
        <span className="menu material-icons" data-icon="more_vert" onClick={handleShowSideMenu} />
      )}
    </div>
  );
};
export default Header;
