import { useContext, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import useToggle from "hooks/useToggle";
import { TableDataContext } from "components/table/Table";
import { sortTableDataColumn } from "components/table/context/tableDataActions";

import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";

interface TableHeadProps {
  tableDataHeadColumn: string;
  tableGroupSortHeadProperties?: string[];
  feedMixTableSizing?: number;
  hidden?: boolean;
  onYieldLevelHeadClick?: (header: string) => void;
  columnIndex?: number;
  feedPlan?: ICustomerFeedPlan;
}

const TableHeadData = ({
  tableDataHeadColumn,
  tableGroupSortHeadProperties,
  feedMixTableSizing,
  hidden,
  onYieldLevelHeadClick,
  columnIndex,
  feedPlan,
}: TableHeadProps): JSX.Element => {
  const { dispatch, tableDataKeyPrefix } = useContext(TableDataContext);
  const { t: tWithKeyPrefix } = useTranslation("", { keyPrefix: tableDataKeyPrefix });
  const [ascending, toggleAscending] = useToggle(true);

  // This removes any existing # in the tableDataHeadColumn, why? Since
  // number-like-keys always comes first in an object, # has been injected
  // between the numbers in order to make them NOT-number-like. Then they
  // can be sorted much easier.
  const content = useRef(tableDataHeadColumn.replace(/#/g, ""));

  const handleOnClick: React.MouseEventHandler<HTMLSpanElement> = async () => {
    if (tableDataKeyPrefix === "feedDietData" && onYieldLevelHeadClick) {
      onYieldLevelHeadClick(tableDataHeadColumn);
    } else {
      dispatch(sortTableDataColumn(tableDataHeadColumn, ascending, tableGroupSortHeadProperties));
      toggleAscending();
    }
  };

  const shouldThisBeTranslated = useCallback((): boolean => {
    if (content.current.includes("kg") || content.current.includes("mo")) {
      return true;
    }
    return Number.isNaN(parseInt(content.current));
  }, []);

  const getDisplayDataForAnimalYield = () => {
    let yieldContent: string = "";
    const YIELD_LEVEL_WEIGHT_CONSTANT = 0;
    const YIELD_LEVEL_GROWTH_CONSTANT = 1;
    if (columnIndex) {
      const columnIndexPosition: number =
        tableDataKeyPrefix === "feedDietData" ? columnIndex - 1 : columnIndex;
      feedPlan?.yieldLevels.forEach((item: any) => {
        if (feedPlan.animalType.name === "heifer24m" || feedPlan.animalType.name === "heifer27m") {
          if (columnIndexPosition === item.level) {
            yieldContent = `${item.values[YIELD_LEVEL_WEIGHT_CONSTANT].value} kg`;
          }
        } else if (
          feedPlan.animalType.name === "dairySteerIntense16m" ||
          feedPlan.animalType.name === "dairySteerExtensive19m" ||
          feedPlan.animalType.name === "lightBeefSteer16m" ||
          feedPlan.animalType.name === "heavyBeefSteer15m"
        ) {
          if (columnIndexPosition === item.level) {
            yieldContent = `${item.values[YIELD_LEVEL_GROWTH_CONSTANT].value} g`;
          }
        }
      });
    }
    return yieldContent ? <span className="inside-content">{yieldContent}</span> : "";
  };

  return (
    <th
      className={`table-head ${hidden ? "hidden" : ""}`}
      onClick={handleOnClick}
      style={{
        minWidth: feedMixTableSizing && tableDataKeyPrefix ? `${feedMixTableSizing}ch` : "",
      }}
    >
      {content.current.includes("-") ||
      content.current.includes("kg") ||
      content.current.includes("farOff8-3w") ||
      content.current.includes("closeUp3-0w") ? (
        <span className="content">{tWithKeyPrefix(content.current)}</span>
      ) : (
        <span className="content">
          {shouldThisBeTranslated()
            ? tWithKeyPrefix(content.current)
            : parseInt(content.current).toFixed(0)}
        </span>
      )}
      {feedPlan ? getDisplayDataForAnimalYield() : ""}
    </th>
  );
};

export default TableHeadData;
