import { useRef } from "react";
import { useTranslation } from "react-i18next";

import buttons from "pages/customer-feeds/json/saveModalContentButtons.json";

import LMButton from "components/lm-button/LMButton";

interface ISaveModalContentButton {
  id: number;
  disabled: boolean;
  purpose: string;
  text: string;
  type: string;
}

interface SaveModalContentProps {
  copyFeed?: any;
  closeModal?: any;
}

const CopyAsNewModalContent = ({ copyFeed, closeModal }: SaveModalContentProps): JSX.Element => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const copyFeedPlan = () => {
    if (inputRef.current) {
      copyFeed(inputRef.current.value);
      closeModal();
    }
  };

  const handleOnClick = (button: ISaveModalContentButton) => {
    switch (button.purpose) {
      case "saveFeedNameForNewFeed":
        return copyFeedPlan();
      case "hideModalAndCancelOperation":
        return closeModal();
      default:
        break;
    }
  };

  return (
    <div>
      <input ref={inputRef} type="text" placeholder="Enter Feed Name" />
      {buttons.map((button) => (
        <LMButton
          key={button.id}
          click={() => handleOnClick(button)}
          disabled={button.disabled ? true : undefined}
          text={t(button.text)}
          type="button"
          className={button.text === "cancel" ? "cancelButton" : "saveButton"}
        />
      ))}
    </div>
  );
};

export default CopyAsNewModalContent;
