import { useContext, useRef, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useSaveFeedRequest from "hooks/useSaveFeedRequest";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";
import {
  addFeedToCurrentFeedMixItems,
  removeFeedToCurrentFeedMixItems,
  setCurrentFeedMix,
  setFeedOrderFeedMix,
} from "pages/choose-customer/context/customerActions";

import typeSpecs from "pages/customer-feeds/json/typeSpecsOfCustomerFeed.json";

import LMButton from "components/lm-button/LMButton";
import AddRemoveFeeds from "components/add-remove-feeds/AddRemoveFeeds";

import ITypeSpec from "interfaces/ITypeSpec";
import ICustomerFeed from "interfaces/ICustomerFeed";
import { IFeedMixItem } from "interfaces/IFeedMixUpdateRequest";

interface FeedMixAddRemoveProps {
  isLoading: boolean;
  toggleUnsavedFeedMix: (val?: boolean) => void;
}

const FeedMixAddRemove = ({
  isLoading,
  toggleUnsavedFeedMix,
}: FeedMixAddRemoveProps): JSX.Element => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const {
    customerFeedsState: { feeds },
  } = useContext(CustomerFeedsContext);
  const {
    customerState: { currentCustomer, currentFeedMix },
    customerDispatch,
  } = useContext(CustomerContext);

  const { updateFeedMixItemsInDatabase } = useSaveFeedRequest();
  const hasCurrentFeedMixItemsBeenModified = useRef(false);
  const [selectedCustomerFeed, setSelectedCustomerFeed] = useState<ICustomerFeed>();
  const [selectedMix, setSelectedMix] = useState<ICustomerFeed>();
  const [filteredFeeds, setFilteredFeeds] = useState<ICustomerFeed[]>([]);
  const [searchInput, setSearchInput] = useState<string>();
  const customerFeedsTypeSpecs = useRef<ITypeSpec>(typeSpecs);
  const CustomerFeedPropsToDisplay = ["feedGroup", "feedName", "feedNumber", "id"];

  const handleOnClickDone = async () => {
    if (hasCurrentFeedMixItemsBeenModified.current && currentFeedMix && currentFeedMix.feeds) {
      if (currentFeedMix && currentFeedMix.feeds) {
        const body: IFeedMixItem[] = currentFeedMix.feeds.map(
          (feed: ICustomerFeed): IFeedMixItem => {
            const currFeedMixItem = currentFeedMix?.feedMixItems?.find(
              (item) => item.customerFeedId === feed.id
            );
            if (feed.id) {
              return {
                customerFeedId: feed.id,
                shareInPercDm: currFeedMixItem?.shareInPercDm ? currFeedMixItem?.shareInPercDm : 0,
                shareInPercKg: currFeedMixItem?.shareInPercKg ? currFeedMixItem?.shareInPercKg : 0,
                amountKg: currFeedMixItem?.amountKg ? currFeedMixItem?.amountKg : 0,
                amountKgDm: currFeedMixItem?.amountKgDm ? currFeedMixItem?.amountKgDm : 0,
                sortIndex: currFeedMixItem?.sortIndex ? currFeedMixItem?.sortIndex : 0,
              };
            }
            return {} as unknown as IFeedMixItem;
          }
        );

        const res = await updateFeedMixItemsInDatabase(
          {
            feedMixItems: body,
          },
          currentFeedMix.id,
          true
        );
        toggleUnsavedFeedMix(false);
        customerDispatch(setCurrentFeedMix({ ...res, ...res?.summary }));
        return goBack();
      }
    }
    return goBack();
  };

  const filterCustomerFeeds = (): ICustomerFeed[] => {
    if (feeds && currentFeedMix) {
      return feeds.filter(
        (feed) =>
          feed.id !== currentFeedMix.id &&
          !currentFeedMix?.feeds?.some((_feed) => _feed.id === feed.id)
      );
    }
    return feeds;
  };

  const onCustomerFeedSlection = (selectedRow: any) => {
    const selectedFeed = feeds.find((feed) => feed.id === selectedRow.id);
    setSelectedCustomerFeed(selectedFeed);
  };

  const onMixFeedSlection = (selectedRow: any) => {
    const selectedFeed = feeds.find((feed) => feed.id === selectedRow.id);
    setSelectedMix(selectedFeed);
  };

  const handleFeedFilter = (value: string) => {
    const filteredCustomerFeeds = filterCustomerFeeds().filter((_feed) =>
      _feed.feedName?.toLowerCase().includes(value.toLocaleLowerCase())
    );

    setSearchInput(value);
    if (!filteredCustomerFeeds) {
      setFilteredFeeds([] as ICustomerFeed[]);
    } else {
      setFilteredFeeds(filteredCustomerFeeds);
    }
  };

  const moveCustomerFeedToMix = () => {
    if (selectedCustomerFeed) {
      customerDispatch(addFeedToCurrentFeedMixItems(selectedCustomerFeed));
      setSelectedMix(undefined);
      if (!hasCurrentFeedMixItemsBeenModified.current) {
        hasCurrentFeedMixItemsBeenModified.current = true;
      }

      if (searchInput) {
        handleFeedFilter(searchInput);
      }
    }
  };

  const moveMixToCustomerFeed = () => {
    if (selectedMix) {
      customerDispatch(removeFeedToCurrentFeedMixItems(selectedMix));
      setSelectedCustomerFeed(undefined);
      if (!hasCurrentFeedMixItemsBeenModified.current) {
        hasCurrentFeedMixItemsBeenModified.current = true;
      }
      if (searchInput) {
        handleFeedFilter(searchInput);
      }
    }
  };

  /**
   * Checks if clicked element is first or last element in table.
   * We can't move the item in the table up if it's already first.
   * Same goes for the last item but downwards.
   */
  const handleOnSortClick = useCallback(
    (direction: string, row) => {
      const items = currentFeedMix?.feeds ? currentFeedMix?.feeds : [];

      const isFirstElement = items[0].id === row.id;
      const isLastElement = items[items.length - 1].id === row.id;

      if (
        !(isFirstElement && direction === "arrow_drop_up") &&
        !(isLastElement && direction === "arrow_drop_down")
      ) {
        hasCurrentFeedMixItemsBeenModified.current = true;
        return customerDispatch(setFeedOrderFeedMix(row, direction));
      }
    },
    [customerDispatch, currentFeedMix]
  );

  /**
   * @description Sorts feeds based on sortIndex parameter. If present in currentFeedPlanFeeds
   * it sorts from there, if not it sorts from currentFeedPlan.feedDietItems
   * @param currentFeedPlanFeeds
   * @returns Feeds sorted on sortIndex
   */
  const sortLeftSideData = useCallback(
    (currentFeedMixFeeds: ICustomerFeed[]): ICustomerFeed[] => {
      if (!currentFeedMix) {
        return [] as ICustomerFeed[];
      }

      return currentFeedMixFeeds.sort((a, b) => {
        const matchingFeedDietItemA = currentFeedMix.feedMixItems!.find(
          (feedMixItem) => feedMixItem.customerFeedId === a.id
        );
        const matchingFeedDietItemB = currentFeedMix.feedMixItems!.find(
          (feedMixItem) => feedMixItem.customerFeedId === b.id
        );

        if (matchingFeedDietItemA?.sortIndex! > matchingFeedDietItemB?.sortIndex!) {
          return 1;
        }
        if (matchingFeedDietItemA?.sortIndex! < matchingFeedDietItemB?.sortIndex!) {
          return -1;
        }
        return 0;
      });
    },
    [currentFeedMix]
  );

  /**
   * Checks if double clicked row exists in feedmix feeds.
   * Removes that feed from the feedmix
   */
  const handleOnDoubleClickLeft = useCallback(
    (row: any) => {
      const selectedFeed = currentFeedMix?.feeds?.find((feed) => feed.id === row.id);

      if (selectedFeed) {
        customerDispatch(removeFeedToCurrentFeedMixItems(selectedFeed));
        setSelectedMix(undefined);
        if (!hasCurrentFeedMixItemsBeenModified.current) {
          hasCurrentFeedMixItemsBeenModified.current = true;
        }
      }

      if (searchInput) {
        handleFeedFilter(searchInput);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFeedMix?.feeds, hasCurrentFeedMixItemsBeenModified.current]
  );

  /**
   * Checks if double clicked row exists in customers feeds.
   * Add that feed to the feedmix
   */
  const handleOnDoubleClickRight = useCallback(
    (row: any) => {
      const selectedFeed = feeds.find((feed) => feed.id === row.id);

      if (selectedFeed) {
        customerDispatch(addFeedToCurrentFeedMixItems(selectedFeed));
        setSelectedMix(undefined);

        if (!hasCurrentFeedMixItemsBeenModified.current) {
          hasCurrentFeedMixItemsBeenModified.current = true;
        }
      }
      if (searchInput) {
        handleFeedFilter(searchInput);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feeds, hasCurrentFeedMixItemsBeenModified.current]
  );

  const rightSideData = searchInput ? filteredFeeds : filterCustomerFeeds();

  return (
    <div className="add-remove-wrapper">
      <div className="sub-header">
        <h3 className="customer-name">
          {currentCustomer && currentCustomer.name
            ? currentCustomer.name
            : currentCustomer?.fullName1}
        </h3>
        <div className="button-wrapper">
          <LMButton text={t("done")} type="button" click={handleOnClickDone} />
        </div>
      </div>
      <AddRemoveFeeds
        rightSidePropsToDisplay={CustomerFeedPropsToDisplay}
        rightSideName={t("customerFeeds")}
        rightSideData={rightSideData}
        rightSideTypeSpecs={customerFeedsTypeSpecs.current}
        isRightSideLoading={isLoading}
        onRightSideSelection={onCustomerFeedSlection}
        onRightButtonClick={moveMixToCustomerFeed}
        leftSidePropsToDisplay={CustomerFeedPropsToDisplay}
        leftSideName={t("feedMix")}
        leftSideData={currentFeedMix?.feeds ? sortLeftSideData(currentFeedMix?.feeds) : []}
        leftSideTypeSpecs={customerFeedsTypeSpecs.current}
        isLeftSideLoading={false}
        onLeftSideSelection={onMixFeedSlection}
        onLeftButtonClick={moveCustomerFeedToMix}
        sortArrows
        onTableSort={handleOnSortClick}
        onDoubleClickLeft={handleOnDoubleClickLeft}
        onDoubleClickRight={handleOnDoubleClickRight}
        handleFeedFilter={handleFeedFilter}
      />
    </div>
  );
};

export default FeedMixAddRemove;
