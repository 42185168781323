interface BasePageProps {
  children: React.ReactNode;
  pageName: string;
}

const BasePage = ({ children, pageName }: BasePageProps): JSX.Element => (
  <div className="base-page tab-navigation__tab-content-container lm__main-content">
    <div className="lm__block">
      <div className="layout layout--large lm__main-content">
        <div className="layout__item u-1/1">
          <div className={`${pageName} lm__block lm__block-contain-float`}>{children}</div>
        </div>
      </div>
    </div>
  </div>
);
export default BasePage;
