/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { Route, Switch, useHistory, useParams, useRouteMatch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useErrorHandler } from "react-error-boundary";
import { debounce } from "lodash";

import { LMString } from "types";

import useFeedHandler from "hooks/useFeedHandler";
import useToggle from "hooks/useToggle";
import useFetch from "hooks/useFetch";
import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useModal from "hooks/useModal";
import useTableHeaderSorter from "hooks/useTableHeaderSorter";
import useOnPageLeave from "hooks/useOnPageLeave";

import BasePage from "components/base-page/BasePage";
import Header from "components/header/Header";
import Modal from "components/modal/Modal";
import SideMenu from "components/sidemenu/SideMenu";

import ModalContent from "pages/customer-feeds/parts/CopyAsNewModalContent";
import FeedMixOverview from "pages/feed-mix/parts/FeedMixOverview";
import FeedMixAddRemove from "pages/feed-mix/parts/FeedMixAddRemove";
import FeedMixCreateNew from "pages/feed-mix/parts/FeedMixCreateNew";

import { setCustomerFeeds } from "pages/customer-feeds/context/customerFeedsActions";
import {
  setCurrentFeedMix,
  setCurrentFeedMixItems,
  setCustomerDetails,
  setCurrentCustomer,
} from "pages/choose-customer/context/customerActions";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";
import { BASE_CUSTOMER_FEEDS_URL, BASE_FEED_MIX_URL } from "assets/constants";
import PrintPage from "components/print-page/PrintPage";

/**
 * @description Page: Wraps all Feed Mix components.
 * Handles which Feed mix is selected based on URL. Detects if selected
 * Feed Mix has been edited and handles "Save"-prompt accordingly.
 * @returns List of Feed mixes, Selected Feed mix, Feed mix Print page,
 * Feed mix Add Remove or Feed mix create new
 */

const FeedMixPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { get } = useFetch();
  const { feedNumber } = useParams<{ feedNumber: string }>();
  const history = useHistory();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const handleError = useErrorHandler();
  const modalHandler = useModal();
  const shouldFetchCustomerFeeds = useRef(true);
  const [unsavedFeedMix, setUnsavedFeedMix] = useState(false);
  const [isFetching, toggleIsFetching] = useToggle(false);

  const { sortPropertiesOfFeedMix } = useTableHeaderSorter();
  const [showMenu, toggleMenu] = useToggle(false);

  const { copyFeedMixToDatabase } = useSaveFeedRequest();

  const {
    customerFeedsState: { feeds },
    customerFeedsState,
    customerFeedsDispatch,
  } = useContext(CustomerFeedsContext);

  const {
    customerState: { currentCustomer, currentFeedMix },
    customerDispatch,
  } = useContext(CustomerContext);

  const { getFeeds, isLoading } = useFeedHandler(customerFeedsState);

  /**
   * @description Fetches all feeds from customer and stores in state for child-components to use.
   * Only needs to run if feeds from customer isn't already in state.
   * (eg. A user has customer X selected and clicks a link to a Feed mix of customer Y)
   * @param {LMString} customerNotSelectedId
   */
  const fetchCustomerFeeds = async (customerNotSelectedId?: LMString) => {
    const url = customerNotSelectedId
      ? `${BASE_CUSTOMER_FEEDS_URL}?customerId=${customerNotSelectedId}`
      : ` ${BASE_CUSTOMER_FEEDS_URL}?customerId=${currentCustomer?.customerId}`;

    customerFeedsDispatch(setCustomerFeeds(await getFeeds(url)));
  };

  /**
   * @description Fetches Feed mix based on feed number in URL and saves in state.
   * If receiving error from fetch request we go back to previous URL and throw error.
   * If the fetched Feed mix is not from current selected customer we fetch feeds from new customer
   * @param {boolean} customerNotSelected
   */
  const fetchCurrentFeedMix = async (customerNotSelected?: boolean) => {
    if (feedNumber && feedNumber !== "create-feed-mix") {
      const feedMixDetails = await get(`${BASE_FEED_MIX_URL}/${feedNumber}`);
      if (feedMixDetails.errorMessage) {
        if (pathname.includes("/feed-mix")) {
          goBack();
        }
        handleError(new Error(feedMixDetails.errorMessage));
      } else {
        customerDispatch(setCurrentFeedMix({ ...feedMixDetails, ...feedMixDetails.summary }));
        customerDispatch(setCurrentFeedMixItems(feedMixDetails.feedMixItems));
        if (customerNotSelected) {
          customerDispatch(setCustomerDetails());
          customerDispatch(setCurrentCustomer(feedMixDetails.customer));
          await fetchCustomerFeeds(feedMixDetails.customer.customerId);
        }
      }
    }
  };

  /**
   * @description Runs everytime currentCustomer updates.
   * If no customer we prompt user that chosing customer is required.
   * If no feeds is saved in state & the feeds has not been fetched before -
   * we run fetchCustomerFeeds.
   */
  useEffect(() => {
    if (currentCustomer && feeds.length === 0 && shouldFetchCustomerFeeds.current) {
      fetchCustomerFeeds();
      shouldFetchCustomerFeeds.current = false;
    }
  }, [currentCustomer]);

  /**
   * @description Debounced function that runs fetchCurrentFeedMix.
   * @param {boolean} customerNotSelected
   */
  const debounceFetchCurrentFeedMix = useCallback(
    debounce(async (customerNotSelected?: boolean) => {
      fetchCurrentFeedMix(customerNotSelected);
      toggleIsFetching(false);
    }, 200),
    [pathname]
  );

  /**
   * @description Runs everytime feed number changes in URL.
   * Check if feed number exists in current selected customer feeds.
   * If we aren't already fetching a Feed Mix we run debounceFetchCurrentFeedMix
   * with customerNotSelected variable as true if the feed number doesn't exist in
   * current selected customer feeds.
   */
  useEffect(() => {
    if (!isFetching) {
      toggleIsFetching(true);
    }
    const parsedFeedNumber = Number(feedNumber);
    if (!feeds.some((feed) => feed.id === parsedFeedNumber)) {
      debounceFetchCurrentFeedMix(true);
    } else {
      debounceFetchCurrentFeedMix();
    }
  }, [feedNumber]);

  /**
   * @description Function passed as prop to FeedMixOverview & FeedMixAddRemove
   * Runs whenever the Feed mix is changed in these child components. Sets unsavedFeedMix-state
   * to value if that exists otherwise it sets it to true.
   * @param {boolean} value
   */
  const handleOnFeedMixChange = async (value?: boolean) => {
    setUnsavedFeedMix(value !== undefined ? value : true);
  };

  useOnPageLeave(unsavedFeedMix);

  /**
   * @description Runs fetchCurrentFeedMix when user wants to remove unwanted changes.
   */
  const onResetChanges = useCallback(async () => {
    await fetchCurrentFeedMix();
    setUnsavedFeedMix(false);
  }, [currentFeedMix]);

  const shouldShowSideMenu =
    !pathname.endsWith("add-remove") &&
    !pathname.endsWith("print") &&
    !pathname.endsWith("create-feed-mix")
      ? toggleMenu
      : undefined;

  const feedMixHiddenColumns =
    currentFeedMix &&
    !pathname.endsWith("add-remove") &&
    !pathname.endsWith("print") &&
    !pathname.endsWith("create-feed-mix") &&
    !pathname.endsWith("feed-mix")
      ? sortPropertiesOfFeedMix([currentFeedMix])[0]
      : undefined;

  return (
    <BasePage pageName="feed-mix">
      {currentCustomer ? (
        <>
          <Header
            title="feedMix"
            showSideMenu={shouldShowSideMenu}
            hideReadOnly={
              !currentFeedMix || pathname.endsWith("print") || pathname.endsWith("create-feed-mix")
            }
            hideColumnsDropdown={feedMixHiddenColumns}
            hideColumnsTableType="feedMixColumns"
          />
          {showMenu && (
            <SideMenu
              type="feed-mix"
              toggleMenu={toggleMenu}
              unsavedProps={{ unsavedFeedMix }}
              callbackProps={{ setUnsavedFeedMix, toggleModal: modalHandler.toggleState }}
            />
          )}

          <Modal
            headerText="copyAsNew"
            show={modalHandler.toggleModal}
            hide={modalHandler.toggleState}
          >
            <ModalContent
              copyFeed={async (newFeedMixName: string) => {
                if (currentFeedMix?.id && currentFeedMix?.feedNumber && newFeedMixName) {
                  const result = await copyFeedMixToDatabase(
                    currentFeedMix.id,
                    newFeedMixName,
                    currentFeedMix.feedNumber
                  );

                  customerDispatch(setCurrentFeedMix({ ...result, ...result?.summary }));
                  return history.push(`/feed-mix/${result?.id}`);
                }
              }}
              closeModal={modalHandler.toggleState}
            />
          </Modal>

          <Switch>
            <Route path="/feed-mix/create-feed-mix/:yieldLevel?">
              <FeedMixCreateNew />
            </Route>
            <Route exact path={path}>
              <FeedMixOverview
                toggleUnsavedFeedMix={handleOnFeedMixChange}
                unsavedFeedMix={unsavedFeedMix}
                onResetChanges={onResetChanges}
              />
            </Route>

            <Route path={`${path}/${feedNumber}/add-remove`}>
              <FeedMixAddRemove
                isLoading={isLoading}
                toggleUnsavedFeedMix={handleOnFeedMixChange}
              />
            </Route>

            <Route path={`${path}/${feedNumber}/print`}>
              <PrintPage type="feedMix" />
            </Route>
          </Switch>
        </>
      ) : (
        <h2 className="text-center">{t("customerOverviewNoCustomerSelected")}</h2>
      )}
    </BasePage>
  );
};

export default withAuthenticationRequired(FeedMixPage);
