import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useConfirm from "hooks/useConfirm";

interface UseSaveBeforeRouteChangeFeedPlan {
  when: boolean;
  callback: () => void;
  resetChanges?: () => void;
}

/**
 * @description Hook that triggers before user changes route.
 * Prompts user with option to save or reset unsaved changes
 * before proceeding to the new route.
 *
 * If user confirms prompt the callback function will be run,
 * if the user discards changes the resetChanges function will run.
 *
 * Different between this hook and useSaveBeforeRouteChangeFeed is
 * only in dependency of useEffect. FeedPlanOverview can't use "when" as
 * dependency in useEffect to work properly.
 */
const useSaveBeforeRouteChangeFeedPlan = ({
  when,
  callback,
  resetChanges,
}: UseSaveBeforeRouteChangeFeedPlan) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isConfirmed } = useConfirm();
  const historyRef = useRef<() => void>();

  const handleConfirm = async (pathname: string) => {
    const confirm = await isConfirmed(t("unsavedChanges"), "reset", resetChanges);
    if (confirm) {
      if (historyRef && historyRef.current) {
        historyRef.current();
      }
      await callback();
      return history.push(pathname);
    }
  };

  useEffect(() => {
    if (when) {
      historyRef.current = history.block((location) => {
        handleConfirm(location.pathname);
        return "true";
      });
    }
    return () => {
      if (typeof historyRef.current === "function") {
        historyRef.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
};

export default useSaveBeforeRouteChangeFeedPlan;
