import IAction from "interfaces/IAction";
import IFeedsState from "interfaces/IFeedsState";
import IStandardFeed from "interfaces/IStandardFeed";

// Action types
export const ADD_NEW_STANDARD_FEED = "ADD_NEW_STANDARD_FEED";
export const ADD_STANDARD_FEED_TO_STANDARD_FEEDS_TO_UPDATE =
  "ADD_STANDARD_FEED_TO_STANDARD_FEEDS_TO_UPDATE";
export const CLEAR_SELECTED_STANDARD_FEED = "CLEAR_SELECTED_STANDARD_FEED";
export const CLEAR_STANDARD_FEEDS = "CLEAR_STANDARD_FEEDS";
export const COPY_SELECTED_STANDARD_FEED = "COPY_SELECTED_STANDARD_FEED";
export const EDIT_STANDARD_FEEDS_TO_ADD = "EDIT_STANDARD_FEEDS_TO_ADD";
export const REMOVE_STANDARD_FEED = "REMOVE_STANDARD_FEED";
export const SET_STANDARD_FEEDS = "SET_STANDARD_FEEDS";
export const UPDATE_SELECTED_STANDARD_FEED = "UPDATE_SELECTED_STANDARD_FEED";
export const UPDATE_STANDARD_FEED = "UPDATE_STANDARD_FEED";
export const UPDATE_STANDARD_FEEDS = "UPDATE_STANDARD_FEEDS";
export const UPDATE_STANDARD_FEEDS_AFTER_CALCULATION = "UPDATE_STANDARD_FEEDS_AFTER_CALCULATION";

// Action creators
export const addNewStandardFeed = (newState: IFeedsState<IStandardFeed>): IAction => ({
  type: ADD_NEW_STANDARD_FEED,
  payload: newState,
});

export const addStandardFeedToStandardFeedsToUpdate = (
  newState: IFeedsState<IStandardFeed>
): IAction => ({ type: ADD_STANDARD_FEED_TO_STANDARD_FEEDS_TO_UPDATE, payload: newState });

export const clearSelectedStandardFeed = (): IAction => ({ type: CLEAR_SELECTED_STANDARD_FEED });

export const clearStandardFeeds = (newState: IFeedsState<IStandardFeed>): IAction => ({
  type: CLEAR_STANDARD_FEEDS,
  payload: newState,
});

export const copySelectedStandardFeed = (newState: IFeedsState<IStandardFeed>): IAction => ({
  type: COPY_SELECTED_STANDARD_FEED,
  payload: newState,
});

export const editStandardFeedsToAdd = (newState: IFeedsState<IStandardFeed>): IAction => ({
  type: EDIT_STANDARD_FEEDS_TO_ADD,
  payload: newState,
});

export const removeStandardFeed = (newState: IFeedsState<IStandardFeed>): IAction => ({
  type: REMOVE_STANDARD_FEED,
  payload: newState,
});

export const setStandardFeeds = (newState: IFeedsState<IStandardFeed>): IAction => ({
  type: SET_STANDARD_FEEDS,
  payload: newState,
});

export const updateSelectedStandardFeed = (feed?: IStandardFeed): IAction => ({
  type: UPDATE_SELECTED_STANDARD_FEED,
  payload: feed,
});

export const updateStandardFeed = (updatedStandardFeed: IStandardFeed, index: number): IAction => ({
  type: UPDATE_STANDARD_FEED,
  payload: { updatedStandardFeed, index },
});

export const updateStandardFeeds = (feeds: IStandardFeed[]): IAction => ({
  type: UPDATE_STANDARD_FEEDS,
  payload: feeds,
});

export const updateStandardFeedsAfterCalculation = (
  feeds: IStandardFeed[],
  sortOrder: any
): IAction => ({
  type: UPDATE_STANDARD_FEEDS_AFTER_CALCULATION,
  payload: { feeds, sortOrder },
});
