import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import buttons from "components/modal/json/saveModalContentButtons.json";

import LMButton from "components/lm-button/LMButton";

interface ISaveModalContentButton {
  id: number;
  disabled: boolean;
  purpose: string;
  text: string;
  type: string;
}

interface ISaveModalContentProps {
  contentText?: string;
  proceed?: any;
  cancel?: any;
  type?: string;
  resetChanges?: any;
}

const SaveAlertModal = ({
  contentText,
  proceed,
  cancel,
  type,
  resetChanges,
}: ISaveModalContentProps): JSX.Element => {
  const { t } = useTranslation();
  const performOperation = useCallback(() => {
    proceed();
  }, [proceed]);

  const resetChangesOperation = useCallback(async () => {
    await resetChanges();
    cancel();
  }, [resetChanges, cancel]);

  const handleOnClick = useCallback(
    (button: ISaveModalContentButton) => {
      switch (button.purpose) {
        case "performOperation":
          return performOperation();
        case "cancelOperation":
          return resetChangesOperation();
        default:
          // eslint-disable-next-line
          return;
      }
    },
    [performOperation, resetChangesOperation]
  );

  const renderButtons = useMemo(
    (): JSX.Element[] =>
      buttons.map((button, index) => (
        <div key={button.id}>
          {((type && type === "alert" && index === 0) || type !== "alert") && (
            <LMButton
              key={button.id}
              click={() => handleOnClick(button)}
              disabled={button.disabled ? true : undefined}
              text={t(button.text)}
              type="button"
              className={button.text === "resetChanges" ? "cancelButton" : "saveButton"}
            />
          )}
        </div>
      )),
    [handleOnClick, t, type]
  );

  return (
    <div>
      <p className="text-center">{contentText}</p>
      <div className="button-wrapper">{renderButtons}</div>
    </div>
  );
};

export default SaveAlertModal;
