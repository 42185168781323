/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Table from "components/table/Table";
import SwitchButtons from "components/add-remove-feeds/parts/SwitchButtons";
import Loader from "components/loader/Loader";

import useToggle from "hooks/useToggle";

import ITypeSpec from "interfaces/ITypeSpec";
import ITableDataRow from "interfaces/ITableDataRow";

interface AddRemoveFeedsProps {
  leftSidePropsToDisplay?: string[];
  leftSideName: string;
  leftSideData: any[];
  leftSideTypeSpecs: ITypeSpec;
  onLeftSideSelection: (selectedRow: any) => void;
  onLeftButtonClick: () => void;
  isLeftSideLoading?: boolean;
  rightSidePropsToDisplay?: string[];
  rightSideName: string;
  rightSideData: any[];
  rightSideTypeSpecs: ITypeSpec;
  onRightSideSelection: (selectedRow: any) => void;
  onRightButtonClick: () => void;
  isRightSideLoading?: boolean;
  sortArrows?: boolean;
  onTableSort?: (direction: string, row: any) => void;
  onDoubleClickLeft?: (row: any) => void;
  onDoubleClickRight?: (row: any) => void;
  handleFeedFilter?: (value: string) => void;
}

const AddRemoveFeeds = ({
  leftSidePropsToDisplay,
  leftSideName,
  leftSideData,
  leftSideTypeSpecs,
  onLeftSideSelection,
  onLeftButtonClick,
  isLeftSideLoading,
  rightSidePropsToDisplay,
  rightSideName,
  rightSideData,
  rightSideTypeSpecs,
  onRightSideSelection,
  onRightButtonClick,
  isRightSideLoading,
  sortArrows,
  onTableSort,
  onDoubleClickLeft,
  onDoubleClickRight,
  handleFeedFilter,
}: AddRemoveFeedsProps): JSX.Element => {
  const { t } = useTranslation();
  const [disableRight, toggleDisableRight] = useToggle(true);
  const [disableLeft, toggleDisableLeft] = useToggle(true);

  const makeDataNonEditable = (typeSpecs: ITypeSpec): ITypeSpec => {
    const typeSpecsCopy = JSON.parse(JSON.stringify(typeSpecs));
    for (const key in typeSpecsCopy) {
      typeSpecsCopy[key].editable = false;
    }

    return typeSpecsCopy;
  };

  const handleOnLeftSideSelection = useCallback(
    (selectedRow: any) => {
      if (selectedRow) {
        onLeftSideSelection(selectedRow);
        toggleDisableRight(false);
      } else {
        toggleDisableRight(true);
      }
    },
    [onLeftSideSelection, toggleDisableRight]
  );

  const handleOnRightSideSelection = useCallback(
    (selectedRow: any) => {
      if (selectedRow) {
        onRightSideSelection(selectedRow);
        toggleDisableLeft(false);
      } else {
        toggleDisableLeft(true);
      }
    },
    [onRightSideSelection, toggleDisableLeft]
  );

  const handleOnRightButtonClick = useCallback(() => {
    onRightButtonClick();
    toggleDisableRight(true);
  }, [onRightButtonClick, toggleDisableRight]);

  const handleOnLeftButtonClick = useCallback(() => {
    onLeftButtonClick();
    toggleDisableLeft(true);
  }, [onLeftButtonClick, toggleDisableLeft]);

  const trimData = (
    data: ITableDataRow[],
    propertiesToDisplay?: string[],
    useDynamicSort: boolean = false,
    showSortArrows: boolean = false
  ): ITableDataRow[] => {
    let trimmedData: ITableDataRow[] = [];

    if (!propertiesToDisplay) {
      return data;
    }

    trimmedData = data.map((element) => {
      const trimmedElement: ITableDataRow = {};

      for (const key in element) {
        if (propertiesToDisplay.includes(key)) {
          trimmedElement[key] = element[key];
        }
      }

      if (showSortArrows) {
        trimmedElement.sortUp = "sortUp";
        trimmedElement.sortDown = "sortDown";
      }

      return Object.keys(trimmedElement).reduce((result: ITableDataRow, key: string) => {
        const resCopy = result;
        resCopy[key] = trimmedElement[key];
        return resCopy;
      }, {} as ITableDataRow);
    });

    if (useDynamicSort) {
      // I take the first property in the displayPropertiesArray and use that for sorting.
      // Something to think about when we construct the props to this component.
      return _.orderBy(trimmedData, ["feedGroup", "feedNumber"], ["asc", "asc"]);
    }

    return trimmedData;
  };

  return (
    <div className="add-remove-feeds">
      <h3 className="left-name">{leftSideName}</h3>
      <div className="left-side">
        {leftSideData.length ? (
          <Table
            data={trimData(leftSideData, leftSidePropsToDisplay, false, sortArrows)}
            typeSpecs={makeDataNonEditable(leftSideTypeSpecs)}
            keyPrefix="feedParameters"
            onSelectedRow={handleOnLeftSideSelection}
            tableSortOrder={onTableSort}
            onDoubleClickLeft={onDoubleClickLeft}
          />
        ) : (
          <>
            {isLeftSideLoading && (
              <div>
                <Loader size="small" />
                <span className="margin-left-15">{t("loading")}</span>
              </div>
            )}

            {!isLeftSideLoading && <h3>{t("emptyResults")}</h3>}
          </>
        )}
      </div>

      <SwitchButtons
        disableRight={disableRight}
        disableLeft={disableLeft}
        handleOnRightButtonClick={handleOnRightButtonClick}
        handleOnLeftButtonClick={handleOnLeftButtonClick}
      />

      <div className="right-name">
        <h3>{rightSideName}</h3>
        <input
          placeholder={t("makeFeedSearch")}
          onChange={(e) => {
            if (handleFeedFilter) {
              handleFeedFilter(e.target.value);
            }
          }}
        />
      </div>
      <div className="right-side">
        {rightSideData.length ? (
          <Table
            data={trimData(rightSideData, rightSidePropsToDisplay, true)}
            typeSpecs={makeDataNonEditable(rightSideTypeSpecs)}
            keyPrefix="feedParameters"
            onSelectedRow={handleOnRightSideSelection}
            groupSortTableColumns={["feedGroup", "feedNumber"]}
            onDoubleClickRight={onDoubleClickRight}
          />
        ) : (
          <h3>
            {isRightSideLoading && (
              <div>
                <Loader size="small" />
                <span className="margin-left-15">{t("loading")}</span>
              </div>
            )}
            {!isRightSideLoading && t("emptyResults")}
          </h3>
        )}
      </div>
    </div>
  );
};

export default AddRemoveFeeds;
