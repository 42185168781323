/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";

import {
  addFeedToCurrentFeedPlan,
  removeFeedFromCurrentFeedPlan,
  setFeedOrderFeedPlan,
} from "pages/choose-customer/context/customerActions";

import { setCustomerFeeds } from "pages/customer-feeds/context/customerFeedsActions";

import customerFeedsTypeSpecs from "pages/customer-feeds/json/typeSpecsOfCustomerFeed.json";
import feedDietTypeSpecs from "pages/feed-plan-calculation/json/typeSpecsOfFeedDietData.json";

import AddRemoveFeeds from "components/add-remove-feeds/AddRemoveFeeds";
import LMButton from "components/lm-button/LMButton";

import ITypeSpec from "interfaces/ITypeSpec";
import ICustomerFeed from "interfaces/ICustomerFeed";

import useFeedHandler from "hooks/useFeedHandler";
import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useConfirm from "hooks/useConfirm";

import { BASE_CUSTOMER_FEEDS_URL } from "assets/constants";

interface IParams {
  feedPlanId: string;
}

interface IFeedPlanOverviewProps {
  setUnsavedFeedPlan: (value: boolean) => void;
}

const FeedPlanOverviewAddRemove = ({ setUnsavedFeedPlan }: IFeedPlanOverviewProps): JSX.Element => {
  const {
    customerFeedsState: { feeds },
    customerFeedsState,
    customerFeedsDispatch,
  } = useContext(CustomerFeedsContext);

  const {
    customerState: { currentFeedPlan },
    customerDispatch,
    getCurrentCustomerFeedPlan,
  } = useContext(CustomerContext);

  const cTypeSpecs = useRef<ITypeSpec>(customerFeedsTypeSpecs);
  const fTypeSpecs = useRef<ITypeSpec>(feedDietTypeSpecs);
  const shouldFetchCustomerFeeds = useRef(true);
  const hasFeedDietItemsBeenModified = useRef(false);
  const { feedPlanId }: IParams = useParams();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const { saveEditedFeedsToDB } = useSaveFeedRequest();
  const { isConfirmed } = useConfirm();

  const [selectedCustomerFeed, setSelectedCustomerFeed] = useState<ICustomerFeed>();
  const [selectedFeedDietItem, setSelectedFeedDietItem] = useState<ICustomerFeed>();
  const [filteredFeeds, setFilteredFeeds] = useState<ICustomerFeed[]>([]);
  const [searchInput, setSearchInput] = useState<string>();
  const CustomerFeedPropsToDisplay = ["feedGroup", "feedName", "feedNumber", "id"];

  const { getFeeds, isLoading } = useFeedHandler(customerFeedsState);

  useEffect(() => {
    setUnsavedFeedPlan(hasFeedDietItemsBeenModified.current);
  }, [hasFeedDietItemsBeenModified.current]);

  const fetchCustomerFeeds = useCallback(async () => {
    customerFeedsDispatch(
      setCustomerFeeds(
        await getFeeds(`${BASE_CUSTOMER_FEEDS_URL}?customerId=${currentFeedPlan?.customerId}`)
      )
    );
  }, [currentFeedPlan?.customerId]);

  const filterCustomerFeedsByCurrentFeedPlan = (inputFeeds: ICustomerFeed[]): ICustomerFeed[] => {
    if (currentFeedPlan?.feeds) {
      const customerFeedIsfOfCurrentFeedPlan: number[] = currentFeedPlan?.feeds.map(
        (feed) => feed.id!
      );

      return inputFeeds.filter((feed) => !customerFeedIsfOfCurrentFeedPlan.includes(feed.id!));
    }
    return inputFeeds;
  };

  const handleFeedFilter = (value: string) => {
    const filteredCustomerFeeds = filterCustomerFeedsByCurrentFeedPlan(feeds).filter((_feed) =>
      _feed.feedName?.toLowerCase().includes(value.toLocaleLowerCase())
    );

    setSearchInput(value);
    if (!filteredCustomerFeeds) {
      setFilteredFeeds([] as ICustomerFeed[]);
    } else {
      setFilteredFeeds(filteredCustomerFeeds);
    }
  };

  const moveCustomerFeedToFeedDiet = useCallback(() => {
    if (selectedCustomerFeed) {
      if (
        currentFeedPlan?.feeds.some((feedDietItem) => feedDietItem.id === selectedCustomerFeed.id)
      ) {
        isConfirmed(t("dublicateFeeds"), "alert");
      } else {
        customerDispatch(addFeedToCurrentFeedPlan(selectedCustomerFeed));
        setSelectedCustomerFeed(undefined);

        if (!hasFeedDietItemsBeenModified.current) {
          hasFeedDietItemsBeenModified.current = true;
        }
        if (searchInput) {
          handleFeedFilter(searchInput);
        }
      }
    }
  }, [selectedCustomerFeed, searchInput]);

  const onCustomerFeedSlection = useCallback(
    (selectedRow: any) => {
      const selectedFeed = feeds.find((feed) => feed.id === selectedRow.id);
      setSelectedCustomerFeed(selectedFeed);
    },
    [feeds]
  );

  const moveFeedDietToCustomerFeed = useCallback(() => {
    if (selectedFeedDietItem) {
      customerDispatch(removeFeedFromCurrentFeedPlan(selectedFeedDietItem));
      setSelectedFeedDietItem(undefined);

      if (!hasFeedDietItemsBeenModified.current) {
        hasFeedDietItemsBeenModified.current = true;
      }
      if (searchInput) {
        handleFeedFilter(searchInput);
      }
    }
  }, [selectedFeedDietItem, searchInput]);

  const onFeedDietItemSelection = useCallback(
    (selectedRow: any) => {
      const selectedFeed = currentFeedPlan?.feeds.find((feed) => feed.id === selectedRow.id);
      setSelectedFeedDietItem(selectedFeed);
    },
    [feeds]
  );

  /**
   * @description Sorts feeds based on sortIndex parameter. If present in currentFeedPlanFeeds
   * it sorts from there, if not it sorts from currentFeedPlan.feedDietItems
   * @param currentFeedPlanFeeds
   * @returns Feeds sorted on sortIndex
   */
  const sortLeftSideData = useCallback(
    (currentFeedPlanFeeds: ICustomerFeed[]): ICustomerFeed[] => {
      if (!currentFeedPlan) {
        return [] as ICustomerFeed[];
      }

      if (!currentFeedPlanFeeds.some((feedPlan) => feedPlan.sortIndex)) {
        return currentFeedPlanFeeds.sort((a, b) => {
          const matchingFeedDietItemA = currentFeedPlan.feedDietItems.find(
            (feedDietItem) => feedDietItem.customerFeedId === a.id
          );
          const matchingFeedDietItemB = currentFeedPlan.feedDietItems.find(
            (feedDietItem) => feedDietItem.customerFeedId === b.id
          );

          if (matchingFeedDietItemA?.sortIndex! > matchingFeedDietItemB?.sortIndex!) {
            return 1;
          }
          if (matchingFeedDietItemA?.sortIndex! < matchingFeedDietItemB?.sortIndex!) {
            return -1;
          }
          return 0;
        });
      }

      return currentFeedPlanFeeds.sort((a, b) => {
        if (a?.sortIndex! > b?.sortIndex!) {
          return 1;
        }
        if (a?.sortIndex! < b?.sortIndex!) {
          return -1;
        }
        return 0;
      });
    },
    [currentFeedPlan]
  );

  const handleOnClick = useCallback(async () => {
    if (currentFeedPlan) {
      await saveEditedFeedsToDB(currentFeedPlan);
      setUnsavedFeedPlan(false);
      goBack();
    }
  }, [hasFeedDietItemsBeenModified.current, currentFeedPlan]);

  /**
   * Checks if clicked element is first or last element in table.
   * We can't move the item in the table up if it's already first.
   * Same goes for the last item but downwards.
   */
  const handleOnSortClick = useCallback(
    (direction: string, row) => {
      const items = currentFeedPlan?.feeds
        ? sortLeftSideData(currentFeedPlan?.feeds)
        : ([] as ICustomerFeed[]);

      const isFirstElement = items[0].id === row.id;
      const isLastElement = items[items.length - 1].id === row.id;

      if (
        !(isFirstElement && direction === "arrow_drop_up") &&
        !(isLastElement && direction === "arrow_drop_down")
      ) {
        hasFeedDietItemsBeenModified.current = true;
        return customerDispatch(setFeedOrderFeedPlan(row, direction));
      }
    },
    [customerDispatch]
  );

  /**
   * Checks if double clicked row exists in currentFeedPlans feeds.
   * Removes that feed from the currentFeedplan
   */
  const handleOnDoubleClickLeft = useCallback(
    (row: any) => {
      const selectedFeed = currentFeedPlan?.feeds.find((feed) => feed.id === row.id);

      if (selectedFeed) {
        customerDispatch(removeFeedFromCurrentFeedPlan(selectedFeed));

        setSelectedFeedDietItem(undefined);
        if (!hasFeedDietItemsBeenModified.current) {
          hasFeedDietItemsBeenModified.current = true;
        }

        if (searchInput) {
          handleFeedFilter(searchInput);
        }
      }
    },
    [currentFeedPlan?.feeds, hasFeedDietItemsBeenModified.current, searchInput]
  );

  /**
   * Checks if double clicked row exists in customers feeds.
   * Adds that feed to the currentFeedplan
   */
  const handleOnDoubleClickRight = useCallback(
    (row: any) => {
      const selectedFeed = feeds.find((feed) => feed.id === row.id);
      if (selectedFeed) {
        if (currentFeedPlan?.feeds.some((feedDietItem) => feedDietItem.id === selectedFeed.id)) {
          isConfirmed(t("dublicateFeeds"), "alert");
        } else {
          customerDispatch(addFeedToCurrentFeedPlan(selectedFeed));
          setSelectedCustomerFeed(undefined);

          if (!hasFeedDietItemsBeenModified.current) {
            hasFeedDietItemsBeenModified.current = true;
          }

          if (searchInput) {
            handleFeedFilter(searchInput);
          }
        }
      }
    },
    [currentFeedPlan?.feeds, hasFeedDietItemsBeenModified.current, feeds, searchInput]
  );

  useEffect(() => {
    if (currentFeedPlan) {
      if (feeds.length === 0 && shouldFetchCustomerFeeds.current) {
        fetchCustomerFeeds();
        shouldFetchCustomerFeeds.current = false;
      }
    } else {
      getCurrentCustomerFeedPlan(parseInt(feedPlanId));
    }
  }, [currentFeedPlan, fetchCustomerFeeds]);

  const rightSideData = searchInput ? filteredFeeds : filterCustomerFeedsByCurrentFeedPlan(feeds);

  return (
    <div className="add-remove-wrapper">
      <div className="button-wrapper">
        <LMButton text={t("done")} type="button" click={handleOnClick} />
      </div>
      <AddRemoveFeeds
        rightSidePropsToDisplay={CustomerFeedPropsToDisplay}
        rightSideName={t("customerFeeds")}
        rightSideData={rightSideData}
        rightSideTypeSpecs={cTypeSpecs.current}
        isRightSideLoading={isLoading}
        onRightSideSelection={onCustomerFeedSlection}
        onRightButtonClick={moveFeedDietToCustomerFeed}
        leftSidePropsToDisplay={CustomerFeedPropsToDisplay}
        leftSideName={t("feedDiet")}
        leftSideData={currentFeedPlan?.feeds.length ? sortLeftSideData(currentFeedPlan?.feeds) : []}
        leftSideTypeSpecs={fTypeSpecs.current}
        isLeftSideLoading={false}
        onLeftSideSelection={onFeedDietItemSelection}
        onLeftButtonClick={moveCustomerFeedToFeedDiet}
        sortArrows
        onTableSort={handleOnSortClick}
        onDoubleClickLeft={handleOnDoubleClickLeft}
        onDoubleClickRight={handleOnDoubleClickRight}
        handleFeedFilter={handleFeedFilter}
      />
    </div>
  );
};

export default FeedPlanOverviewAddRemove;
