/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useConfirm from "hooks/useConfirm";

import Table from "components/table/Table";
import Loader from "components/loader/Loader";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import {
  setCurrentFeedPlan,
  setCustomerFeedPlans,
} from "pages/choose-customer/context/customerActions";

import ITableDataRow from "interfaces/ITableDataRow";

import typeSpecsCustomerFeedPlans from "../json/typeSpecsOfCustomerFeedPlans.json";

interface ICustomerFeedPlanProps {
  isLoading?: boolean;
}

const CustomerFeedPlanTable = ({ isLoading }: ICustomerFeedPlanProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isConfirmed } = useConfirm();

  const { deleteFeedPlanFromDatabase } = useSaveFeedRequest();

  const {
    customerState: { customerFeedPlans, currentFeedPlan },
    customerDispatch,
  } = useContext(CustomerContext);

  /**
   * This method will trim all the customerFeedPlans t0
   *  only display the properties that need to be shown.
   * @param {ITableDataRow[]} customerFeedPlans
   * @return {ITableDataRow[]} The trimmed customerFeedPlans
   */
  const trimCustomerFeedPlans = useCallback(
    (_customerFeedPlans: ITableDataRow[]) => {
      const propertiesToDisplay = ["id", "name", "updated", "updatedBy"];

      return _customerFeedPlans.map((element) => {
        const trimmedElement: ITableDataRow = {};
        for (const key in element) {
          if (propertiesToDisplay.includes(key)) {
            trimmedElement[key] = element[key] ? element[key] : "";
          }

          if (key === "animalType") {
            trimmedElement[key] = element[key].name ? t(element[key].name) : "";
          }
        }

        trimmedElement.deleteFeedPlan = "deleteFeedPlanIcon";

        return trimmedElement;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSelectedRow = useCallback(
    (selectedRow: ITableDataRow) => {
      if (!selectedRow) return;

      customerDispatch(setCurrentFeedPlan());

      const clickedCustomerFeedPlan = customerFeedPlans?.find(
        (customerFeedPlan) => customerFeedPlan.id === selectedRow.id
      );

      if (!clickedCustomerFeedPlan) return;

      customerDispatch(setCurrentFeedPlan(clickedCustomerFeedPlan));
      history.push(`/feed-plan-calculation/${clickedCustomerFeedPlan.id}`);
    },
    [customerDispatch, customerFeedPlans, history]
  );

  const handleDeleteFeedPlan = useCallback(
    async (feedPlanId: number, feedPlanName: string) => {
      const warning = `${t("deleteFeedPlanConfirmation")} ${feedPlanName}`;
      const confirm = await isConfirmed(warning);
      if (confirm) {
        const result = await deleteFeedPlanFromDatabase(feedPlanId);
        if (result.ok) {
          customerDispatch(
            setCustomerFeedPlans(
              customerFeedPlans!.filter((feedPlan) => feedPlan.id !== feedPlanId)
            )
          );

          if (currentFeedPlan && currentFeedPlan.id === feedPlanId) {
            customerDispatch(setCurrentFeedPlan());
          }

          return isConfirmed(t("deleteSuccessful"), "alert");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerFeedPlans]
  );

  return customerFeedPlans && customerFeedPlans?.length ? (
    <Table
      data={trimCustomerFeedPlans(customerFeedPlans)}
      typeSpecs={typeSpecsCustomerFeedPlans}
      keyPrefix="customerFeedPlans"
      onSelectedRow={onSelectedRow}
      deleteFeedPlan={handleDeleteFeedPlan}
    />
  ) : (
    <>
      <h3>
        {customerFeedPlans === undefined && t("fetchingError")}
        {!isLoading && customerFeedPlans?.length === 0 && t("emptyCustomerFeedPlanResult")}
      </h3>
      {isLoading && (
        <div>
          <Loader size="small" />
          <span className="margin-left-15">{t("loading")}</span>
        </div>
      )}
    </>
  );
};

export default CustomerFeedPlanTable;
