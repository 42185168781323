import { t } from "i18next";
import React from "react";

interface ModalProps {
  headerText?: string;
  children?: React.ReactNode;
  show: boolean;
  hide: React.MouseEventHandler<HTMLSpanElement> | undefined;
}

const Modal = ({ headerText, show, hide, children }: ModalProps) => {
  if (show) {
    return (
      <div className="modal">
        <div className="modal-header">
          <span>{headerText ? t(headerText) : ""}</span>
          {/* eslint-disable-next-line */}
          <span className="close material-icons" data-icon="close" onClick={hide} />
        </div>
        <div className="modal-content">{children}</div>
        <div className="modal-footer" />
      </div>
    );
  }

  // eslint-disable-next-line
  return <></>;
};

export default Modal;
