import IAction from "interfaces/IAction";
import ICustomerFeed from "interfaces/ICustomerFeed";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import ICustomerState from "interfaces/ICustomerState";
import IFeedMix from "interfaces/IFeedMix";
import { IFeedMixItem } from "interfaces/IFeedMixUpdateRequest";
import IUserHiddenColumns, { ITableTypes } from "interfaces/IUserHiddenColumns";

import {
  ADD_FEED_TO_CURRENT_FEED_MIX_ITEMS,
  ADD_FEED_TO_CURRENT_FEED_PLAN,
  ADD_USER_HIDDEN_COLUMNS,
  REMOVE_FEED_FROM_CURRENT_FEED_MIX_ITEMS,
  REMOVE_FEED_FROM_CURRENT_FEED_PLAN,
  REMOVE_USER_HIDDEN_COLUMNS,
  SET_CURRENT_CUSTOMER,
  SET_CURRENT_FEED_PLAN,
  SET_CURRENT_FEED_PLAN_CONTROL_SETTINGS,
  SET_CURRENT_FEED_MIX,
  SET_CURRENT_FEED_MIX_FEEDS,
  SET_CURRENT_FEED_MIX_ITEMS,
  SET_CUSTOMERS,
  SET_CUSTOMER_DETAILS,
  SET_CUSTOMER_FEED_PLANS,
  SET_FEED_ORDER_FEED_PLAN,
  SET_FEED_ORDER_FEED_MIX,
  SET_READ_ONLY_MODE,
  SET_RECENT_CUSTOMERS,
  SET_USER_RECENT_CUSTOMERS,
  SET_USER,
  SET_USER_HIDDEN_COLUMNS,
} from "pages/choose-customer/context/customerActions";

function setFeedOrderFeedMix(customerState: ICustomerState, action: IAction): ICustomerState {
  const {
    feed: { id },
    direction,
  } = action.payload;
  const currentFeedMixCopy: IFeedMix = { ...customerState.currentFeedMix! };

  const feed: IFeedMixItem | undefined = currentFeedMixCopy.feedMixItems!.find(
    (item) => item.customerFeedId === id
  );

  if (!feed) {
    return { ...customerState };
  }

  const indexOf = currentFeedMixCopy.feedMixItems?.indexOf(feed);

  currentFeedMixCopy.feedMixItems?.splice(indexOf!, 1);

  if (direction === "arrow_drop_up") {
    currentFeedMixCopy.feedMixItems?.splice(indexOf! - 1, 0, feed);
  } else {
    currentFeedMixCopy.feedMixItems?.splice(indexOf! + 1, 0, feed);
  }

  for (let i = 0; i < currentFeedMixCopy.feedMixItems!.length; i++) {
    currentFeedMixCopy.feedMixItems![i].sortIndex = i;
  }

  return { ...customerState, currentFeedMix: currentFeedMixCopy };
}

function setFeedOrderFeedPlan(customerState: ICustomerState, action: IAction): ICustomerState {
  const {
    feed: { id },
    direction,
  } = action.payload;
  const currentFeedPlanCopy: ICustomerFeedPlan = { ...customerState.currentFeedPlan! };

  const feed: ICustomerFeed | undefined = currentFeedPlanCopy.feeds.find(
    (_feed: ICustomerFeed) => _feed.id === id
  );

  if (!feed) {
    return { ...customerState };
  }

  const indexOfFeed = currentFeedPlanCopy.feeds.indexOf(feed);
  currentFeedPlanCopy.feeds.splice(indexOfFeed, 1);

  if (direction === "arrow_drop_up") {
    currentFeedPlanCopy.feeds.splice(indexOfFeed - 1, 0, feed);
  } else {
    currentFeedPlanCopy.feeds.splice(indexOfFeed + 1, 0, feed);
  }

  for (let i = 0; i < currentFeedPlanCopy.feeds.length; i++) {
    currentFeedPlanCopy.feeds[i].sortIndex = i;
  }

  return { ...customerState, currentFeedPlan: currentFeedPlanCopy };
}

function addFeedToCurrentFeedPlan(customerState: ICustomerState, action: IAction): ICustomerState {
  const feed: ICustomerFeed = action.payload;
  const currentFeedPlanCopy: ICustomerFeedPlan = { ...customerState.currentFeedPlan! };

  if (currentFeedPlanCopy.feeds) {
    currentFeedPlanCopy.feeds.push(feed);
  } else {
    currentFeedPlanCopy.feeds = [feed];
  }

  for (let i = 0; i < currentFeedPlanCopy.feeds.length; i++) {
    currentFeedPlanCopy.feeds[i].sortIndex = i;
  }

  return { ...customerState, currentFeedPlan: currentFeedPlanCopy };
}

function removeFeedFromCurrentFeedPlan(
  customerState: ICustomerState,
  action: IAction
): ICustomerState {
  const feedToRemove: ICustomerFeed = action.payload;
  const currentFeedPlanCopy: ICustomerFeedPlan = { ...customerState.currentFeedPlan! };

  currentFeedPlanCopy.feeds = currentFeedPlanCopy.feeds.filter(
    (feed) => feed.id !== feedToRemove.id
  );

  for (let i = 0; i < currentFeedPlanCopy.feeds.length; i++) {
    currentFeedPlanCopy.feeds[i].sortIndex = i;
  }

  return { ...customerState, currentFeedPlan: currentFeedPlanCopy };
}

function setCustomers(customerState: ICustomerState, action: IAction): ICustomerState {
  return { ...customerState, customers: action.payload };
}

function setCurrentCustomer(customerState: ICustomerState, action: IAction): ICustomerState {
  if (action.payload) {
    return { ...customerState, currentCustomer: action.payload };
  }
  return { ...customerState, currentCustomer: undefined };
}

function setCurrentFeedPlan(customerState: ICustomerState, action: IAction): ICustomerState {
  if (action.payload) {
    return { ...customerState, currentFeedPlan: action.payload };
  }
  return { ...customerState, currentFeedPlan: undefined };
}

function setCurrentFeedPlanControlSettings(
  customerState: ICustomerState,
  action: IAction
): ICustomerState {
  if (action.payload) {
    return { ...customerState, currentFeedPlanControlSettings: action.payload };
  }
  return { ...customerState, currentFeedPlanControlSettings: undefined };
}

function setCurrentFeedMix(customerState: ICustomerState, action: IAction): ICustomerState {
  if (action.payload) {
    return { ...customerState, currentFeedMix: action.payload };
  }
  return { ...customerState, currentFeedMix: undefined };
}

function setCurrentFeedMixFeeds(customerState: ICustomerState, action: IAction): ICustomerState {
  if (action.payload) {
    return { ...customerState, currentFeedMixFeeds: action.payload };
  }
  return { ...customerState, currentFeedMixFeeds: undefined };
}

function setCurrentFeedMixItems(customerState: ICustomerState, action: IAction): ICustomerState {
  if (action.payload) {
    return { ...customerState, currentFeedMixItems: action.payload };
  }
  return { ...customerState, currentFeedMixItems: undefined };
}

function addFeedToCurrentFeedMixItems(
  customerState: ICustomerState,
  action: IAction
): ICustomerState {
  const feed: ICustomerFeed = action.payload;
  const feedMixItem: IFeedMixItem = {
    customerFeedId: action.payload.id,
    shareInPercDm: 0,
    shareInPercKg: 0,
    amountKg: 0,
    amountKgDm: 0,
    sortIndex: 0,
  };
  const currentFeedMixCopy: IFeedMix = customerState.currentFeedMix!;

  if (currentFeedMixCopy.feeds) {
    currentFeedMixCopy.feeds.push(feed);
  } else {
    currentFeedMixCopy.feeds = [feed];
  }

  if (currentFeedMixCopy.feedMixItems) {
    currentFeedMixCopy.feedMixItems.push(feedMixItem);
  } else {
    currentFeedMixCopy.feedMixItems = [feedMixItem];
  }

  for (let i = 0; i < currentFeedMixCopy.feedMixItems.length; i++) {
    currentFeedMixCopy.feedMixItems[i].sortIndex = i;
  }

  return { ...customerState, currentFeedMix: currentFeedMixCopy };
}

function removeFeedFromCurrentFeedMixItem(
  customerState: ICustomerState,
  action: IAction
): ICustomerState {
  const feedToRemove: IFeedMix = action.payload;

  const currentFeedMixCopy: IFeedMix = customerState.currentFeedMix!;

  currentFeedMixCopy.feeds = currentFeedMixCopy?.feeds?.filter(
    (feed) => feed.id !== feedToRemove.id
  );
  currentFeedMixCopy.feedMixItems = currentFeedMixCopy?.feedMixItems?.filter(
    (feed) => feed.customerFeedId !== feedToRemove.id
  );

  if (currentFeedMixCopy.feedMixItems) {
    for (let i = 0; i < currentFeedMixCopy.feedMixItems.length; i++) {
      currentFeedMixCopy.feedMixItems[i].sortIndex = i;
    }
  }

  return { ...customerState, currentFeedMix: currentFeedMixCopy };
}

function setCustomerDetails(customerState: ICustomerState, action: IAction): ICustomerState {
  if (action.payload) {
    return { ...customerState, customerDetails: action.payload };
  }
  return { ...customerState, customerDetails: undefined };
}

function setCustomerFeedPlans(customerState: ICustomerState, action: IAction): ICustomerState {
  return { ...customerState, customerFeedPlans: action.payload };
}

function setReadOnlyMode(customerState: ICustomerState, action: IAction): ICustomerState {
  return { ...customerState, readOnlyMode: action.payload };
}

function setRecentCustomers(customerState: ICustomerState, action: IAction): ICustomerState {
  return { ...customerState, recentCustomers: action.payload };
}

function setUserRecentCustomers(customerState: ICustomerState, action: IAction): ICustomerState {
  return { ...customerState, userRecentCustomers: action.payload };
}

function setUser(customerState: ICustomerState, action: IAction): ICustomerState {
  return { ...customerState, currentUser: action.payload };
}

function setUserHiddenColumns(customerState: ICustomerState, action: IAction): ICustomerState {
  return { ...customerState, userHiddenTableColumns: action.payload };
}

function addUserHiddenColumns(customerState: ICustomerState, action: IAction): ICustomerState {
  const userHiddenTableColumnsCopy: IUserHiddenColumns = customerState.userHiddenTableColumns!;
  const params: {
    tableType: ITableTypes;
    index: number;
  } = {
    tableType: action.payload.tableType,
    index: action.payload.index,
  };

  if (!userHiddenTableColumnsCopy[params.tableType].some((index) => index === params.index)) {
    userHiddenTableColumnsCopy[params.tableType].push(params.index);
  }

  userHiddenTableColumnsCopy[params.tableType].sort((a, b) => a - b);

  return { ...customerState, userHiddenTableColumns: userHiddenTableColumnsCopy };
}

function removeUserHiddenColumns(customerState: ICustomerState, action: IAction): ICustomerState {
  const userHiddenTableColumnsCopy: IUserHiddenColumns = customerState.userHiddenTableColumns!;
  const params: {
    tableType: ITableTypes;
    index: number;
  } = {
    tableType: action.payload.tableType,
    index: action.payload.index,
  };
  userHiddenTableColumnsCopy[params.tableType] = userHiddenTableColumnsCopy[
    params.tableType
  ].filter((item) => item !== params.index);

  userHiddenTableColumnsCopy[params.tableType].sort((a, b) => a - b);

  return { ...customerState, userHiddenTableColumns: userHiddenTableColumnsCopy };
}

function customerReducer(customerState: ICustomerState, action: IAction): ICustomerState {
  const args: [ICustomerState, IAction] = [customerState, action];

  switch (action.type) {
    case ADD_FEED_TO_CURRENT_FEED_MIX_ITEMS:
      return addFeedToCurrentFeedMixItems(...args);
    case ADD_FEED_TO_CURRENT_FEED_PLAN:
      return addFeedToCurrentFeedPlan(...args);
    case ADD_USER_HIDDEN_COLUMNS:
      return addUserHiddenColumns(...args);
    case REMOVE_FEED_FROM_CURRENT_FEED_MIX_ITEMS:
      return removeFeedFromCurrentFeedMixItem(...args);
    case REMOVE_FEED_FROM_CURRENT_FEED_PLAN:
      return removeFeedFromCurrentFeedPlan(...args);
    case REMOVE_USER_HIDDEN_COLUMNS:
      return removeUserHiddenColumns(...args);
    case SET_CUSTOMERS:
      return setCustomers(...args);
    case SET_CURRENT_CUSTOMER:
      return setCurrentCustomer(...args);
    case SET_CURRENT_FEED_PLAN:
      return setCurrentFeedPlan(...args);
    case SET_CURRENT_FEED_PLAN_CONTROL_SETTINGS:
      return setCurrentFeedPlanControlSettings(...args);
    case SET_CURRENT_FEED_MIX:
      return setCurrentFeedMix(...args);
    case SET_CURRENT_FEED_MIX_FEEDS:
      return setCurrentFeedMixFeeds(...args);
    case SET_CURRENT_FEED_MIX_ITEMS:
      return setCurrentFeedMixItems(...args);
    case SET_CUSTOMER_DETAILS:
      return setCustomerDetails(...args);
    case SET_CUSTOMER_FEED_PLANS:
      return setCustomerFeedPlans(...args);
    case SET_FEED_ORDER_FEED_PLAN:
      return setFeedOrderFeedPlan(...args);
    case SET_FEED_ORDER_FEED_MIX:
      return setFeedOrderFeedMix(...args);
    case SET_READ_ONLY_MODE:
      return setReadOnlyMode(...args);
    case SET_RECENT_CUSTOMERS:
      return setRecentCustomers(...args);
    case SET_USER_RECENT_CUSTOMERS:
      return setUserRecentCustomers(...args);
    case SET_USER:
      return setUser(...args);
    case SET_USER_HIDDEN_COLUMNS:
      return setUserHiddenColumns(...args);
    default:
      return customerState;
  }
}

export default customerReducer;
