/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/table/Table";

import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import ITableDataRow from "interfaces/ITableDataRow";

import printConfig from "../json/printConfig.json";

interface ISectionsToPrint {
  [key: string]: boolean;
}

interface FeedPlanPrintProps {
  checkNutrientsAndControlParameters: boolean;
  currentFeedPlan: ICustomerFeedPlan | undefined;
  handleOnChange: (value: string) => void;
  sectionsToPrint: ISectionsToPrint;
  setFeedDietCheckbox: (value: number[]) => void;
  setControlParameterCheckbox: (value: number[]) => void;
  setCheckNutrientsAndControlParameters: (value: boolean) => void;
}

const FeedPlanPrint = ({
  checkNutrientsAndControlParameters,
  currentFeedPlan,
  handleOnChange,
  sectionsToPrint,
  setFeedDietCheckbox,
  setControlParameterCheckbox,
  setCheckNutrientsAndControlParameters,
}: FeedPlanPrintProps) => {
  const { t } = useTranslation();
  const [feedDietCheckboxData, setFeedDietCheckboxData] = useState<ITableDataRow[]>([]);
  const [controlParameterCheckboxData, setControlParameterCheckboxData] = useState<ITableDataRow[]>(
    []
  );

  const createYieldLevelSpecs = (feedPlan: ICustomerFeedPlan | undefined) => {
    if (feedPlan) {
      let yieldObjectSpecsData: any = {};
      feedPlan.feedDietItems.forEach((feed, index) => {
        if (index === 0) {
          if (feedPlan.animalType.name !== "milkCow") {
            Object.entries(feedPlan.yieldLevels).forEach((item: any) => {
              const yieldKey: any = item[1].description;
              const yieldObjectSpecs: any = {};
              yieldObjectSpecs[yieldKey] = { type: "boolean", editable: true };
              yieldObjectSpecsData = Object.assign(yieldObjectSpecsData, yieldObjectSpecs);
            });
          } else {
            Object.entries(feed.yieldLevelValues).forEach((item: any) => {
              const yieldKey: any = item[0];
              const yieldObjectSpecs: any = {};
              yieldObjectSpecs[yieldKey] = { type: "boolean", editable: true };
              yieldObjectSpecsData = Object.assign(yieldObjectSpecsData, yieldObjectSpecs);
            });
          }
        }
      });
      return yieldObjectSpecsData;
    }
    return {};
  };

  const createYieldLevelData = (feedPlan: ICustomerFeedPlan | undefined) => {
    if (feedPlan) {
      const yieldTableData: any = [];
      feedPlan.feedDietItems.forEach((feed, index) => {
        if (index === 0) {
          const yieldObject: ISectionsToPrint = {} as ISectionsToPrint;
          if (feedPlan.animalType.name !== "milkCow") {
            Object.entries(feedPlan.yieldLevels).forEach((item: any) => {
              yieldObject[item[1].description] = true;
            });
          } else {
            Object.entries(feed.yieldLevelValues).forEach((item: any) => {
              yieldObject[item[0]] = true;
            });
          }
          yieldTableData.push(yieldObject);
        }
      });
      setFeedDietCheckboxData(yieldTableData);
      setControlParameterCheckboxData(yieldTableData);
    }
  };
  const handleOnUpdatedFeedDietCheck = useCallback(
    (updatedRow: ISectionsToPrint) => {
      const feedDietCheckboxArray: any = [];
      Object.entries(updatedRow).forEach((item, index) => {
        if (item[1] === true) {
          if (item[0].includes("-")) {
            const newYieldLevel: number = parseInt(item[0]) / 3;
            feedDietCheckboxArray.push(newYieldLevel);
          } else if (
            item[0].includes("kg") ||
            item[0].includes("farOff8-3w") ||
            item[0].includes("closeUp3-0w")
          ) {
            feedDietCheckboxArray.push(index + 1);
          } else {
            feedDietCheckboxArray.push(parseInt(item[0]));
          }
        }
      });
      if (setFeedDietCheckbox && feedDietCheckboxArray) {
        setFeedDietCheckbox(feedDietCheckboxArray);
      }
    },
    [setFeedDietCheckbox]
  );

  const handleOnUpdatedControlParameterCheck = useCallback(
    (updatedRow: ISectionsToPrint) => {
      const controlParameterCheckboxArray: any = [];
      Object.entries(updatedRow).forEach((item, index) => {
        if (item[1] === true) {
          if (item[0].includes("-")) {
            const newYieldLevel: number = parseInt(item[0]) / 3;
            controlParameterCheckboxArray.push(newYieldLevel);
          } else if (
            item[0].includes("kg") ||
            item[0].includes("farOff8-3w") ||
            item[0].includes("closeUp3-0w")
          ) {
            controlParameterCheckboxArray.push(index + 1);
          } else {
            controlParameterCheckboxArray.push(parseInt(item[0]));
          }
        }
      });
      if (setControlParameterCheckbox && controlParameterCheckboxArray) {
        setControlParameterCheckbox(controlParameterCheckboxArray);
      }
    },
    [setControlParameterCheckbox]
  );

  const setDefaultYieldLevelValues = (feedPlan: ICustomerFeedPlan | undefined) => {
    const feedDietCheckboxArray: any = [];
    const controlParameterCheckboxArray: any = [];
    if (feedPlan) {
      Object.entries(feedPlan.yieldLevels).forEach((item: any, index: number) => {
        if (item[1].description.includes("-")) {
          const newYieldLevel: number = parseInt(item[1].level);
          feedDietCheckboxArray.push(newYieldLevel);
          controlParameterCheckboxArray.push(newYieldLevel);
        } else if (
          item[1].description.includes("kg") ||
          item[1].description.includes("farOff8-3w") ||
          item[1].description.includes("closeUp3-0w")
        ) {
          feedDietCheckboxArray.push(index + 1);
          controlParameterCheckboxArray.push(index + 1);
        } else {
          feedDietCheckboxArray.push(parseInt(item[1].level));
          controlParameterCheckboxArray.push(parseInt(item[1].level));
        }
      });
    }
    setFeedDietCheckbox(feedDietCheckboxArray);
    setControlParameterCheckbox(controlParameterCheckboxArray);
  };

  const handleAllCheck = useCallback(() => {
    setCheckNutrientsAndControlParameters(!checkNutrientsAndControlParameters);
  }, [checkNutrientsAndControlParameters, setCheckNutrientsAndControlParameters]);

  useEffect(() => {
    createYieldLevelData(currentFeedPlan);
    setDefaultYieldLevelValues(currentFeedPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3>{currentFeedPlan ? currentFeedPlan.name : ""}</h3>
      <div className="section">
        <h3>{t("printSections")}</h3>
        {printConfig.sectionsToPrint.feedPlan.map((section, index) => (
          <li key={section}>
            <label
              className="print-checkbox lm__checkbox lm__tick"
              htmlFor={`printSection-${index}`}
            >
              <input
                id={`printSection-${index}`}
                type="checkbox"
                checked={sectionsToPrint[section]}
                onChange={() => {
                  handleOnChange(section);
                }}
              />
              <label />
              <span className="margin-left-5">{t(section)}</span>
            </label>
          </li>
        ))}
      </div>
      <div className="section">
        <h3>{t("yieldLevels")}</h3>
        <h3>{t("feedDiet")}</h3>
        <Table
          data={feedDietCheckboxData}
          typeSpecs={createYieldLevelSpecs(currentFeedPlan)}
          onUpdatedRow={handleOnUpdatedFeedDietCheck}
          disableHighlight
          emptyTableData={!feedDietCheckboxData.length ? true : undefined}
        />
        <h3>{t("controlParametersTitle")}</h3>
        <Table
          data={controlParameterCheckboxData}
          typeSpecs={createYieldLevelSpecs(currentFeedPlan)}
          onUpdatedRow={handleOnUpdatedControlParameterCheck}
          disableHighlight
          emptyTableData={!controlParameterCheckboxData.length ? true : undefined}
        />
        <label className="print-checkbox lm__checkbox lm__tick" htmlFor="printAll">
          <input
            id="printAll"
            type="checkbox"
            checked={checkNutrientsAndControlParameters}
            onChange={() => {
              handleAllCheck();
            }}
          />
          <label />
          <span className="margin-left-5">{t("includeNutrientsControlParameters")}</span>
        </label>
      </div>
    </>
  );
};

export default FeedPlanPrint;
