import { useContext } from "react";
import { ConfirmContext } from "../components/modal/context/ConfirmContextProvider";

const useConfirm = () => {
  const { confirm, setConfirm } = useContext(ConfirmContext);

  const isConfirmed = (prompt: string, type?: string, resetChanges?: () => void) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
        resetChanges,
        type: type || "",
      });
    });

    const reset = () => {
      setConfirm({ prompt: "", isOpen: false });
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };
  return {
    ...confirm,
    isConfirmed,
  };
};

export default useConfirm;
