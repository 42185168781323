/* eslint-disable react-hooks/exhaustive-deps */

import { createContext, useCallback, useReducer } from "react";
import useFetch from "hooks/useFetch";
import { BASE_FEED_PLAN_URL, BASE_CUSTOMERS_URL, BASE_USER_URL } from "assets/constants";
import CustomerReducer from "pages/choose-customer/context/customerReducer";
import dummyData from "pages/feed-plan-calculation/json/feedPlanDummyData.json";

import {
  setCurrentFeedPlan,
  setCustomerDetails,
  setCustomerFeedPlans,
  setCustomers,
  setRecentCustomers,
  setUserRecentCustomers,
  setUser,
} from "pages/choose-customer/context/customerActions";

import IContextProps from "interfaces/IContextProps";
import IAction from "interfaces/IAction";
import ICustomer from "interfaces/ICustomer";
import IRecentCustomers from "interfaces/IRecentCustomers";
import ICustomerState from "interfaces/ICustomerState";
import ICustomerDetails from "interfaces/ICustomerDetails";
import ICustomerFeedPlansResult from "interfaces/ICustomerFeedPlansResult";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import IUser from "pages/choose-customer/interfaces/IUser";

interface IChooseCustomerContext {
  customerState: ICustomerState;
  customerDispatch: React.Dispatch<IAction>;
  getCurrentUser: () => Promise<void>;
  getAllCustomers: () => Promise<void>;
  getCustomerDetails: (customerId: string) => Promise<void>;
  getCustomerFeedPlans: (customerId: string) => Promise<void>;
  getCurrentCustomerFeedPlan: (customerFeedPlanId: number) => Promise<void>;
  getRecentCustomers: () => Promise<void>;
  getUserRecentCustomers: (username: string) => Promise<void>;
  searchCustomers: (inputSearch: string) => Promise<void>;
}

interface Customers {
  customers: ICustomer[];
}

export const CustomerContext = createContext<IChooseCustomerContext>({} as IChooseCustomerContext);

const CustomerProvider = ({ children }: IContextProps): JSX.Element => {
  const { get } = useFetch();
  const [customerState, customerDispatch] = useReducer(CustomerReducer, {
    customers: [],
    customerFeedPlans: [],
    recentCustomers: [],
    readOnlyMode: true,
    userHiddenTableColumns: {
      customerFeedColumns: [],
      feedMixColumns: [],
      standardFeedColumns: [],
    },
  });

  const getAllCustomers = useCallback(async () => {
    const customers: Customers = await get(BASE_CUSTOMERS_URL);
    customerDispatch(setCustomers(customers.customers));
  }, []);

  const getCustomerDetails = useCallback(async (customerId: string) => {
    const customerDetails: ICustomerDetails = await get(
      `${BASE_CUSTOMERS_URL}/${customerId}/details`
    );
    customerDispatch(setCustomerDetails(customerDetails));
  }, []);

  const getCustomerFeedPlans = useCallback(async (customerId: string) => {
    const results: ICustomerFeedPlansResult = await get(`
      ${BASE_FEED_PLAN_URL}?CustomerId=${customerId}
    `);

    customerDispatch(setCustomerFeedPlans(results.feedPlans));
  }, []);

  const getCurrentCustomerFeedPlan = useCallback(async (customerFeedPlanId: number) => {
    const results: ICustomerFeedPlan = await get(`${BASE_FEED_PLAN_URL}/${customerFeedPlanId}`);

    if (!results) {
      customerDispatch(setCurrentFeedPlan(dummyData[0] as unknown as ICustomerFeedPlan));
    } else if (results.errorMessage || !results.id) {
      throw results.errorMessage;
    } else {
      customerDispatch(setCurrentFeedPlan(results));
    }
  }, []);

  const searchCustomers = useCallback(async (inputSearch: string) => {
    const results: Customers = await get(`${BASE_CUSTOMERS_URL}/search?query=${inputSearch}`);
    customerDispatch(setCustomers(results.customers));
  }, []);

  const getRecentCustomers = useCallback(async () => {
    const recentCustomers = await get(
      `${BASE_FEED_PLAN_URL}/?DistinctByCustomerId=true&PageSize=10&PageNumber=1`
    );
    const recentCustomersArray: IRecentCustomers[] = [];

    recentCustomers.feedPlans?.forEach((params: IRecentCustomers) => {
      recentCustomersArray.push({
        customerId: params.customerId,
        name: params.customerName ? params.customerName : "",
        updated: params.updated ? params.updated : "",
        updatedBy: params.updatedBy ? params.updatedBy : "",
      });
    });

    customerDispatch(setRecentCustomers(recentCustomersArray));
  }, []);

  const getUserRecentCustomers = useCallback(async (username: string) => {
    const userRecentCustomers = await get(
      `${BASE_FEED_PLAN_URL}/?author=${username}&DistinctByCustomerId=true&PageSize=10&PageNumber=1`
    );

    const userRecentCustomersArray: IRecentCustomers[] = [] as IRecentCustomers[];
    userRecentCustomers.feedPlans?.forEach((params: IRecentCustomers) => {
      userRecentCustomersArray.push({
        customerId: params.customerId,
        name: params.customerName ? params.customerName : "",
        updated: params.updated ? params.updated : "",
        updatedBy: params.updatedBy ? params.updatedBy : "",
      });
    });

    customerDispatch(setUserRecentCustomers(userRecentCustomersArray));
  }, []);

  const getCurrentUser = async () => {
    const currentUser: IUser = await get(BASE_USER_URL);
    customerDispatch(setUser(currentUser));
  };

  /* eslint-disable react/jsx-no-constructed-context-values */
  const values = {
    customerState,
    customerDispatch,
    getAllCustomers,
    getRecentCustomers,
    getUserRecentCustomers,
    searchCustomers,
    getCustomerFeedPlans,
    getCustomerDetails,
    getCurrentCustomerFeedPlan,
    getCurrentUser,
  };

  return <CustomerContext.Provider value={values}>{children}</CustomerContext.Provider>;
};

export default CustomerProvider;
