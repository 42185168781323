/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { TableDataContext } from "components/table/Table";
import { TableDataValue } from "types";

import useToggle from "hooks/useToggle";
import { roundToDecimal } from "hooks/useFeedMixCalculation";
import useConfirm from "hooks/useConfirm";

interface ITableDataProps {
  tableDataKey: string;
  tableDataValue: string | number | boolean | Date;
  update: (key: string, newValue: TableDataValue) => void;
  checkIfParamsComboIsUnique?: (key: string, value: string) => boolean | undefined;
  uniqueParamsCombo?: string[];
  disabled?: boolean;
  feedMix?: { feedNumber: number; feedName: string; id: number | undefined };
  hidden?: boolean;
  numberOfDecimals?: number;
  unsavedItems?: boolean;
  disableHighlight?: boolean;
}

const TableData = ({
  tableDataKey,
  tableDataValue,
  update,
  checkIfParamsComboIsUnique,
  uniqueParamsCombo,
  disabled,
  feedMix,
  hidden,
  numberOfDecimals,
  unsavedItems,
  disableHighlight,
}: ITableDataProps): JSX.Element => {
  const { typeSpecs, tableDataKeyPrefix } = useContext(TableDataContext);
  const { t } = useTranslation();
  const { t: tWithPrefix } = useTranslation("", { keyPrefix: "feedParameters" });
  const history = useHistory();
  const { isConfirmed } = useConfirm();
  const [content, setContent] = useState<typeof tableDataValue>("");
  const [isEditing, toggleIsEditing] = useToggle(false);
  const [contentChanged, toggleContentChanged] = useToggle(false);
  const value = useRef<string | number | boolean | Date>(tableDataValue);
  const thisInput = useRef<HTMLInputElement>(null);
  const thisSpan = useRef<HTMLSpanElement>(null);
  const typeOfContent = typeSpecs[tableDataKey];

  useEffect(() => {
    setContent(tableDataValue);
  }, [tableDataValue]);

  useEffect(() => {
    if (thisInput?.current) {
      if (isEditing) {
        thisInput.current.focus();
      }
    }
  }, [isEditing]);

  useEffect(() => {}, [contentChanged]);

  const resetData = (): void => {
    setContent(value.current as string);
    toggleIsEditing();
  };

  useEffect(() => {
    if (typeof unsavedItems === "boolean" && !unsavedItems) {
      toggleContentChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedItems]);

  const saveTableDataLocally = async (): Promise<void> => {
    if (checkIfParamsComboIsUnique && uniqueParamsCombo && uniqueParamsCombo.length) {
      if (uniqueParamsCombo.includes(tableDataKey)) {
        const isUnique = checkIfParamsComboIsUnique(tableDataKey, content.toString());

        if (isUnique !== undefined && !isUnique) {
          const uniqueParamsComboTranslated = uniqueParamsCombo.map((param) => tWithPrefix(param));

          resetData();
          await isConfirmed(
            `${t("paramComboNotUnique")} ${uniqueParamsComboTranslated.join(", ")}`,
            "alert"
          );
          return;
        }
      }
    }

    if (typeOfContent?.type === "number") {
      if (content) {
        const parsedContent = content.toString().replaceAll(",", ".");
        const roundedInput =
          tableDataKeyPrefix !== "feedDietData"
            ? roundToDecimal(parseFloat(parsedContent), numberOfDecimals).toString()
            : parsedContent.toString();

        if (roundedInput === "NaN") {
          setContent("");
          value.current = "";
          update(tableDataKey, "");
          isConfirmed(t("invalidInput"), "alert");
        } else if (parsedContent.toString().endsWith(".")) {
          const updatedContent = parsedContent.toString().replaceAll(".", "");
          value.current = updatedContent;
          setContent(updatedContent);
          update(tableDataKey, parseFloat(updatedContent));
        } else if (roundedInput !== "NaN") {
          setContent(roundedInput);
          value.current = parseFloat(roundedInput);
          update(tableDataKey, parseFloat(roundedInput));
        }
      } else if (content === "" && tableDataKeyPrefix === "feedDietData") {
        setContent(0);
        value.current = parseFloat("0");
        update(tableDataKey, parseFloat("0"));
      }
      if (contentChanged === false && !disableHighlight) {
        toggleContentChanged();
      }
      return toggleIsEditing();
    }

    if (typeOfContent?.type === "string") {
      value.current = content;
      update(tableDataKey, content);
      return toggleIsEditing();
    }

    value.current = content;
    update(tableDataKey, content);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    saveTableDataLocally();
  };

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (tableDataKeyPrefix === "feedDietData") {
      const inputVal = parseInt(e.target.value);
      if (inputVal < 0 || inputVal > 100) {
        resetData();
        await isConfirmed(t("feedDietDataInputError"), "alert");
        return;
      }

    /**
     * Regex explanation:
     * If the type of content is number, we want to allow the user to input
     * numbers, commas, dots and minus signs. We also want to prevent the user
     * from inputting anything else.
     */
      const stringContent = e.target.value.replace(/[^\d.,-]/g, "");
      return setContent(stringContent);
    }

    /**
     * Regex explanation:
     * Same as the comment above
     */
    if (typeOfContent?.type === "number") {
      const stringContent = e.target.value.replace(/[^\d.,-]/g, "");
      return setContent(stringContent);
    }

    return setContent(e.target.value);
  };

  const handleOnCheckboxChange = () => {
    if (typeOfContent?.editable) {
      update(tableDataKey, !content);
    }
  };

  const handleOnSpanClick = () => {
    if (
      feedMix &&
      (tableDataKey === "feedGroup" || tableDataKey === "feedNumber") &&
      !history.location.pathname.includes("feed-mix") &&
      !history.location.pathname.includes("add-remove")
    ) {
      return history.push(`/feed-mix/${feedMix.id}`);
    }
    if (typeOfContent?.editable) {
      toggleIsEditing();
    }
  };

  const handleOnKeyUp: React.KeyboardEventHandler<HTMLSpanElement> = (e) => {
    if (e.target === thisInput.current) {
      if (e.code === "Escape") {
        resetData();
      }
      return;
    }

    if (e.target === thisSpan.current) {
      if (e.code === "Enter" || e.code === "Tab") {
        toggleIsEditing();
      }
    }
  };

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    saveTableDataLocally();
  };

  const handleOnFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    e.currentTarget.select();
  };

  const renderTableDataContent = () => {
    if (isEditing) {
      return (
        <form className="form" onSubmit={handleOnSubmit}>
          <input
            className="input"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onKeyUp={handleOnKeyUp}
            ref={thisInput}
            value={content === null ? "" : content.toString()}
          />
        </form>
      );
    }

    const classes: string[] = ["content"];

    if (content === null || content === "") {
      classes.push("empty");
    }

    if (content && content.toString().includes("#min#")) {
      classes.push("highlight-min");
    }

    if (content && content.toString().includes("#max#")) {
      classes.push("highlight-max");
    }

    if (typeOfContent?.editable) {
      classes.push("editable");
    }

    if (disabled) {
      classes.push("disabled");
    }

    let innerText: string | undefined = "";

    if (content === null) {
      innerText = "";
    } else if (content === undefined) {
      if (typeOfContent?.type !== "boolean") {
        innerText = "false";
      }
      innerText = undefined;
    } else if ((tableDataKey === "updated" || tableDataKey === "created") && content) {
      innerText = new Date(content.toString()).toLocaleDateString();
    } else if (content.toString().includes("#min#")) {
      innerText = content.toString().replace("#min#", "");
    } else if (content.toString().includes("#max#")) {
      innerText = content.toString().replace("#max#", "");
    } else if (content.toString() === "deleteFeedPlanIcon") {
      innerText = "delete_forever";
      classes.push("material-icons", "deleteFeedPlan");
    } else if (content.toString() === "sortUp") {
      innerText = "arrow_drop_up";
      classes.push("material-icons", "sortTableArrow");
    } else if (content.toString() === "sortDown") {
      innerText = "arrow_drop_down";
      classes.push("material-icons", "sortTableArrow");
    } else {
      innerText = content.toString();
    }

    const shouldBeChecked =
      content && typeOfContent?.type === "boolean" ? (content as boolean) : false;

    const shouldBeDisabled = !disabled ? handleOnSpanClick : undefined;
    
    return typeOfContent?.type === "boolean" ? (
      <label className="lm__checkbox lm__tick table-checkbox" htmlFor="editableCheckbox">
        <input
          id="editableCheckbox"
          type="checkbox"
          checked={shouldBeChecked}
          onChange={handleOnCheckboxChange}
        />
        <label />
      </label>
    ) : (
      <span
        role="button"
        className={`${classes.join(" ")} table-data-span`}
        onClick={shouldBeDisabled}
        onKeyUp={handleOnKeyUp}
        ref={thisSpan}
        tabIndex={0}
      >
        {innerText}
      </span>
    );
  };

  return (
    <td
      className={`table-data ${hidden ? "hidden" : ""} ${contentChanged ? "highlight-change" : ""}`}
    >
      {renderTableDataContent()}
    </td>
  );
};

export default TableData;
